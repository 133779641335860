import emptyStorage from "../../../../assets/svg/Emptystorage.svg";

const ImageEmpty = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={emptyStorage} style={{ width: "10%", paddingTop: "10px" }} />
      <div className="empty-div">
        <p className="empty-text">NO FILE FOUND </p>
        <p className="empty-text">PLEASE UPLOAD TO SEE HERE</p>
      </div>
    </div>
  );
};

export default ImageEmpty;
