import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { ReactFormInput as Input } from "../../../components/Common/Input";
import { AddTagValidation } from "./AddTagValidation";
import { hexToRgb } from "@utils";
import { rgbToHex } from "@utils";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getFiles } from "@store/asset/assetActions";
import { getUsersByProject } from "@store/customer/customerActions";
import { CheckBoxOption } from "components/Common/input/CheckBoxOption";
import { useParams } from "react-router-dom";

const AddTagModal = ({ show, id, isEdit, handleClose, handleCreateItem }) => {

  const dispatch = useDispatch();
  const { id: projectId } = useParams();
  const { selectedAssetFiles, selectedAssetUsers, loading } = useSelector(
    (state) => state.assets
  );
  const [firstFile, SetFirstFile] = useState("");
  const { usersByProject: users } = useSelector((state) => state.customers);
  const firstFileId = useRef("");

  useEffect(() => {
    if (id) {
      dispatch(getFiles(id._id));
    }
  }, [id]);
  useEffect(() => {
    dispatch(getUsersByProject(projectId));
  }, []);
  useEffect(() => {
    if (isEdit) {
      if (selectedAssetFiles.length > 0) {
        SetFirstFile(selectedAssetFiles[0].file);
        firstFileId.current = selectedAssetFiles[0]._id;
      }
    }
  }, [selectedAssetFiles]);
  const onFormSubmit = (data) => {
    data.color = hexToRgb(data.color);
    handleCreateItem(data);
  };

  return (
    <Modal
      className="modal-dialog modal-lg "
      // style={{ marginLeft: "28%", marginTop: "-3%" }}
      isOpen={show}
      onHide={handleClose}
    >
      <ModalHeader closeButton>
        {" "}
        {isEdit ? "Edit Tag" : "Add New Tag"}
      </ModalHeader>
      <ModalBody>
        <AddTagForm
          onCancel={handleClose}
          tag={id}
          isEdit={isEdit}
          onSubmit={onFormSubmit}
          firstFile={firstFile}
          firstFileId={firstFileId}
          users={users}
        />
      </ModalBody>
    </Modal>
  );
};

const AddTagForm = ({
  onSubmit,
  tag,
  isEdit,
  onCancel,
  firstFile,
  firstFileId,
  users,
}) => {
  const optionsUsers = users.map((user) => ({
    value: user._id,
    label: user.name,
  }));
  let selectedUser;
  let selectedUsersArray = [];
  // if (isEdit) {
  //   if (tag.users && tag.users > 0) {
  //     selectedCustomer = users.filter(function (customer) {
  //       return customer._id === project.customerId;
  //     });
  //     if (selectedCustomer.length > 0) {
  //      var selectedUserObj = {
  //         label: selectedCustomer[0].name,
  //         value: project.customerId,
  //       };
  //       selectedUsersArray.push(selectedUserObj);

  //     }
  //   }
  // }

  const defaultValues = {
    title: isEdit ? tag.title : "",
    users: isEdit ? tag?.users.map((user) => user) : [],
    file: isEdit ? firstFile : "",
    fileId: isEdit ? firstFileId.current : "",
    color: isEdit ? rgbToHex(tag.color) : "#4b1616",
    description: isEdit ? tag.description : "",
    assetName: isEdit ? tag.assetName : "",
    assetModel: isEdit ? tag.assetModel : "",
    assetValue: isEdit ? tag.assetValue : "",
    maintenanceDate: isEdit ? tag.maintenanceDate : "",
    maintenanceProvider: isEdit ? tag?.maintenanceProvider : "",
    warrantyExpirationDate: isEdit ? tag?.warrantyExpirationDate : "",
    warrantyProvider: isEdit ? tag?.warrantyProvider : "",
  };

  const { control, handleSubmit, formState, setValue, } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: AddTagValidation,
  });
  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());
  return (
    <div className="space-y-10 w-full" ref={ref}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 w-full md:w-96">
          <ImageUpload
            label={"Upload FIle"}
            setValue={setValue}
            name="file"
            control={control}
            uploadedImage={isEdit ? firstFile : ""}
            imageHeight={"10rem"}
          />
          <div style={styles.outerDiv}>
            <Input
              reactForm
              control={control}
              label={"TITLE *"}
              name="title"
              type="text"
              error={formState?.errors?.title}
              errorMessage={formState?.errors?.title?.message}
            />
          </div>

          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <FormGroup>
                <Label>People</Label>
                <Controller
                  name={"users"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        options={optionsUsers}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          CheckBoxOption,
                        }}
                        onChange={field.onChange}
                        allowSelectAll={true}
                        value={field.value}
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>

            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                control={control}
                label={"Asset Value"}
                name="assetValue"
                type="text"
              />
            </div>
          </div>
          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <Input
                reactForm
                control={control}
                label={"Select Tag Color"}
                name="color"
                type="color"
                placeholder={"color"}
                error={formState?.errors?.color}
                errorMessage={formState?.errors?.color?.message}
              />
            </div>
            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                control={control}
                label={"Description"}
                name="description"
                type="textarea"
              />
            </div>
          </div>
          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <Input
                reactForm
                control={control}
                label={"Asset Name"}
                name="assetName"
                type="text"
                error={formState?.errors?.assetName}
                errorMessage={formState?.errors?.assetName?.message}
              />
            </div>
            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                control={control}
                label={"Asset Model"}
                name="assetModel"
                type="text"
              />
            </div>
          </div>

          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <Input
                reactForm
                control={control}
                label={"Asset Maintenance Date"}
                name="maintenanceDate"
                type="date"
              />
            </div>
            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                control={control}
                label={"Asset Maintenance Provider"}
                name="maintenanceProvider"
                type="text"
              />
            </div>
          </div>
          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <Input
                reactForm
                control={control}
                label={"Warranty Expiration Date"}
                name="warrantyExpirationDate"
                type="date"
              />
            </div>
            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                control={control}
                label={"Warranty Provider"}
                name="warrantyProvider"
                type="text"
              />
            </div>
          </div>
          <div className="text-center mt-4">
            <Button className="btn-wd" type="submit" color="primary">
              {isEdit ? "Save Changes" : "Add Tag"}
            </Button>
            <Button
              className="btn-wd"
              type="button"
              color="secondary"
              onClick={onCancel}
            >
              cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const styles = {
  outerDiv: { display: "flex", flexDirection: "row" },
  innerFirstDiv: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "50%",
    marginRight: "10%",
  },
  innerSecondDiv: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "50%",
  },
};

export default AddTagModal;
