import React, { useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import ReactPlayer from "react-player";

const PlayerModal = ({ show, handleClose, src }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());
  return (
    <div className="space-y-10 w-full" ref={ref}>
      <Modal
        className="modal-dialog modal-lg project-modal-responsive"
        isOpen={show}
        onHide={handleClose}
        contentClassName="bg-transparent"
        style={{ marginTop: "10%" }}
      >
        <ModalBody className="p-0">
          <ReactPlayer url={src} width="100%" height="500px" controls={true} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PlayerModal;
