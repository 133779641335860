import React, { useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { ReactFormInput as Input } from "../../../components/Common/Input";
import { AddUpdateValidation } from "./AddUpdateValidation";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

const AddUpdateModal = ({ id, show, handleClose, handleCreateItem }) => {
  const onFormSubmit = (data) => {
    handleCreateItem(data);
  };
  const onCancel = (e) => {
    handleClose();
  };

  useEffect(() => {
    if (id) {
      // call the api and set all values to the form in update
    }
  }, [id]);

  return (
    <Modal isOpen={show} onHide={handleClose} style={{ marginLeft: "40%" }}>
      <ModalHeader closeButton>ADD UPDATE</ModalHeader>
      <ModalBody>
        <AddUpdateForm onSubmit={onFormSubmit} onCancel={onCancel} />
      </ModalBody>
    </Modal>
  );
};

const AddUpdateForm = ({ onSubmit, onCancel }) => {
  const defaultValues = {
    title: "",
    details: "",
  };

  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: AddUpdateValidation,
  });
  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());
  return (
    <div ref={ref} className="space-y-10 w-full">
      {/* <Loader active={isLoading}> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 w-full md:w-96">
          <div className="space-y-4">
            <Input
              reactForm
              label={"TITLE *"}
              name="title"
              control={control}
              type="text"
              placeholder={"Title"}
              error={formState?.errors?.title}
              errorMessage={formState?.errors?.title?.message}
            />
          </div>
          <div className="space-y-4">
            <Input
              reactForm
              label={"DESCRIPTION"}
              name="details"
              control={control}
              type="textarea"
              placeholder={"Description"}
              height="150px"
            />
          </div>

          <div className="text-center">
            {/* <Button
              type='button'
              onClick={onSubmit}
              disabled={!formState.isValid}
              className='btn-green text-sm md:text-md'
              text={t('SAVE_CHANGES')}
              loading={loading}
              loadingText={t('common:SAVING')}
            /> */}
            <Button className="btn-wd" type="submit" color="primary">
              Add Update
            </Button>
            <Button
              className="btn-wd"
              type="button"
              color="secondary"
              onClick={onCancel}
            >
              cancel
            </Button>
          </div>
        </div>
      </form>
      {/* </Loader> */}
    </div>
  );
};

export default AddUpdateModal;
