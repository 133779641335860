import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '@api/axios'
import { toast } from 'react-toastify'


const createTutorial = createAsyncThunk('tutorial/create', async (body, { rejectWithValue }) => {
  const progressChannel = new BroadcastChannel('upload-progress');
  progressChannel.postMessage(0);
  try {
    const onUploadProgress = event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      progressChannel.postMessage(percentCompleted);
    };
    const res = await axiosInstance.post(`/tutorial`, body, { onUploadProgress });
    return res.data
  } catch (err) {
    toast.warn(err)
    return rejectWithValue(err)
  }
})
const fetchTutorials = createAsyncThunk('tutorials/fetch', async (_, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/tutorial`)
    return res.data.result
  } catch (err) {
    toast.warn(err)
    return rejectWithValue(err)
  }
})

const updateTutorial = createAsyncThunk('tutorial/update', async ({ id, body }, { rejectWithValue }) => {
  const progressChannel = new BroadcastChannel('upload-progress');
  progressChannel.postMessage(0);
  try {
    const onUploadProgress = event => {
      const percentCompleted = Math.round((event.loaded * 100) / event.total);
      progressChannel.postMessage(percentCompleted);
    };
    const res = await axiosInstance.put(`/tutorial/` + id, body, { onUploadProgress })
    return res.data
  } catch (err) {
    return rejectWithValue(err)
  }
}
)
const deleteTutorial = createAsyncThunk('tutorials/delete', async (id, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.delete(`/tutorial/` + id)
    return res.data
  } catch (err) {
    return rejectWithValue(err)
  }
})

export {
  fetchTutorials,
  createTutorial,
  deleteTutorial,
  updateTutorial,
}
