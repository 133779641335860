import { styles } from "../../../views/admin/styles";
import { useState } from "react";

const PasswordField = ({ type, placeholder, onChange, id, onKeyDown }) => {
  const [eye, seteye] = useState(true);
  const [inpass, setinpass] = useState("password");
  const Eye = () => {
    if (inpass == "password") {
      setinpass("text");
      seteye(false);
    } else {
      setinpass("password");
      seteye(true);
    }
  };

  return (
    <div style={styles.inputField}>
      <input
        style={{ border: "0px white", width: "90%", outline: "none" }}
        id={id}
        type={inpass}
        placeholder={placeholder}
        autoComplete="off"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        onKeyDown={onKeyDown}
      />
      <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}></i>
    </div>
  );
};
export { PasswordField };
