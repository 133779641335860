import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Modal, Col, Row } from "reactstrap";
import { ReactComponent as LoginIcon } from "../../../assets/svg/LoginIcon.svg";
import { ReactComponent as GeoMausLogo } from "../../../assets/svg/GeoMausLogo.svg";

import OtpInput from "react-otp-input";
import axiosInstance from "@api/axios";
import { styles } from "../styles";
import { PasswordField } from "components/Common/input/PasswordField";

function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailVerified, setEmailVerified] = useState(false);
  const [otp, setOtp] = useState();
  const [otpError, setOtpError] = useState(false);
  const [modal, setModal] = useState(false);
  const generateotp = useRef(Math.floor(100000 + Math.random() * 900000));

  const sendotp = async () => {
    setModal(!modal);
    setCounter(30);
    try {
      await axiosInstance.post("/sendotp", {
        email: email,
        otp: generateotp.current,
      });
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const resendOtp = async () => {
    generateotp.current = Math.floor(100000 + Math.random() * 900000);
    try {
      await axiosInstance.post("/sendotp", {
        email: email,
        otp: generateotp.current,
      });
    } catch (err) {
      console.log("Error: ", err);
    }
    setCounter(30);
  };

  const confirmOtp = () => {
    if (otp == generateotp.current) {
      setOtpError(false);
      setEmailVerified(true);
      setModal(!modal);
    } else {
      setOtpError(true);
    }
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      if (emailVerified) {
        updatePasword;
      } else {
        sendotp();
      }
    }
  };
  const updatePasword = async () => {
    if (password === confirmPassword) {
      try {
        await axiosInstance.put("/user/password/changepassword", {
          email,
          password,
        });
        history.push("/Login");
      } catch (err) {
        console.log("Error: ", err);
      }
    } else {
    }
  };

  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="container-fluid">
      <Row>
        <Col md="6" xs="12" style={styles.leftContainer}>
          <GeoMausLogo height="100%" width="100%" />
        </Col>
        <Col
          md="6"
          xs="12"
          className="ml-auto mr-auto"
          style={styles.rightContainer}
        >
          <h2 style={styles.heading}>
            WELCOME TO <span style={{ color: "#031FDC" }}>GEOMAUS</span>
          </h2>
          <h6 style={{ color: "#4D5053" }}>Forgot your password?</h6>
          <div className="mt-5">
            {!emailVerified ? (
              <div className="mb-3" style={styles.inputContainer}>
                <label htmlFor="email">Email Address</label>
                <input
                  style={styles.inputField}
                  id="email"
                  type="text"
                  placeholder="Enter your Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyDown={handleKeypress}
                />
              </div>
            ) : null}
            {emailVerified ? (
              <>
                <div style={styles.inputContainer}>
                  <label htmlFor="passsword">Password</label>

                  <PasswordField
                    id="password"
                    placeholder="Enter your Password"
                    onChange={setPassword}
                  />
                </div>

                <div style={styles.inputContainer}>
                  <label htmlFor="confirm-passsword">Confirm Password</label>
                  <PasswordField
                    id="confirm-password"
                    onChange={setConfirmPassword}
                    placeholder="Confirm your password"
                    onKeyDown={() => {
                      updatePasword();
                    }}
                  />
                </div>
              </>
            ) : null}
          </div>

          <Button
            className="btn-round"
            style={styles.btn}
            onClick={() => (emailVerified ? updatePasword() : sendotp())}
          >
            Confirm
          </Button>
        </Col>
      </Row>
      <Modal
        isOpen={modal}
        toggle={() => setModal(!modal)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ padding: 50, textAlign: "center" }}>
          <h2>Forgot Your Password?</h2>
          <p>Kindly enter OTP sent to your email</p>
          <OtpInput
            containerStyle={styles.otpContainer}
            inputStyle={{
              ...styles.otpInput,
              border: otpError ? "1px solid red" : "1px solid #D0DCD7",
            }}
            value={otp}
            onChange={(otp) => {
              setOtp(otp);
            }}
            numInputs={6}
            placeholder="******"
            shouldAutoFocus={true}
          />
          {otpError ? (
            <span style={{ color: "red" }}>Wrong Otp! Try again.</span>
          ) : null}
          <p>
            <Button
              color="link"
              disabled={counter > 0}
              onClick={() => resendOtp()}
            >
              Resend Otp
            </Button>{" "}
            00:{counter}s
          </p>
          <br />
          <Button
            className="btn-round"
            style={styles.btn}
            onClick={() => confirmOtp()}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default ForgotPassword;
