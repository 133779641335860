import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddTagSchema = yup.object().shape({
  title: yup.string().required("Title should not be empty"),
});

const AddTagValidation = yupResolver(AddTagSchema);

export { AddTagValidation };
