import {createSlice} from '@reduxjs/toolkit'
import {
  fetchAssets,
  fetchAllAssets,
  fetchOverDueAssets,
  createAsset,
  updateAsset,
  completeAsset,
  deleteAsset,
  getAsset,
  createAssetComment,
  fetchCustomerOverduesAssets,
  fetchUserOverduesAssets,
  uploadFile,
  getFiles,
  deleteFile,
  editFile,
} from './assetActions'
const initialState = {
  assets: [],
  overDueAssets: [],
  selectedAssetComments: [],
  selectedAsset: {},
  selectedAssetFiles: [],
  totalAssets: 0,
  totalOverDueAssets: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
}

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAssets.pending]: state => {
      state.loading = true
    },
    [fetchAssets.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.assets = payload.result.docs
      state.totalAssets = payload.result.totalDocs
      state.numberOfRows = payload.result.limit
      state.pageIndex = payload.result.page
    },
    [fetchAssets.rejected]: state => {
      state.loading = false
    },
    [fetchAllAssets.pending]: state => {
      state.loading = true
    },
    [fetchAllAssets.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.assets = payload.result.docs
      state.totalAssets = payload.result.totalDocs
      state.numberOfRows = payload.result.limit
      state.pageIndex = payload.result.page
    },
    [fetchAllAssets.rejected]: state => {
      state.loading = false
    },
    [fetchOverDueAssets.pending]: state => {
      state.loading = true
    },
    [fetchOverDueAssets.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.overDueAssets = payload.result.docs
      state.totalOverDueAssets = payload.result.totalDocs
    },
    [fetchOverDueAssets.rejected]: state => {
      state.loading = false
    },
    [fetchCustomerOverduesAssets.pending]: state => {
      state.loading = true
    },
    [fetchCustomerOverduesAssets.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.totalCustomerOverdueTags = payload.result
    },
    [fetchCustomerOverduesAssets.rejected]: state => {
      state.loading = false
    },
    [fetchUserOverduesAssets.pending]: state => {
      state.loading = true
    },
    [fetchUserOverduesAssets.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.totalUserOverdueTags = payload.result
    },
    [fetchUserOverduesAssets.rejected]: state => {
      state.loading = false
    },
    [getAsset.pending]: state => {
      state.loading = true
    },
    [getAsset.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.selectedAssetComments = payload.result.assetComments.docs
      state.selectedAsset = payload.result.asset
    },
    [getAsset.rejected]: state => {
      state.loading = false
    },
    [createAsset.pending]: state => {
      state.loading = true
    },
    [createAsset.fulfilled]: (state, {payload}) => {
      state.loading = false
      const asset = payload.result
      const assets = state.assets
      state.assets = [asset, ...assets]
      state.totalAssets = state.totalAssets + 1
    },
    [createAsset.rejected]: state => {
      state.loading = false
    },
    [updateAsset.pending]: state => {
      state.loading = true
    },
    [updateAsset.fulfilled]: (state, {payload}) => {
      state.loading = false
      const asset = payload.result.asset
      const assets = state.assets
      const filteredAssets = assets.filter(function (obj) {
        return obj._id !== asset._id
      })
      state.assets = [asset, ...filteredAssets]
      if (payload?.result?.uploaded.length > 0) {
        const selectedFile = payload?.result?.uploaded
        const selectedAssetFiles = state.selectedAssetFiles
        const filteredAssetFiles = selectedAssetFiles.filter(function (obj) {
          return obj._id !== selectedFile._id
        })

        state.selectedAssetFiles = [selectedFile, ...filteredAssetFiles]
      }
    },
    [updateAsset.rejected]: state => {
      state.loading = false
    },
    [completeAsset.pending]: state => {
      state.loading = true
    },
    [completeAsset.fulfilled]: (state, {payload}) => {
      state.loading = false
      const asset = payload.result.asset
      const assets = state.assets
      const filteredAssets = assets.filter(function (obj) {
        return obj._id !== asset._id
      })
      state.assets = [asset, ...filteredAssets]
    },
    [completeAsset.rejected]: state => {
      state.loading = false
    },
    [deleteAsset.pending]: state => {
      state.loading = true
    },
    [deleteAsset.fulfilled]: (state, {payload}) => {
      state.loading = false
      const deletedId = payload.result
      const assets = state.assets

      const filteredAssets = assets.filter(function (obj) {
        return obj._id !== deletedId
      })
      state.assets = [...filteredAssets]
      state.totalAssets = state.totalAssets - 1
    },
    [deleteAsset.rejected]: state => {
      state.loading = false
    },
    [createAssetComment.pending]: state => {
      state.loading = true
    },
    [createAssetComment.fulfilled]: (state, {payload}) => {
      state.loading = false
      const comment = payload.result
      const comments = state.selectedAssetComments
      state.selectedAssetComments = [comment, ...comments]
    },
    [createAssetComment.rejected]: state => {
      state.loading = false
    },
    [getFiles.pending]: state => {
      state.loading = true
    },
    [getFiles.fulfilled]: (state, {payload}) => {
      state.loading = false
      state.selectedAssetFiles = payload.result
    },
    [getFiles.rejected]: state => {
      state.loading = false
    },
    [uploadFile.pending]: state => {
      state.loading = true
    },
    [uploadFile.fulfilled]: (state, {payload}) => {
      state.loading = false
      const file = payload.result
      const files = state.selectedAssetFiles
      state.selectedAssetFiles = [file, ...files]
    },
    [uploadFile.rejected]: state => {
      state.loading = false
    },
    [editFile.pending]: state => {
      state.loading = true
    },
    [editFile.fulfilled]: (state, {payload}) => {
      const file = payload.result
      const files = state.selectedAssetFiles
      const filteredFiles = files.filter(function (obj) {
        return obj._id !== file._id
      })
      state.selectedAssetFiles = [file, ...filteredFiles]
    },
    [editFile.rejected]: state => {
      state.loading = false
    },
    [deleteFile.pending]: state => {
      state.loading = true
    },
    [deleteFile.fulfilled]: (state, {payload}) => {
      const deletedId = payload.result
      const files = state.selectedAssetFiles
      const filteredFiles = files.filter(function (obj) {
        return obj._id !== deletedId
      })
      state.selectedAssetFiles = [...filteredFiles]
    },
    [deleteFile.rejected]: state => {
      state.loading = false
    },
  },
})

// this is for configureStore
export default assetSlice.reducer
