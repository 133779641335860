import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Customer from "layouts/Customer";
import Login from "./views/admin/login/Login";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.0";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ForgotPassword from "views/admin/forgotPassword/ForgotPassword";
import { AbilityContext } from "@permissions/Can";
import { ability } from "@permissions/Ability";
import ProjectMarketingModel from "./views/admin/projects/ProjectMarketingModel";
import People from "layouts/People";

export const App = () => {
  return (
    <AbilityContext.Provider value={ability}>
      <BrowserRouter>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/Customer" render={(props) => <Customer {...props} />} />
          <Route path="/user" render={(props) => <People {...props} />} />
          <Route path="/Login" render={(props) => <Login {...props} />} />
          <Route
            path="/MarketingPage/:id"
            render={(props) => <ProjectMarketingModel {...props} />}
          />
          <Route
            path="/forgotpassword"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Redirect to="/Login" />
        </Switch>
      </BrowserRouter>
    </AbilityContext.Provider>
  );
};
