import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import {  useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  createProject,
  deleteProject,
  fetchProjects,
  updateProject,
} from "@store/project/projectActions";
import { useDispatch, useSelector } from "react-redux";
// 
import { ActionsModal } from "components/Common/ActionsModal";
import AddProjectModal from "./AddProjectModal";
import AddProjectModalCustomer from "./AddProjectModalCustomers";
import { Can } from "@permissions/Can";
import CsvExport from "components/Common/CsvExport";
import DeleteModal from "components/Common/modal/DeleteModal";
import PlayerModal from "components/Common/PlayerModal";
import { ProjectStatus } from "./ProjectStatus";
import ReactReadMoreReadLess from "react-read-more-read-less";
import ReactTable from "../../../components/ReactTable/ReactTable";
import ReactTableSearch from "components/ReactTable/ReactTableSearch";
import ReactTooltip from "react-tooltip";
import mapIcon from "../../../assets/svg/map.svg";
import matterportIcon from "../../../assets/svg/matterport.svg";
import { removeSelectedProject } from "@store/project/projectActions";
import useDebounce from "@hooks/useDebounce";
import youtubeIcon from "../../../assets/svg/youtube.svg";
// 
const Projects = () => {
  const [query, setQuery] = useState("");
  const debouncedSearchTerm = useDebounce(query, 500);
  const [data, setData] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [playerModal, setPlayerModal] = useState(false);
  const [currentSrc, setCurrentSrc] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { projects, totalProjects, pageIndex, numberOfRows, loading } =
    useSelector((state) => state.projects);
  const { user, role } = useSelector((state) => state.login);
  console.log('➡️ 🤷‍♂️ file: Projects.js:60 🤷‍♂️ role', role)
  useEffect(() => {
    dispatch(
      fetchProjects({
        page: pageIndex,
        rows: numberOfRows,
        query: debouncedSearchTerm,
      }),
    );
  }, [pageIndex, numberOfRows, debouncedSearchTerm]);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setSelectedId("");
    setShow(false);
  };
  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => {
    setDeleteShow(false);
  };
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const handleDeleteShow = () => setDeleteShow(true);
  const handleCreateProject = (data) => {
    if (localStorage.getItem("customer_access_token")) {
      data.customerId = "self";
    } else {
      data.customerId = data.customerId.value;
    }
    data.status = data.status.value;
    if (selectedId) {
      const form_data = { ...data };
      dispatch(updateProject({ id: selectedId._id, body: form_data }));
    } else {
      // var form_data = new FormData()
      // for (var key in data) {
      //   form_data.append(key, data[key])
      // }
      const form_data = { ...data };
      dispatch(createProject(form_data));
    }
    handleClose();
  };
  const notify = () => toast("Copied to ClipBoard");
  var youtubeRegex =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const handleViewProject = (project) => {
    dispatch(removeSelectedProject());
    if (role === "ADMIN") {
      history.push("/admin/MatterportEditor/" + project._id);
    } else if (role === "CUSTOMER") {
      history.push("/customer/MatterportEditor/" + project._id);
    } else if (role === "USER") {
      history.push("/user/MatterportEditor/" + project._id);
    }
  };
  const handleViewMarketingUrl = (project) => {
    dispatch(removeSelectedProject());
    if (role === "ADMIN") {
      history.push("/admin/MarketingPage/" + project._id);
    } else if (role === "CUSTOMER") {
      history.push("/customer/MarketingPage/" + project._id);
    } else if (role === "USER") {
      history.push("/user/MarketingPage/" + project._id);
    }
  };
  const handleEditProject = (id) => {
    setSelectedId(id);
    handleShow();
  };
  const handleDeleteClicked = (id) => {
    setSelectedId(id);
    handleDeleteShow();
  };
  const handleSearchClicked = (value) => {
    dispatch(
      fetchProjects({ page: pageIndex, rows: numberOfRows, searchText: value }),
    );
  };
  const handleDeleteProject = () => {
    handleDeleteClose();
    dispatch(deleteProject(selectedId));
  };
  const onChangePaginationInfo = (page, rows) => {
    const rowsToUpdate = rows === null ? numberOfRows : rows;
    const pageToUpdate = page === null ? pageIndex : page;
    dispatch(fetchProjects({ page, rows, searchText: query }));
  };
  const handelMatterPortClick = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
      notify();
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const handleyoutubeClick = (src) => {
    setCurrentSrc(src);
    setPlayerModal(true);
  };
  const handelActions = () => { };
  const handleUrl = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };
  const handlePlayerClose = () => {
    setPlayerModal(false);
  };
  const iconStyle = { marginTop: "-2px" };
  // update the data array
  useEffect(() => {
    let tempData = [];
    let dataToExportTemp = [];
    if (projects?.length > 0) {
      tempData = projects.map((project, index) => {
        const item = {
          id: project?.id,
          title: project?.title,
          srNumber: index + 1,
          titleAnddescription: project?.description,
          customerId: project?.customerId,
          status: project?.status,
          matterportUrl: project?.matterPortUrl,
          clickEmbedUrl: project?.clickEmbedUrl,
          geomausMarketingModel: project?.geomausMarketingModel,
          googleMapEmbed: project?.googleMapEmbed,
          youtubeVideoEmbed: project?.youtubeVideoEmbed,
          body: (
            <div className="overflow-auto" style={{ maxHeight: "3rem" }}>
              <span
                className="h-25 overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: project?.body,
                }}
              ></span>
            </div>
          ),
          logoPhoto: (
            <div
              className="w-10"
              onClick={() => {
                handleViewProject(project);
              }}
            >
              <img src={project?.logoPhoto} style={imgStyles} />
            </div>
          ),
          status: <ProjectStatus status={project?.status} />,
          customerId: (
            <Can I="view" a="ProjectField">
              <div className={`${project?.customerId}`} style={{ marginTop: "15px" }}>
                <p>{project?.customerId}</p>
              </div>
            </Can>
          ),
          description: (
            <div>
              <p
                onClick={() => {
                  handleViewProject(project);
                }}
                style={{ fontWeight: "700", cursor: "pointer" }}
              >
                {project?.title}
              </p>
              <ReactReadMoreReadLess
                charLimit={30}
                readMoreText={"Read more ▼"}
                readLessText={"Read less ▲"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                {project?.description}
              </ReactReadMoreReadLess>
            </div>
          ),
          clickEmbedUrl: (
            <div
              style={
                project.clickEmbedUrl === "" ? { opacity: 0.5, marginTop: "10px" } : { opacity: 1, marginTop: "8px" }
              }
            >
              <p
                style={{ ...ButtonStyle, background: "#1A73E8" }}
                onClick={() => {
                  handleUrl(project.clickEmbedUrl);
                }}
              >
                Click up
              </p>
            </div>
          ),
          marketingModel: (
            <div
              style={
                project.marketingModel === ""
                  ? { opacity: 0.5, marginTop: "10px" }
                  : { opacity: 1, marginTop: "10px" }
              }
            >
              <p
                style={ButtonStyle}
                onClick={() => {
                  handleUrl(project.geomausMarketingModel);
                }}
              >
                Marketing Modal
              </p>
            </div>
          ),
          matterportUrl: (
            // <Can I="view" a="ProjectField">
            <div>
              <img
                src={matterportIcon}
                data-tip
                data-for={`${project.id}_${project?.matterPortUrl}`}
                alt="matter"
                style={IconStyle}
                onClick={() => handelMatterPortClick(project?.matterPortUrl)}
              />
              <ReactTooltip
                id={`${project.id}_${project?.matterPortUrl}`}
                place="top"
                effect="solid"
              >
                <p>{project?.matterPortUrl}</p>
              </ReactTooltip>
            </div>
            // </Can>
          ),
          googleMap: (
            // <Can I="view" a="ProjectField">
            <div
              style={IconStyle}
              onClick={() => {
                handleUrl(project.googleMapEmbed);
              }}
            >
              <img
                style={
                  project.googleMapEmbed === ""
                    ? { opacity: 0.5 }
                    : { opacity: 1 }
                }
                src={mapIcon}
                alt="map-icon"
              />
            </div>
            // </Can>
          ),
          youtubeVideo: (
            // <Can I="view" a="ProjectField">
            <>
              {
                project?.youtubeVideoEmbed.match(youtubeRegex) ? (
                  <span
                    style={{ cursor: "pointer", marginTop: "8px" }}
                    onClick={() => {
                      handleyoutubeClick(project?.youtubeVideoEmbed);
                    }}
                  >
                    <img src={youtubeIcon} alt="map-icon" />
                  </span>
                ) : (
                  <span style={disabledStyle}>
                    <img style={{ cursor: "pointer", marginTop: "8px" }} src={youtubeIcon} alt="map-icon" />
                  </span>
                )
              }
            </>
            // </Can>
          ),
          actions: (
            // <Can I="view" a="ProjectField">
            <ActionsModal
              project={project}
              handleDeleteClicked={handleDeleteClicked}
              handleEditProject={handleEditProject}
              handleViewProject={handleViewProject}
            />
            // </Can>
          ),
        };
        return item;
      });
      dataToExportTemp = projects.map((project, index) => {
        const item = {
          title: project?.title,
          srNumber: index + 1,
          description: project?.description,
          customerId: project?.customerId,
          status: project?.status,
          matterportUrl: project?.matterportUrl,
          clickEmbedUrl: project?.clickEmbedUrl,
          geomausMarketingModel: project?.geomausMarketingModel,
          googleMapEmbed: project?.googleMapEmbed,
          youtubeVideoEmbed: project?.youtubeVideoEmbed,
        };
        return item;
      });
    }
    setData(tempData);
    setDataToExport(dataToExportTemp);
  }, [projects]);
  // useEffect(() => {
  //   console.log("useEffectuseEffectuseEffect", query);
  // }, [query]);
  const imgStyles = {
    width: "120px",
    height: "81px",
    borderRadius: "12px",
    cursor: "pointer",
  };
  const initialState =
    role === "USER"
      ? {
        hiddenColumns: [
          // "customerId",
          // "matterportUrl",
          // "clickEmbedUrl",
          // "googleMap",
          // "youtubeVideo",
        ],
      }
      : { hiddenColumns: [] };
  return (
    <>
      <div className="content">
        <div className="TableTopProjects">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <Can I="create" a="Project">
                <Button variant="primary" onClick={handleShow}>
                  Add Project
                </Button>
              </Can>
              <CsvExport data={dataToExport} fileName="Projects Export" />
            </div>
            {role === "CUSTOMER" ? (
              <AddProjectModalCustomer
                show={show}
                handleClose={handleClose}
                handleCreateItem={handleCreateProject}
                id={selectedId}
              />
            ) : (
              <AddProjectModal
                show={show}
                handleClose={handleClose}
                handleCreateItem={handleCreateProject}
                id={selectedId}
              ></AddProjectModal>
            )}
            <ReactTableSearch
              numberOfRows={numberOfRows}
              onChange={(e) => {
                handleSearchClicked(e);
              }}
              onSearchClicked={handleSearchClicked}
              onChangePaginationInfo={onChangePaginationInfo}
            />
          </div>
        </div>
        <Row>
          <Col md="12">
            <ReactTable
              projects={true}
              data={data}
              numberOfRows={numberOfRows}
              pageIndex={pageIndex}
              totalCounts={totalProjects}
              onChangePaginationInfo={onChangePaginationInfo}
              loading={loading}
              // sticky={true}
              onChangeFilter={(filters) => { }}
              columns={[
                {
                  Header: "Sr No.",
                  accessor: "srNumber",
                  width: 50,
                },
                {
                  Header: "Photo",
                  accessor: "logoPhoto",
                },
                {
                  Header: "Title & Description",
                  accessor: "description",
                  width: 300,
                },
                {
                  Header: "Customer Id",
                  accessor: "customerId",
                  width: 250,
                },
                {
                  Header: "Status",
                  accessor: "status",
                },
                {
                  Header: "Matterport Url",
                  accessor: "matterportUrl",
                },
                {
                  Header: "Click Embed Url",
                  accessor: "clickEmbedUrl",
                },
                {
                  Header: "Marketing Model",
                  accessor: "marketingModel",
                },
                {
                  Header: "Google Map",
                  accessor: "googleMap",
                  width: 100,
                },
                {
                  Header: "Youtube Video",
                  accessor: "youtubeVideo",
                  width: 100,
                },
                {
                  Header: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // zIndex: '0',
                      }}
                    >
                      Actions
                    </div>
                  ),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  // sticky: 'right',
                  width: 85,
                },
              ]}
              initialState={initialState}
              className="-striped -highlight primary-pagination"
            />
          </Col>
        </Row>
        <DeleteModal
          show={deleteShow}
          handleClose={handleDeleteClose}
          handleAction={handleDeleteProject}
        />
      </div>
      <div className="content">
        <PlayerModal
          show={playerModal}
          handleClose={handlePlayerClose}
          src={currentSrc}
        />
      </div>
      <ToastContainer autoClose={2000} />
    </>
  );
};
export default Projects;
const ButtonStyle = {
  background: "#12E3FF",
  padding: "4px 7px",
  color: "white",
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
};
const IconStyle = {
  cursor: "pointer",
};
const disabledStyle = {
  opacity: 0.5,
};
