import React, { useEffect, useRef } from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { ReactFormInput as Input } from "../../../components/Common/Input";
import { AddProjectValidation } from "./AddProjectValidation";
import { fetchCustomers } from "@store/customer/customerActions";
import PictureUpload from "components/CustomUpload/PictureUpload";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import CoverUpload from "components/CustomUpload/CoverUpload";

const AddProjectModal = ({ id, show, handleClose, handleCreateItem }) => {
  const dispatch = useDispatch();
  const { customers, loading } = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  useEffect(() => {
    if (id) {
    }
  }, [id]);

  return (
    <Modal
      className="modal-dialog modal-lg project-modal-responsive"
      isOpen={show}
      onHide={handleClose}
    >
      <ModalHeader closeButton>
        {id ? "Edit Project" : "Add Project"}
      </ModalHeader>
      <ModalBody>
        <AddProjectForm
          project={id}
          onSubmit={handleCreateItem}
          customers={customers}
          onCancel={handleClose}
        />
      </ModalBody>
    </Modal>
  );
};

const AddProjectForm = ({ project, onSubmit, customers, onCancel }) => {
  const { role } = useSelector((state) => state.login)
  const optionsCustomer = customers.map((customer) => ({
    value: customer._id,
    label: customer.name,
  }));
  const options = [
    { value: "In-Progress", label: "In-Progress" },
    { value: "Completed", label: "Completed" },
  ];
  // const [singleSelect, setSingleSelect] = React.useState(selectedValue);
  // const [statusSelect, setStatusSelect] = React.useState(1);
  let selectedCustomer;
  let selectedCustomerObj = {};
  let selectedStatus;
  let selectedStatusObj = {};
  if (project) {
    if (project.customerId && customers.length > 0) {
      selectedCustomer = customers.filter(function (customer) {
        return customer._id === project.customerId;
      });
      if (selectedCustomer.length > 0) {
        selectedCustomerObj = {
          label: selectedCustomer[0].name,
          value: project.customerId,
        };
      }
    }

    if (project.status) {
      selectedStatus = options.filter(function (option) {
        return option.value === project.status;
      });
      if (selectedStatus.length > 0) {
        selectedStatusObj = {
          label: selectedStatus[0].label,
          value: selectedStatus[0].value,
        };
      }
    }
  }
  const defaultValues = {
    coverPhoto: project ? project.coverPhoto : null,
    logoPhoto: project ? project.logoPhoto : "",
    title: project ? project.title : "",
    description: project ? project.description : "",
    customerId: selectedCustomerObj,
    status: selectedStatusObj,
    matterPortUrl: project ? project.matterPortUrl : "",
    clickEmbedUrl: project ? project.clickEmbedUrl : "",
    geomausMarketingModel: project ? project.geomausMarketingModel : "",
    googleMapEmbed: project ? project.googleMapEmbed : "",
    youtubeVideoEmbed: project ? project.youtubeVideoEmbed : "",
  };

  const { control, handleSubmit, formState, setValue, register, watch } =
    useForm({
      mode: "onChange",
      defaultValues: defaultValues,
      resolver: AddProjectValidation,
    });

  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());

  return (
    <div ref={ref} className="space-y-10 w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 w-full md:w-96">
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
            className="w-full"
          >
            {/* <label>COVER PHOTO</label>
            <ImageUpload
              className="w-full"
              label={"Upload Cover Photo"}
              imageHeight={"7rem"}
              name="coverPhoto"
              setValue={setValue}
              control={control}
              register={{ ...register }}
              uploadedImage={project ? project.coverPhoto : ""}
              error={formState?.errors?.coverPhoto}
              errorMessage={formState?.errors?.coverPhoto?.message}
            /> */}
          </div>
          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <label>PROFILE PICTURE</label>
              <div className="space-y-4">
                <ImageUpload
                  label={"Upload Profile Picture"}
                  profilepic={true}
                  name="logoPhoto"
                  setValue={setValue}
                  control={control}
                  uploadedImage={project ? project.logoPhoto : ""}
                />
              </div>
            </div>
            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                label={"TITLE *"}
                name="title"
                control={control}
                type="text"
                placeholder={""}
                error={formState?.errors?.title}
                errorMessage={formState?.errors?.title?.message}
              />
              <Input
                reactForm
                label={"MatterPort Project Id *"}
                name="matterPortUrl"
                control={control}
                type="text"
                placeholder={""}
                error={formState?.errors?.matterPortUrl}
                errorMessage={formState?.errors?.matterPortUrl?.message}
              />
              <Input
                reactForm
                label={"Clickup Embed link"}
                name="clickEmbedUrl"
                control={control}
                type="text"
                placeholder={""}
              />
              <Input
                reactForm
                label={"Geomaus Marketing Model"}
                name="geomausMarketingModel"
                control={control}
                type="text"
                placeholder={""}
              />
            </div>
          </div>
          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <FormGroup>
                <Label>STATUS *</Label>
                <Controller
                  name={"status"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        options={options}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>
            {!localStorage.getItem("customer_access_token") && (
              <div style={styles.innerSecondDiv}>
                <FormGroup>
                  <Label>CUSTOMER ID</Label>
                  <Controller
                    name={"customerId"}
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          options={optionsCustomer}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FormGroup>
              </div>
            )}
          </div>
          <div style={styles.outerDiv}>
            <div style={styles.innerFirstDiv}>
              <Input
                reactForm
                label={"DESCRIPTION *"}
                name="description"
                control={control}
                type="textarea"
                rows="4"
                height="120px"
                placeholder={""}
                error={formState.errors.description}
                errorMessage={formState?.errors?.description?.message}
              />
            </div>
            <div style={styles.innerSecondDiv}>
              <Input
                reactForm
                label={"Property Google Map"}
                name="googleMapEmbed"
                control={control}
                type="text"
                placeholder={""}
              />
              <Input
                reactForm
                label={"Youtube Embed Videos"}
                name="youtubeVideoEmbed"
                control={control}
                type="text"
                placeholder={""}
              />
            </div>
          </div>
          <div className="text-center">
            <Button className="btn-wd" type="submit" color="primary">
              Save
            </Button>

            <Button
              className="btn-wd"
              type="button"
              color="secondary"
              onClick={onCancel}
            >
              cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const styles = {
  outerDiv: { display: "flex", flexDirection: "row" },
  innerFirstDiv: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "50%",
    marginRight: "15%",
  },
  innerSecondDiv: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "50%",
  },
};

export default AddProjectModal;
