import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "@api/axios";
const fetchUpdates = createAsyncThunk(
  "updates/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const { rows, page, query } = data;
      const res = await axiosInstance.get(
        `/updates?page=` + page + `&limit=` + rows + `&query=` + query
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const createUpdate = createAsyncThunk(
  "updates/add",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/update`, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const deleteUpdate = createAsyncThunk(
  "updates/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/update/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  }
);

export { fetchUpdates, createUpdate, deleteUpdate };
