import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { CheckBoxOption } from "components/Common/input/CheckBoxOption";
import Select from "react-select";
import { fetchProjects } from "@store/project/projectActions";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsByCustomer } from "@store/project/projectActions";
import LoaderModal from "components/Common/modal/loader";
import Projects from "views/admin/projects/Projects";

const AssignProjectModal = ({
  id,
  show,
  handleClose,
  handleAssignProject,
  type,
}) => {
  const dispatch = useDispatch();
  const { projects, customerProjects, loading } = useSelector(
    (state) => state.projects,
  );

  const onCancel = (e) => {
    handleClose();
  };

  useEffect(() => {
    if (id) {
      dispatch(getProjectsByCustomer({ id, type }));
      dispatch(fetchProjects({ page: 1, rows: 100, type: "UnAssigned" }));
    }
  }, [id]);

  return (
    <Modal isOpen={show} onHide={handleClose}>
      <ModalHeader closeButton>Assign Project</ModalHeader>
      <ModalBody>
        <AssignProjectForm
          onSubmit={handleAssignProject}
          onCancel={onCancel}
          projects={projects}
          customerProjects={customerProjects}
          loading={loading}
          type={type}
        />
      </ModalBody>
    </Modal>
  );
};

const AssignProjectForm = ({
  onSubmit,
  onCancel,
  projects,
  customerProjects,
  loading,
  type,
}) => {
  let optionsProjects;
  let selectedProjects;

  optionsProjects = projects.map((project) => ({
    value: project._id,
    label: project.title,
  }));
  selectedProjects = customerProjects.map((project) => ({
    value: project._id,
    label: project.title,
  }));

  optionsProjects = [...optionsProjects, ...selectedProjects];

  useEffect(() => {
    setValue("projects", selectedProjects);
  }, [customerProjects]);

  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
  });

  return (
    <div className="space-y-10 w-full">
      <LoaderModal loading={loading} />
      {!loading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label>Projects</Label>
            <Controller
              name={"projects"}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    options={optionsProjects}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      CheckBoxOption,
                    }}
                    onChange={field.onChange}
                    allowSelectAll={true}
                    value={field.value}
                  />
                );
              }}
            />
          </FormGroup>
          <div>
            <Button type="submit" color="primary">
              Save
            </Button>
            <Button type="button" color="secondary" onClick={onCancel}>
              cancel
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default AssignProjectModal;
