import { Input, Modal, ModalBody, Row } from "reactstrap";
import React, { useRef, useState } from "react";
import { getAsset, getFiles } from "@store/asset/assetActions";
import { useDispatch, useSelector } from "react-redux";

import AssetStorage from "./AssetStorage";
import { Can } from "@permissions/Can";
import CommentList from "./components/CommentList";
import { CommentsIcons } from "../../../assets/svg/comments.js";
import { DetailsIcon } from "../../../assets/svg/details.js";
import EmojiPicker from "emoji-picker-react";
import FilePickerUpload from "components/CustomUpload/filePickerUpload";
import { FilesIcon } from "../../../assets/svg/files.js";
import ImageUpload from "components/CustomUpload/ImageUpload";
import crossicon from "../../../assets/img/crossicon.svg";
import emojiIcon from "../../../assets/svg/Emoji.svg";
import { fetchUsers } from "@store/customer/customerActions";
import fileAttachment from "../../../assets/svg/file_attachment.svg";
import paperplane from "../../../assets/img/paperplane.svg";
import pencilicon from "../../../assets/img/pencilicon.svg";
import plusicon from "../../../assets/img/plusicon.svg";
import { rgbToHex } from "@utils";
import styled from "styled-components";
import { useEffect } from "react";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

const TabStyle = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
`;

const TagModal = ({
  show,
  handleClose,
  handleFileUpload,
  handleFileClicked,
  handleComment,
  selectedAsset,
  handleEditAsset,
}) => {
  const dispatch = useDispatch();
  const emojiRef = useRef();
  useOnClickOutside(emojiRef, () => handleShowEmojies());
  const { selectedAssetComments, loading } = useSelector(
    (state) => state.assets,
  );

  const { users } = useSelector((state) => state.customers);
  const [message, setMessage] = useState("");
  const commentRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState("details");
  const [showEmojies, setShowEmojies] = useState(false);
  const [fileName, setFileName] = useState("");

  const color = selectedAsset?.color
    ? rgbToHex(selectedAsset?.color)
    : "#4b1616";
  const handleCommentClicked = (title) => {
    setMessage("");
    handleComment({
      title: title,
      assetId: selectedAsset._id,
      messageType: "text",
    });
  };

  const handleShowEmojies = () => {
    setShowEmojies(!showEmojies);
  };
  const handleInput = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    if (selectedAsset) {
      dispatch(getFiles(selectedAsset._id));
      dispatch(getAsset(selectedAsset._id));
    }
  }, [selectedAsset]);
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  const handleEmojiChange = (emojiObject) => {
    const cursor = document.getElementById("text").selectionStart;
    const text = `${message.slice(0, cursor) + emojiObject.emoji + message.slice(cursor)
      }`;
    setMessage(text);
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleCommentClicked(message);
    }
  };
  return (
    <Modal
      isOpen={show}
      className="modal-dialog"
      onHide={handleClose}
    // centered={true}
    >
      <ModalBody className="transparent-modal ">
        <div
          className="space-y-10 w-full"
          style={{
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <div
                style={{
                  background: color,
                  borderRadius: "5px",
                  padding: "0.4rem 1rem",
                  fontSize: "24px",
                  color: "white",
                }}
              >
                {selectedAsset?.index + 1}
              </div>
              <div
                style={{ color: "white", fontSize: "24px", fontWeight: "500" }}
              >
                {selectedAsset?.title}
              </div>
            </div>
            <div
              style={{
                background: "#90C7FB",
                height: "30px",
                width: "30px",
                borderRadius: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <img src={crossicon} />
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid #ffff", marginTop: "10px" }}
          ></div>

          <div style={styles.tabContainer}>
            <TabStyle
              background={selectedTab == "details" ? "#23A6CF" : "#fff"}
              color={selectedTab == "details" ? "#ffff" : "black"}
              onClick={() => setSelectedTab("details")}
            >
              <DetailsIcon
                fill={selectedTab == "details" ? "white" : "black"}
              />
              Details
            </TabStyle>

            <TabStyle
              background={selectedTab == "files" ? "#23A6CF" : "#fff"}
              color={selectedTab == "files" ? "#ffff" : "black"}
              onClick={() => setSelectedTab("files")}
            >
              <FilesIcon fill={selectedTab == "files" ? "white" : "black"} />
              Files
            </TabStyle>

            <TabStyle
              background={selectedTab == "comments" ? "#23A6CF" : "#fff"}
              color={selectedTab == "comments" ? "#ffff" : "#black"}
              onClick={() => setSelectedTab("comments")}
            >
              <CommentsIcons
                fill={selectedTab == "comments" ? "white" : "black"}
              />
              Comments
            </TabStyle>
          </div>
          <div className="tag-modal-content" style={{ border: "none" }}>
            {selectedTab == "files" && (
              <div
                style={{
                  minHeight: "40vh",
                  maxHeight: "40vh",
                  overflow: "hidden",
                }}
              >
                <AssetStorage
                  id={selectedAsset?._id}
                  handleFileClicked={handleFileClicked}
                  handleFileUpload={handleFileUpload}
                />
              </div>
            )}
            {selectedTab == "details" && (
              <div style={{ height: "40vh" }}>
                <div className="tag-modal-title">
                  <div style={{ fontWeight: "bold" }}>Assets Details</div>
                  <div style={{ cursor: "pointer" }}>
                    <Can I="edit" a="Asset">
                      <img
                        src={pencilicon}
                        onClick={() => handleEditAsset(selectedAsset)}
                      />
                    </Can>
                  </div>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                <div className="tag-modal-form col-12">
                  <div className="col-6 p-0">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <div
                        className="input-inline-tag-form"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <label>TITLE *</label>
                        <div className="input-inline-tag-text">
                          {selectedAsset?.title}
                        </div>
                      </div>
                      <div className="input-inline-tag-form">
                        <label>Asset Name</label>
                        <div className="input-inline-tag-text">
                          {selectedAsset?.assetName}
                        </div>
                      </div>
                      <div className="input-inline-tag-form">
                        <label>Asset Value</label>
                        <div className="input-inline-tag-text">
                          {selectedAsset?.assetValue}
                        </div>
                      </div>
                      <div className="input-inline-tag-form">
                        <label>Maintenance Date</label>
                        <div className="input-inline-tag-text">
                          {selectedAsset?.maintenanceDate}
                        </div>
                      </div>
                      <div className="input-inline-tag-form">
                        <label>Users</label>
                        <div
                          style={{
                            marginBottom: "5px",
                            display: "flex",
                            gap: "3px",
                          }}
                        >
                          {selectedAsset &&
                            selectedAsset?.users.map((user) => (
                              <div
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  display: "flex",
                                  borderRadius: "17px",
                                  background: "#51bcda",
                                  alignItems: "center",
                                  justifyContent: "center",

                                  fontSize: "20px",
                                  color: "white",
                                  padding: 0,
                                }}
                              >
                                {user?.label &&
                                  user?.label.charAt(0).toUpperCase()}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="input-inline-tag-form">
                      <label>Asset Model</label>
                      <div className="input-inline-tag-text">
                        {selectedAsset?.assetModel}
                      </div>
                    </div>
                    <div className="input-inline-tag-form">
                      <label>Maintenance Provider</label>
                      <div className="input-inline-tag-text">
                        {selectedAsset?.maintenanceProvider}
                      </div>
                    </div>
                    <div className="description-tag-modal">
                      <label>Description</label>
                      <div
                        style={{
                          background: "#ECF0FF",
                          // borderRadius: '10px',
                          padding: "5px",
                          height: "6rem",
                          overflow: "auto",
                        }}
                      >
                        <div
                          style={{ color: "#8a8787" }}
                          className="input-inline-tag-text"
                        >
                          {selectedAsset?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
        {selectedTab == "comments" && (
          <div
            style={{

              height: "40vh",
              padding: "10px",
              background: "white",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                maxHeight: "250px",
                minHeight: "250px",
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
                paddingBottom: "60px",

              }}
            >
              {selectedAssetComments.map((comment) => (
                <CommentList comment={comment} setSelectedTab={setSelectedTab} />
              ))}
            </div>
            <div
              style={{
                bottom: 0,
                backgroundColor: "white",
                padding: "10px",
                height: "40px",
                borderRadius: "40px",
                border: "1px solid #808080",
                position: "absolute",
                width: "93%",
                bottom: "23px",
              }}
              className="action-comment"
            >
              <div style={styles.selectorStyle}>
                {/* myEmoji */}
                <div>
                  <div style={{ cursor: "pointer" }}>
                    <img
                      src={emojiIcon}
                      width="16px"
                      height="16px"
                      onClick={() => {
                        handleShowEmojies();
                      }}
                    />
                  </div>
                  {showEmojies ? (
                    <div style={styles.emojiPicker} ref={emojiRef}>
                      <EmojiPicker
                        searchDisabled={true}
                        lazyLoadEmojis={true}
                        onEmojiClick={handleEmojiChange}
                        lazyLoad={true}
                      />
                      {/* <Picker onEmojiClick={handleEmojiChange} /> */}
                    </div>
                  ) : null}
                </div>
                <FilePickerUpload
                  name="attachedFile"
                  handleFileUpload={handleFileUpload}
                  setFileName={setFileName}
                  handleComment={handleComment}
                  assetId={selectedAsset._id}
                />
              </div>
              <div style={{ borderLeft: "1px solid rgb(128, 128, 128)" }}></div>
              <Input
                name="description"
                id="text"
                type="textfield"
                ref={commentRef}
                onBlur={(e) => {
                  console.log("e=>", e);
                }}
                rows="1"
                style={{
                  border: "none",
                  padding: "0px 10px",
                  fontSize: "14px",
                  overflowY: "hidden",
                }}
                value={message}
                onInput={(e) => handleInput(e)}
                placeholder={" Type your comment here ...."}
                onKeyPress={handleKeypress}
              />
              <span
                style={styles.sendIcon}
                onClick={() => handleCommentClicked(message)}
              >
                <img style={styles.paperPlane} src={paperplane} />
              </span>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

const styles = {
  tabContainer: {
    background: "white",
    display: "grid",
    alignItems: "center",
    justifyContent: "space-around",
    height: "60px",
    // cursor: 'pointer',
    gridTemplateColumns: "1fr 1fr 1fr",
  },
  activeTab: {
    background: "#23A6CF",
    cursor: "pointer",
    color: "white",
    height: "100%",
    display: "flex",
  },
  tab: {
    background: "#ffff",
    color: "black",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlignCenter: "center",
  },
  selectorStyle: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "center",
  },
  paperPlane: {
    rotate: "-40deg",
  },
  emojiPicker: {
    cursor: "pointer",
    position: "absolute",
    left: "10px",
    bottom: "0px",
  },
  sendIcon: {
    background: "#51BCDA",
    borderRadius: "100%",
    width: "35px",
    paddingLeft: "5px",
    height: "36px",
    display: "flex ",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    right: "1px",
    position: "absolute",
    top: "1px",
  },
};

export default TagModal;
