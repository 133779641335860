import { styles } from "../../../views/admin/styles";
import React, { useState } from "react";
import { Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";

const AddUserPasswordField = ({
  type,
  placeholder,
  onChange,
  id,
  label,
  name,
  control,
  error,
  errorMessage,
  readOnly,
  rows,
  height,
  autoComplete,
}) => {
  const [eye, seteye] = useState(true);
  const [inpass, setinpass] = useState("password");
  const Eye = () => {
    if (inpass == "password") {
      setinpass("text");
      seteye(false);
    } else {
      setinpass("password");
      seteye(true);
    }
  };
  return (
    <>
      {label && <Label style={{ textTransform: "uppercase" }}>{label}</Label>}
      <div style={styles.passconfirm}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <input
                {...field}
                style={{ border: "0px white", width: "90%", outline: "none" }}
                id={id}
                type={inpass}
                placeholder={placeholder}
                autoComplete="off"
              />
              <i
                onClick={Eye}
                className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
              ></i>
            </>
          )}
        />
        {true && <div className="has-error-true">{errorMessage}</div>}
      </div>
    </>
  );
};
export { AddUserPasswordField };
