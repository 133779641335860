import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "@api/axios";
import { toast } from "react-toastify";
import defineRulesFor from "@permissions/Ability";

const fetchCustomers = createAsyncThunk(
  "customers/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const { rows, page, query } = data;
      const res = await axiosInstance.get(
        `/customers?page=` + page + `&limit=` + rows + `&query=` + query,
      );
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const fetchUsers = createAsyncThunk(
  "users/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/users`);

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const createCustomer = createAsyncThunk(
  "customers/create",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/user`, body);
      return res.data;
    } catch (err) {
      toast.warn(err);
      return rejectWithValue(err);
    }
  },
);
const updateCustomer = createAsyncThunk(
  "customers/update",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/user/` + id, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const deleteCustomer = createAsyncThunk(
  "customers/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/user/` + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const createUser = createAsyncThunk(
  "users/create",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/user`, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const updateUser = createAsyncThunk(
  "user/update",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/user/` + id, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const deleteUser = createAsyncThunk(
  "user/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/user/` + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const getUsersByProject = createAsyncThunk(
  "customers/byProject",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`user/getusersbyproject/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);

export {
  fetchCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  getUsersByProject,
};
