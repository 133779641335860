import React, {useRef, useState} from 'react'
import {Button} from 'reactstrap'
import {ReactComponent as EmptyStorage} from '../../../assets/svg/Emptystorage.svg'
import {useDispatch, useSelector} from 'react-redux'
import {getFiles} from '@store/asset/assetActions'
import {useEffect} from 'react'
import {ReactComponent as ShareSm} from '../../../assets/svg/ShareSm.svg'
import {AiFillCloseCircle} from 'react-icons/ai'
import {useOnClickOutside} from '@hooks/useOnClickOutside'
import {CopyToClipboard} from 'react-copy-to-clipboard'

const ProjectShare = ({id, handleClose}) => {
  //const [copied, setCopied] = useState(false);
  const dispatch = useDispatch()
  const {selectedProjectFiles, loading} = useSelector(state => state.projects)
  const ref = useRef()
  useOnClickOutside(ref, () => handleClose())
  useEffect(() => {
    if (id) {
      dispatch(getFiles(id))
    }
  }, [id])

  return (
    <div style={{padding: '0 1rem', width: '100%'}} ref={ref}>
      <div className="display-flex border-btm">
        <h6 className="container-heading">FILE SHARING</h6>
        <AiFillCloseCircle onClick={() => handleClose()} size={20} style={{marginTop: 5}} />
      </div>

      <div className="empty-list">
        {selectedProjectFiles.length > 0 ? (
          <ProjectImages files={selectedProjectFiles} />
        ) : (
          <ProjectEmptyImages />
        )}
      </div>
    </div>
  )
}

const ProjectImages = ({files}) => {
  const [copiedItemIndex, setCopiedItemIndex] = useState('')
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {files.map((file, index) => (
        <div style={{display: 'flex', margin: '5px 5px', width: '100%'}}>
          <img
            style={{width: '42px', height: '42px', borderRadius: '10px'}}
            key={file._id}
            src={file?.file}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '5px',
              marginTop: '-1px',
              width: '100%',
            }}
          >
            <div
              style={{
                padding: '5px',
                display: 'flex',
                background: '#F5F5F5',
                borderRadius: '5px',
                alignItems: 'center',
              }}
            >
              <ShareSm style={{margin: '5px'}} />
              <div
                id={file.name}
                style={{
                  fontWeight: '500',
                  fontSize: '10px',
                  color: '#727272',
                  margin: '0',
                  overflow: 'scroll',
                  padding: '8px',
                }}
                className="share-file-url"
              >
                <a href={file?.file}>Click here to Go to file</a>
              </div>
            </div>

            <Button
              onClick={() => {
                setCopiedItemIndex(index)
                navigator.clipboard.writeText(file?.file)
              }}
              style={{
                background: '#51BCDA',
                width: 'fit-content',
                padding: '10px 15px',
                fontSize: '10px',
              }}
            >
              {copiedItemIndex === index ? 'Copied' : 'copy'}
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}
const ProjectEmptyImages = ({}) => {
  return (
    <div className="empty-list mt-40">
      <EmptyStorage />
      <p className="empty-text">Upload in storage to share here</p>
    </div>
  )
}
export default ProjectShare
