import { rgbToHex } from '@utils'
import { ReactComponent as DoneSm } from '../../../assets/svg/doneSm.svg'
import more from '../../../assets/img/more.png'
import editiconblack from '../../../assets/img/editiconblack.svg'
import tickicon from '../../../assets/img/tickicon.svg'
import trashicon from '../../../assets/img/trashicon.svg'
import commenticon from '../../../assets/img/commenticon.svg'
import React, { useRef, useState } from 'react'
import { useOnClickOutside } from '../../../@hooks/useOnClickOutside'
import { Can } from '@permissions/Can'

const MatterPortTag = ({
  _id,
  color,
  number,
  title,
  status,
  onClick,
  show,
  selectedAsset,
  handleClose,
  handleAddComment,
  handleDeleteAsset,
  handleEditAsset,
  handleAssetStatus,
  handleNavigateAsset,
  handleFileUploadAsset,
}) => {
  const ref = useRef()
  const [showTagDropdown, setShowTagDropdown] = useState(false)
  const statusText = status === 'Completed' ? 'InComplete' : 'Complete'

  const handlelistClose = () => {
    setShowTagDropdown(false)
  }

  useOnClickOutside(ref, () => handlelistClose())

  return (
    <div className="tag" ref={ref}>
      <div
        className="tag-number-detail"
        style={{
          background: rgbToHex(color),
          width: '20%',
          padding: 0,
          display: 'grid',
          placeItems: 'center',
          cursor: 'pointer',
        }}
      >
        <p style={{ fontSize: '22px' }}> {number}</p>
      </div>
      <div className="tag-title" style={{ width: '80%', cursor: 'pointer' }}>
        <p style={{ fontSize: '14px' }}>
          {title.length >= 15 ? <div>{title.substring(0, 15)} ...</div> : title}
        </p>
        {status === 'Completed' && <DoneSm height={16} width={16} />}
      </div>
      <div>
        <div style={{ padding: '15px 10px' }} className="tagdetailsmenu">
          <img
            src={more}
            style={{ cursor: 'pointer', height: '20px', width: "27px" }}
            onClick={() => setShowTagDropdown(!showTagDropdown)}
          />

          <div
            className={`${showTagDropdown ? 'tag-details-menu-dropdown' : 'tag-details-menu-dropdown-hidden'
              }`}
          >
            <Can I="edit" a="Asset">
              <div className="details-menu-item" onClick={handleEditAsset}>
                <img src={editiconblack} />
                <div>Edit</div>
              </div>
            </Can>
            <div className="details-menu-item" onClick={handleAssetStatus}>
              <img src={tickicon} />
              <div>{statusText}</div>
            </div>
            <Can I="delete" a="Asset">
              <div className="details-menu-item" onClick={handleDeleteAsset}>
                <img src={trashicon} />
                <div>Delete</div>
              </div>
            </Can>
            <div className="details-menu-item" onClick={handleNavigateAsset}>
              <img src={commenticon} />
              <div>Go to the tag</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export { MatterPortTag }
