export const FilesIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_159_59)">
      <path
        d="M15.7502 5.3481C15.7502 4.9901 15.46 4.7 15.1021 4.7H1.0983C0.740395 4.7 0.450195 4.9902 0.450195 5.3481V25.3518C0.450195 25.7098 0.740395 25.9999 1.0983 25.9999H15.102C15.46 25.9999 15.7501 25.7097 15.7501 25.3518V5.3481H15.7502ZM4.3835 9.1H11.9835C12.3978 9.1 12.7335 9.4357 12.7335 9.85C12.7335 10.2643 12.3978 10.6 11.9835 10.6H4.3835C3.9692 10.6 3.6335 10.2643 3.6335 9.85C3.6335 9.4357 3.9692 9.1 4.3835 9.1ZM13.1835 19H2.8835C2.4692 19 2.1335 18.6643 2.1335 18.25C2.1335 17.8357 2.4692 17.5 2.8835 17.5H13.1835C13.5978 17.5 13.9335 17.8357 13.9335 18.25C13.9335 18.6643 13.5978 19 13.1835 19ZM13.1835 14.8H2.8835C2.4692 14.8 2.1335 14.4643 2.1335 14.05C2.1335 13.6357 2.4692 13.3 2.8835 13.3H13.1835C13.5978 13.3 13.9335 13.6357 13.9335 14.05C13.9335 14.4643 13.5978 14.8 13.1835 14.8ZM25.5502 0.75V22.05C25.5502 22.4642 25.2477 22.8 24.8335 22.8H17.3502V14.5H21.6835C22.0978 14.5 22.4335 14.1643 22.4335 13.75C22.4335 13.3357 22.0978 13 21.6835 13H17.3502V10.6H21.6835C22.0978 10.6 22.4335 10.2643 22.4335 9.85C22.4335 9.4357 22.0978 9.1 21.6835 9.1H17.3502V6.6H20.4835C20.8978 6.6 21.2335 6.2643 21.2335 5.85C21.2335 5.4357 20.8978 5.1 20.4835 5.1H17.3169C17.1794 4.0471 16.2787 3.2 15.189 3.2H8.3502V0.75C8.3502 0.3358 8.7193 0 9.1335 0H24.8335C25.2477 0 25.5502 0.3358 25.5502 0.75Z"
        fill={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_159_59">
        <rect width="26" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
