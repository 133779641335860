import React from "react";

function ListItem(props) {
  const { onClick, itemSelected, icon } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {itemSelected && <span id="triangle-up" />}
      {icon}
    </div>
  );
}

export default ListItem;
