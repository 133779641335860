import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

function ProtectedRoute({ Component, type, ...rest }) {
  const { role, user } = useSelector((state) => state.login);

  return (
    <Route
      {...rest}
      render={() => {
        const access_token = localStorage.getItem("access_token");
        const customerAccessToken = localStorage.getItem(
          "customer_access_token",
        );
        if (access_token && customerAccessToken) {
          if (type === "ADMIN") {
            return <Redirect to="/customer/dashboard" />;
          } else if (type === "CUSTOMER" && role === "USER") {
            return <Redirect to="/user/dashboard" />;
          } else if (type === "USER" && role === "CUSTOMER") {
            return <Redirect to="/customer/dashboard" />;
          } else {
            return <Component />;
          }
        } else if (access_token) {
          return <Component />;
        }
        return <Redirect to="/login" />;
      }}
    />
  );
}

export default ProtectedRoute;
