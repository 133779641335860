import Select from "react-select";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, Label } from "reactstrap";
// 
import { UserSettingValidation } from "./AddUserValidation";
import { AddUserPasswordField } from "./AddUserPasswordField";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useOnClickOutside } from "../../../@hooks/useOnClickOutside";
import { ReactFormInput as Input } from "../../../components/Common/Input";
// 
const SettingsModal = ({ show, handleClose, handleCreateItem }) => {
  const { user } = useSelector((state) => state.login)
  const [userData, setUserData] = useState(user)
  useEffect(() => { setUserData(user) }, [user])
  const onCancel = (e) => {
    handleClose();
  };
  return (
    <Modal isOpen={show}
      className="modal-lg modal-responsiveness"
      onHide={handleClose}>
      <ModalHeader closeButton>Settings</ModalHeader>
      <ModalBody>
        <SettingsForm
          onSubmit={handleCreateItem}
          onCancel={onCancel}
          user={userData}
        />
      </ModalBody>
    </Modal>
  );
};
// 
const SettingsForm = ({ onCancel, onSubmit, user }) => {
  const [userPhoto, setUserPhoto] = useState(user ? user?.photo : "")
  console.log('➡️ 🤷‍♂️ file: SettingsModal.js:47 🤷‍♂️ userPhoto', userPhoto)
  const defaultValues = {
    id: user._id,
    password: "",
    photo: userPhoto,
    passwordConfirmation: "",
    isEdit: user ? true : false,
    name: user ? user.name : "",
    email: user ? user.email : "",
    phone: user ? user.phone : "",
    address: user ? user.address : "",
    description: user ? user.description : "",
    twoFactorAuthentication: user?.twoFactorAuthentication ? user?.twoFactorAuthentication : true,
  };
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: UserSettingValidation,
  });
  function getLabel () {
    if (user?.twoFactorAuthentication) {
      return 'Enable'
    }
    else if (user?.twoFactorAuthentication === false || null) {
      return 'Disable'
    }
    return 'Enable'
  }
  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());
  return (
    <div ref={ref} className="space-y-10 w-full">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <input
          type="email"
          name="email"
          style={{ width: 0, height: 0, opacity: 0 }}
        ></input>
        <input
          type="password"
          style={{ width: 0, height: 0, opacity: 0 }}
        ></input>
        <Row>
          <Col>
            <div className="space-y-4">
              <Input
                reactForm
                label={"Name *"}
                name="name"
                control={control}
                type="text"
                placeholder={"Name"}
                error={formState?.errors?.name}
                errorMessage={formState?.errors?.name?.message}
              />
            </div>
            <div className="space-y-4">
              <Input
                reactForm
                label={"Email *"}
                name="email"
                autoComplete="new-password"
                control={control}
                type="email"
                placeholder={""}
                error={formState?.errors?.email}
                errorMessage={formState?.errors?.email?.message}
              />
            </div>
            <div className="space-y-4">
              <AddUserPasswordField
                reactForm
                label="Password *"
                name="password"
                control={control}
                type="password"
                placeholder={"Password"}
                error={formState?.errors?.password}
                errorMessage={formState?.errors?.password?.message}
              />
              <AddUserPasswordField
                reactForm
                label="Confirm Password *"
                name="passwordConfirmation"
                control={control}
                type="password"
                placeholder={"Confirm Password"}
                error={formState?.errors?.passwordConfirmation}
                errorMessage={formState?.errors?.passwordConfirmation?.message}
              />
            </div>
            <div className="space-y-4">
              <Input
                reactForm
                label={"Phone *"}
                name="phone"
                control={control}
                type="phone"
                placeholder={""}
                error={formState?.errors?.phone}
                errorMessage={formState?.errors?.phone?.message}
              />
            </div>
            <div className="space-y-4">
              <Input
                reactForm
                label={"Description"}
                name="description"
                control={control}
                type="textarea"
                placeholder={""}
              />
            </div>
          </Col>
          <Col>
            <div className="space-y-4">
              <ImageUpload
                label={"Upload FIle"}
                setValue={setValue}
                profilepic={true}
                name="photo"
                control={control}
                uploadedImage={userPhoto}
                imageHeight={"10rem"}
                error={formState?.errors?.photo}
                errorMessage={formState?.errors?.photo?.message}
                setUserPhoto={setUserPhoto}
              />
            </div>
            <div className="space-y-4">
              <Label>Two factor authentication</Label>
              <Select
                name="twoFactorAuthentication"
                className="react-select"
                placeholder="Single Select"
                control={control}
                options={
                  [
                    { label: "Enable", value: true },
                    { label: "Disable", value: false }
                  ]}
                defaultValue={{
                  label: getLabel()
                  , value: user?.twoFactorAuthentication ? user?.twoFactorAuthentication : true
                }}
                onChange={(field) => setValue('twoFactorAuthentication', field.value)}
              />
            </div>
            <div>
              <Button type="submit" color="primary">
                Save Changes
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={onCancel}
              >
                cancel
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};
export default SettingsModal;
