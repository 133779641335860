import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  logoutUser,
  loginUserInSystem,
  sendOtp,
  setSendOtpStatus,
  getCustomerToken,
  removeCustomerToken,
  updateLoginUser,
} from "./loginActions";
const customerTokenPresent = localStorage.getItem("customer_access_token")
  ? true
  : false;
const initialState = {
  user: {},
  loading: false,
  login: false,
  sendOtpStatus: null,
  userData: {},
  role: "",
  accessType: "",
  isCustomer: customerTokenPresent,
};

export const LoginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.loading = false;
        state.userData = payload.result;
        state.user = payload.result.user;
        state.role = payload.result.user.role[0];
        state.accessType = payload.result.user.accessType;
        state.sendOtpStatus = payload.result.otp;
      }
    },
    [updateLoginUser.rejected]: (state, error) => {
      state.loading = false;
    },
    [updateLoginUser.pending]: (state) => {
      state.loading = true;
    },
    [updateLoginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userData = payload.result;
      state.user = payload.result.user;
      state.role = payload.result.user.role[0];
      state.accessType = payload.result.user.accessType;
      state.sendOtpStatus = payload.result.user.twoFactorAuthentication;
    },
    [loginUser.rejected]: (state, error) => {
      state.loading = false;
    },
    [sendOtp.pending]: (state) => {
      state.loading = true;
    },
    [sendOtp.fulfilled]: (state) => {
      state.loading = false;
      state.sendOtpStatus = false;
    },
    [sendOtp.rejected]: (state, error) => {
      state.loading = false;
    },
    [setSendOtpStatus.pending]: (state) => {
      state.loading = true;
    },
    [setSendOtpStatus.fulfilled]: (state) => {
      state.loading = false;
      state.sendOtpStatus = false;
    },
    [setSendOtpStatus.rejected]: (state, error) => {
      state.loading = false;
    },
    [loginUserInSystem.pending]: (state) => {
      state.loading = true;
    },
    [loginUserInSystem.fulfilled]: (state) => {
      state.loading = false;
      state.login = true;
    },
    [loginUserInSystem.rejected]: (state, error) => {
      state.loading = false;
    },
    [logoutUser.pending]: (state) => {
      state.loading = true;
    },
    [logoutUser.fulfilled]: (state, { history }) => {
      state.loading = false;
      state.user = {};
      state.login = false;
      state.role = "";
      state.accessType = "";
    },
    [logoutUser.rejected]: (state) => {
      state.loading = false;
    },
    [getCustomerToken.pending]: (state) => {
      state.loading = true;
    },
    [getCustomerToken.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isCustomer = true;
      state.role = payload.result.role[0];
      state.accessType = payload.result.accessType;
    },
    [getCustomerToken.rejected]: (state) => {
      state.loading = false;
    },
    [removeCustomerToken.pending]: (state) => {
      state.loading = true;
    },
    [removeCustomerToken.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isCustomer = false;
      state.role = "ADMIN";
    },
    [removeCustomerToken.rejected]: (state) => {
      state.loading = false;
    },
  },

});

// this is for configureStore
export default LoginSlice.reducer;
