import React, { useRef } from "react";
import { Button } from "reactstrap";
import { ReactComponent as EmptyStorage } from "../../../assets/svg/Emptystorage.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProjectFiles } from "@store/project/projectActions";
import { AiFillCloseCircle } from "react-icons/ai";
import { FiArrowDownCircle } from "react-icons/fi";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import Download from "../../../assets/svg/Download.svg";
import { saveAs } from "file-saver";
import ImageList from "./components/ImageList";
import ImageEmpty from "./components/ImageEmpty";
import { deleteProjectFile } from "@store/project/projectActions";
import { editProjectFile } from "@store/project/projectActions";
const ProjectStorage = ({
  id,
  handleClose,
  handleFileUpload,
  handleFileClicked,
}) => {
  const dispatch = useDispatch();
  const { selectedProjectFiles, loading } = useSelector(
    (state) => state.projects,
  );
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  const handleFileDeleted = (file) => {
    dispatch(deleteProjectFile(file?._id));
  };
  const handleFileEdited = ({ name, id }) => {
    dispatch(editProjectFile({ id, body: { name } }));
  };
  useEffect(() => {
    if (id) {
      dispatch(getProjectFiles(id));
    }
  }, [id]);
  return (
    <div style={{ width: "fit-content" }}>
      <div
        className="display-flex border-btm"
        style={{ paddingInline: "0.4rem" }}
      >
        <h6 className="container-heading">STORAGE</h6>
        <AiFillCloseCircle
          onClick={() => handleClose()}
          size={20}
          style={{ marginTop: 5 }}
        />
      </div>

      <div className="empty-storage-list">
        {selectedProjectFiles.length > 0 ? (
          <>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <ImageList
                files={selectedProjectFiles}
                handleDeleteImage={handleFileDeleted}
                handleEditImage={handleFileEdited}
              />
            </div>
          </>
        ) : (
          <>
            <ImageEmpty />
          </>
        )}
        <Button color="info" filled type="button" onClick={handleFileUpload}>
          Upload File
        </Button>
      </div>
    </div>
  );
};

export default ProjectStorage;
