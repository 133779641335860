import React from 'react'
import { CardBody, Col, Card, Popover, PopoverBody, Row } from 'reactstrap'
import editIcon from '../../assets/img/editicon.svg'
import deleteIcon from '../../assets/img/deleteicon.svg'
import { deleteTutorial } from '@store/tutorials/tutorialsActions'
import { useDispatch } from 'react-redux'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import { useRef } from 'react'
import videothumb from '../../assets/img/videothumb.png'
import DeleteModal from './modal/DeleteModal'
import { useState } from 'react'
import { Can } from '@permissions/Can'
import { BsThreeDotsVertical, BsFillPlayCircleFill } from 'react-icons/bs'

const VideoCard = ({
  title,
  thumbnail,
  src,
  id,
  handleClick,
  setCurrentSrc,
  handleEditClick,
  setSelected,
}) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [deleteShow, setDeleteShow] = useState(false)
  const handleDeleteShow = () => {
    setDeleteShow(true)
  }
  const handleDelete = () => {
    dispatch(deleteTutorial(id))
    handleDeleteClose()
  }
  const handleDeleteClose = () => {
    setDeleteShow(false)
  }
  const ref = useRef();
  useOnClickOutside(ref, () => { setPopoverOpen(false) });

  return (
    <>
      <div style={cardStyle}>
        {popoverOpen ? (
          <div ref={ref}>
            <Card style={popOverStyle}>
              <div style={popRowStyle}  >
                <img src={editIcon} style={{ height: "15px" }}
                  onClick={() => {
                    handleEditClick(title, thumbnail, src, id)
                    setPopoverOpen(false)
                    setSelected(id)
                  }} />

                Edit
              </div>
              <div style={popRowStyle} onClick={() => { handleDeleteShow(); setSelected(id) }}>
                <img src={deleteIcon} style={{ height: "15px" }} />Delete
              </div>
            </Card>
          </div>
        ) : null}
        <Can I="Edit" a="Tutorial">
          <span onClick={() => { setPopoverOpen(true); }}>
            <BsThreeDotsVertical alt="options" style={optionStyle} />
          </span>
        </Can>

        {
          thumbnail ? <img
            style={imageStyle}
            alt="Sample"
            src={thumbnail}
          /> : <img
            style={imageStyle}
            alt="Sample"
            src={videothumb}
          />
        }

        <span onClick={() => { handleClick(); setCurrentSrc(src) }}>
          <BsFillPlayCircleFill alt="vector" style={playStyles} />
        </span>
        <p style={paragraph}>{title}</p>
      </div >
      <DeleteModal
        show={deleteShow}
        handleClose={handleDeleteClose}
        handleAction={handleDelete}
      />
    </>
  )
}
export default VideoCard


const popOverStyle = {
  display: 'flex',
  padding: '12px',
  flexDirection: 'column',
  borderRadius: '12px',
  gap: '10px',
  width: '50%',
  right: '-90px',
  position: 'absolute',
  zIndex: '1',
}

const popRowStyle = {
  display: 'flex',
  justifyContent: 'space-evenly',
  fontSize: '12px',
  cursor: "pointer",

}

const imageStyle = {
  width: '258px',
  height: '342px',
  borderTopRightRadius: '28px',
  borderTopLeftRadius: '28px',

}
const cardStyle = {
  borderRadius: '28px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '258px',
  position: 'relative',
  background: 'white'
}
const paragraph = {
  padding: '0px 0px 0px 10px',
  padding: '10px 10px 0px', fontSize: '1.2rem', fontWeight: '400'
}
const optionStyle = {
  position: 'absolute',
  right: '0',
  top: '10',
  margin: '1rem',
  cursor: 'pointer'
}

const playStyles = {
  color: "#a42327",
  position: 'absolute',
  marginBottom: '60px', left: '97px',
  bottom: '0',
  top: "140px",
  height: "60px",
  width: "60px",
  background: "",
  borderRadius: "10px",
  padding: "5px",
  cursor: "pointer",

}

