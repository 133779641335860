import {CSVLink} from 'react-csv'
import {Button} from 'reactstrap'
const CsvExport = ({data, fileName}) => {
  return (
    <CSVLink data={data} filename={fileName}>
      <div style={{background: '#51BCDA', padding: '0.5rem', borderRadius: '5px', color: 'White'}}>
        Export
      </div>
    </CSVLink>
  )
}
export default CsvExport
