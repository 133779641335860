import React from "react";
import Loader from "react-loader";
const LoaderModal = (props) => {
  const { loading } = props;
  return (
    <>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader
            loaded={!loading}
            lines={13}
            length={20}
            width={5}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      )}
    </>
  );
};
export default LoaderModal;
