import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Row,
  Col,
  Progress,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { useOnClickOutside } from "../../../@hooks/useOnClickOutside";
import useBroadcast from "../../../@hooks/useBroadcast";
import { ReactFormInput as Input } from "../../../components/Common/Input";
import ImageUpload from "components/CustomUpload/ImageUpload";
import VideoUpload from "components/CustomUpload/VideoUpload";
import { AddTutorialValidation } from "./AddTutorialValidation";
import { useSelector } from "react-redux";

const AddTutorialModal = ({ current, show, handleClose, handleCreateItem }) => {
  const { channelData: fileprogress, closeChannle } =
    useBroadcast("upload-progress");
  const { loading } = useSelector((state) => state.tutorial);
  const [enableLoader, setEnableLoader] = useState(false);
  const onCancel = (e) => {
    handleClose();
  };
  useEffect(() => {
    if (fileprogress !== undefined || fileprogress !== null) {
      if (fileprogress > 0 && fileprogress <= 100 && loading == true) {
        setEnableLoader(true);
      }
      if (fileprogress === 100 && loading == false) {
        setTimeout(() => {
          onCancel();
          closeChannle();
          setEnableLoader(false);
        }, 1000);
      }
    }
    if (loading == false) {
      setTimeout(() => {
        onCancel();
      }, 1000);
    }
  }, [fileprogress, loading]);

  return (
    <Modal
      isOpen={show}
      className="modal-lg modal-responsiveness w-100  "
      onHide={handleClose}
      styles={{
        modal: {
          maxWidth: "unset",
          width: "100%",
          padding: "unset",
        },
        overlay: {
          background: "rgba(0, 0, 0, 0.5)",
        },
        closeButton: {
          background: "yellow",
        },
      }}
      center
    >
      <ModalHeader closeButton>
        {current ? "Edit Tutorial" : "Add Tutorial"}
      </ModalHeader>
      <ModalBody>
        <AddTutorialForm
          onSubmit={handleCreateItem}
          onCancel={onCancel}
          tutorial={current}
          onHide={handleClose}
          uploadProgress={fileprogress}
          enableLoader={enableLoader}
        />
      </ModalBody>
    </Modal>
  );
};

const AddTutorialForm = ({
  onSubmit,
  onCancel,
  tutorial,
  enableLoader,
  uploadProgress,
}) => {
  const options = [
    { value: "View", label: "View" },
    { value: "Edit", label: "Edit" },
  ];

  const defaultValues = {
    title: tutorial ? tutorial.title : "",
    video: tutorial ? tutorial.src : "",
    thumbnail: tutorial ? tutorial.thumbnail : "",
  };

  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: AddTutorialValidation,
  });
  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());

  return (
    <div className="space-y-10 w-full" ref={ref}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 w-full md:w-96"
        autoComplete="off"
      >
        <Input
          reactForm
          label={"Tutorial title"}
          name="title"
          control={control}
          type="text"
          placeholder={""}
          error={formState?.errors?.title}
          errorMessage={formState?.errors?.title?.message}
        />
        <Input
          reactForm
          label={"Tutorial link"}
          name="video"
          control={control}
          type="text"
          placeholder={""}
          error={formState?.errors?.videoLink}
          errorMessage={formState?.errors?.title?.message}
        />
        <Row>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "17px",
              flexDirection: "column",
            }}
            className="w-full"
          >
            <ImageUpload
              className="w-full"
              label={"Upload Thumbnail"}
              imageHeight={"10rem"}
              name="thumbnail"
              setValue={setValue}
              control={control}
              uploadedImage={tutorial ? tutorial.thumbnail : ""}
              error={formState?.errors?.thumbnail}
            />
          </div>
        </Row>
        {/* {
          tutorial ? null :
            <VideoUpload
              label={tutorial ? "Change Video" : "Upload Video"}
              imageHeight={"6rem"}
              name="video"
              setValue={setValue}
              control={control}
            />

        } */}

        {enableLoader ? (
          <Progress
            animated
            className="my-3"
            color="info"
            value={uploadProgress}
          />
        ) : null}

        <div>
          <Button className="btn-wd" type="submit" color="primary">
            Save
          </Button>

          <Button
            className="btn-wd"
            type="button"
            color="secondary"
            onClick={onCancel}
          >
            cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTutorialModal;
