import { createSlice } from "@reduxjs/toolkit";
import {
  createTutorial,
  deleteTutorial,
  fetchTutorials,
  updateTutorial,
} from "./tutorialsActions";

const initialState = {
  tutorials: [],
  loading: false,
  login: false,
};

export const TutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {},
  extraReducers: {
    [createTutorial.pending]: (state) => {
      state.loading = true;
    },
    [createTutorial.fulfilled]: (state, { payload }) => {
      const tutorial = payload.result;
      const tutorials = state.tutorials;
      state.tutorials = [tutorial, ...tutorials];
      state.loading = false;
    },
    [createTutorial.rejected]: (state, error) => {
      state.loading = false;
    },
    [fetchTutorials.pending]: (state) => {
      state.loading = true;
    },
    [fetchTutorials.fulfilled]: (state, { payload }) => {
      state.tutorials = payload;
    },
    [fetchTutorials.rejected]: (state, error) => {
      state.loading = false;
    },

    [deleteTutorial.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteTutorial.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const deletedId = payload.result;
      const tutorials = state.tutorials;
      const filteredTutorials = tutorials.filter(function (obj) {
        return obj._id !== deletedId;
      });
      state.tutorials = [...filteredTutorials];
    },
    [deleteTutorial.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    [updateTutorial.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateTutorial.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const tutorial = payload.result;
      const tutorials = state.tutorials;
      const filteredtutorials = tutorials.filter(function (obj) {
        return obj._id !== tutorial._id;
      });
      state.tutorials = [tutorial, ...filteredtutorials];
    },
    [updateTutorial.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

// this is for configureStore
export default TutorialSlice.reducer;
