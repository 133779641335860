import { useOnClickOutside } from "@hooks/useOnClickOutside";
import React, { useRef } from "react";
import { Button, Modal } from "reactstrap";
import { ReactComponent as Warning } from "../../../assets/svg/warning.svg";
import warningIcon from "../../../assets/svg/warning.svg";
import warning from "react-redux/es/utils/warning";
const DeleteModal = ({
  show,
  handleClose,
  title,
  handleAction,
  buttonText,
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());
  const onCancel = (e) => {
    handleClose();
  };
  const onSubmit = () => {
    handleAction();
  };
  return (
    <Modal
      className="modal-dialog modal-sm "
      style={{
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10%",
        left: "0",
        right: "0",
      }}
      isOpen={show}
      onHide={handleClose}
    >
      <div style={styles.modalContent} ref={ref}>
        <img src={warningIcon} width={90} height={90} />
        <strong className="h2" style={styles.headingText}>
          Are You Sure?
        </strong>
        <p className="text-center">
          {title
            ? title
            : "Do you really want to delete the record? This process cannot be undone."}
        </p>
        <div style={styles.btnContainer}>
          <Button onClick={onSubmit} color="primary" style={styles.btn}>
            {buttonText ? buttonText : "Delete"}
          </Button>

          <Button
            style={{ ...styles.btn, backgroundColor: "#66615B" }}
            type="button"
            color="secondary"
            onClick={onCancel}
          >
            cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 10px",
  },
  btn: {
    width: "130px",
    backgroundColor: "#DC3545",
  },
  headingText: {
    fontWeight: "bold",
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
    fontSize: "32px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default DeleteModal;
