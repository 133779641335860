/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import profileImage from "assets/img/profilePlaceholder.png";
import defaultImage from "assets/img/placeholderImage.png";

function CoverUpload({ fileState, setFileState, label, profilepic }) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    profilepic ? profileImage : defaultImage
  );
  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFileState(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    fileInput.current.value = null;
    setFileState(null);
    setImagePreviewUrl(profilepic ? profileImage : defaultImage);
  };
  return (
    <div className="fileinput text-center w-full">
      <input
        className=".input-group-lg"
        type="file"
        onChange={handleImageChange}
        ref={fileInput}
      />
      <div>
        <img width="100%" height="120px" src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {fileState === null ? (
          <Button
            className="btn btn-primary btn-block"
            onClick={() => handleClick()}
          >
            {label}
          </Button>
        ) : (
          <span>
            <Button onClick={() => handleClick()}>Change</Button>
            <Button color="danger" onClick={() => handleRemove()}>
              <i className="fa fa-times" />
              Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

CoverUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default CoverUpload;
