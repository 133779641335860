import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CardBody, Row, Col, Card, Button} from 'reactstrap'
import ReactTooltip from 'react-tooltip'
import LoadingOverlay from 'react-loading-overlay'
import ReactTable from '../../../components/ReactTable/ReactTable'
import AddUpdateModal from './AddUpdateModal'
import {fetchUpdates} from '@store/update/updateActions'
import {createUpdate} from '@store/update/updateActions'
import {deleteUpdate} from '@store/update/updateActions'
import {dateConversion} from '@utils'
import ReactTableSearch from 'components/ReactTable/ReactTableSearch'
import DeleteModal from 'components/Common/modal/DeleteModal'
import {AiFillDelete} from 'react-icons/ai'
import ReactReadMoreReadLess from 'react-read-more-read-less'
import {fetchProjects} from '../../../@store/project/projectActions'
import CsvExport from 'components/Common/CsvExport'

const Updates = () => {
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])
  const [dataToExport, setDataToExport] = useState([])

  const [showMorest, setShowMore] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [alert, setAlert] = useState(null)

  const dispatch = useDispatch()
  const {updates, totalUpdates, pageIndex, numberOfRows, loading} = useSelector(
    state => state.updates
  )
  useEffect(() => {
    dispatch(fetchUpdates({page: pageIndex, rows: numberOfRows, query}))
  }, [pageIndex, numberOfRows, query])

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [deleteShow, setDeleteShow] = useState(false)
  const handleDeleteClose = () => {
    setDeleteShow(false)
  }
  const handleDeleteShow = () => setDeleteShow(true)
  const handleCreateUpdate = data => {
    handleClose()
    dispatch(createUpdate(data))
  }
  const handleDeleteClicked = id => {
    setSelectedId(id)
    handleDeleteShow()
  }
  const handleSearchClicked = () => {
    dispatch(fetchUpdates({rows: numberOfRows, page: pageIndex, query}))
  }
  const handleDeleteUpdate = () => {
    handleDeleteClose()
    dispatch(deleteUpdate(selectedId))
  }

  const handleShowMore = id => {
    setSelectedId(id)
  }

  const onChangePaginationInfo = (page, rows) => {
    const rowsToUpdate = rows === null ? numberOfRows : rows
    const pageToUpdate = page === null ? pageIndex : page
    dispatch(fetchUpdates({page, rows, query}))

    //  dispatch(updatePaginationInfo(pageToUpdate, rowsToUpdate));
  }
  // update the data array
  useEffect(() => {
    let tempData = []
    let dataToExportTemp = []
    if (updates?.length > 0) {
      tempData = updates.map((update, index) => {
        const item = {
          id: update?._id,
          srNumber: index + 1,
          title: update?.title,
          date: dateConversion(update?.createdAt),

          body: (
            <div className="overflow-auto" style={{maxHeight: '3rem'}}>
              <span
                className="h-25 overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: update?.body,
                }}
              ></span>
            </div>
          ),

          details: (
            <div>
              <ReactReadMoreReadLess
                charLimit={150}
                readMoreText={'See more'}
                readLessText={'See less'}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                {update?.details}
              </ReactReadMoreReadLess>
            </div>
          ),

          actions: (
            <div
              className="actions-left"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ReactTooltip id="customTooltip" getContent={dataTip => dataTip} />
              <Button
                data-for="customTooltip"
                data-tip="Delete"
                className=" btn btn-danger btn-sm"
                style={{background: '#DC3545'}}
                onClick={() => handleDeleteClicked(update._id)}
              >
                Delete
              </Button>
            </div>
          ),
        }
        return item
      })
      dataToExportTemp = updates.map(update => {
        const item = {
          title: update?.title,
          date: dateConversion(update?.createdAt),
        }

        return item
      })
    }
    setData(tempData)
    setDataToExport(dataToExportTemp)
  }, [updates, selectedId])

  return (
    <div className="content">
      <div className="TableTop">
        <div>
          <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <Button variant="primary" onClick={handleShow}>
              Add Update
            </Button>
            <CsvExport data={dataToExport} />
          </div>
          <AddUpdateModal
            show={show}
            handleClose={handleClose}
            handleCreateItem={handleCreateUpdate}
            id={selectedId}
          ></AddUpdateModal>
        </div>

        <div>
          <ReactTableSearch
            numberOfRows={numberOfRows}
            onChange={setQuery}
            onSearchClicked={handleSearchClicked}
            onChangePaginationInfo={onChangePaginationInfo}
          />
        </div>
      </div>
      <Row>
        <Col md="12">
          <ReactTable
            data={data}
            numberOfRows={numberOfRows}
            pageIndex={pageIndex}
            totalCounts={totalUpdates}
            actionAlignLeft={true}
            onChangePaginationInfo={onChangePaginationInfo}
            loading={loading}
            sticky={false}
            onChangeFilter={filters => {}}
            columns={[
              {
                Header: 'Sr No.',
                accessor: 'srNumber',
                width: 50,
              },
              {
                Header: 'Title',
                accessor: 'title',
              },
              {
                Header: 'Details',
                accessor: 'details',
                minWidth: 440,
              },
              {
                Header: 'Date and time',
                accessor: 'date',
                minWidth: 200,
              },
              {
                Header: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    Actions
                  </div>
                ),
                accessor: 'actions',
                sortable: false,
                filterable: false,
                sticky: 'right',
              },
            ]}
            className="-striped -highlight primary-pagination"
          />
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>

      <DeleteModal
        show={deleteShow}
        handleClose={handleDeleteClose}
        handleAction={handleDeleteUpdate}
      />
    </div>
  )
}
export default Updates
