/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

import { Button } from 'reactstrap'

import profileImage from 'assets/img/profilePlaceholder.png'
import defaultImage from 'assets/img/placeholderImage.png'
import { registerables } from 'chart.js'
import { Controller } from 'react-hook-form'
import { useState } from 'react'
import { getMetadata, getThumbnails, Video } from 'video-metadata-thumbnails';
import { event } from 'jquery'


function VideoUpload ({
  // fileState,
  // setFileState,
  label,
  uploadedImage,
  setValue,
  name,
  error,
  errorMessage,
  register,
  control,
}) {



  const [src, setSrc] = useState('')
  const [fileName, setFileName] = useState('')
  const [imgSrc, setImgSrc] = useState(null);
  const fileInput = React.useRef()
  const handleImageChange = async e => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      // setFileState(file);
      if (setValue) {
        setValue(name, reader.result)
        setFileName(e.target.files[0].name)
      }
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
  }
  const handleClick = () => {
    fileInput.current.click()
  }
  const handleRemove = () => {
    fileInput.current.value = null
  }
  return (
    <div className="fileinput  w-full max-h-[7rem]">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            className=".input-group-lg"
            type="file"
            onChange={handleImageChange}
            ref={fileInput}
            accept=".mp4"
            register
          />
        )}
      />
      {/* <div style={{ width: '400px' }}>
        <video id="video-element"
          src={src} controls />
      </div> */}
      <p>{fileName}</p>
      {
        imgSrc && <img src={imgSrc} alt="THUMB" />
      }
      <div>
        {uploadedImage === null || uploadedImage === undefined || uploadedImage === '} ' ? (
          <Button className="btn btn-primary" onClick={() => handleClick()}>
            {label}
          </Button>
        ) : (
          <span>
            <Button onClick={() => handleClick()}>Change</Button>
            <Button color="danger" onClick={() => handleRemove()}>
              <i className="fa fa-times" />
              Remove
            </Button>
          </span>
        )}
      </div>
      {error && <div className="has-error-true">{errorMessage}</div>}
    </div>
  )
}

VideoUpload.propTypes = {
  avatar: PropTypes.bool,
}

export default VideoUpload
