import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ReactComponent as Back } from "../../../assets/svg/Back.svg";

import paperplane from "../../../assets/img/paperplane.svg";
import editiconblack from "../../../assets/img/editiconblack.svg";
import trashicon from "../../../assets/img/trashicon.svg";
import attachicon from "../../../assets/img/attachicon.svg";
import commenticon from "../../../assets/img/commenticon.svg";
import tickicon from "../../../assets/img/tickicon.svg";
import blackmenubar from "../../../assets/img/blackmenubars.svg";
import more from "../../../assets/img/more.png";
import profileImage from "assets/img/icons/profileImage.png";
import { rgbToHex } from "@utils";
import { Input } from "reactstrap";
import { getAsset } from "@store/asset/assetActions";
import { onlyDateConversion2 } from "@utils";
import { getFiles } from "@store/asset/assetActions";
import CommentList from "./components/CommentList";

const TagDetail = ({
  show,
  selectedAsset,
  handleClose,
  handleAddComment,
  handleDeleteAsset,
  handleEditAsset,
  handleAssetStatus,
  handleNavigateAsset,
  handleFileUploadAsset,
}) => {
  const dispatch = useDispatch();
  const { selectedAssetComments, selectedAssetFiles, loading } = useSelector(
    (state) => state.assets,
  );
  const [showMore, setShowMore] = useState(false);

  const [input, setInput] = useState("");
  const [isFiles, setIsFiles] = useState(true);
  const commentRef = useRef(null);
  const [showTagDropdown, setShowTagDropdown] = useState(false);
  useEffect(() => {
    if (selectedAsset) {
      dispatch(getAsset(selectedAsset._id));
      dispatch(getFiles(selectedAsset._id));
    }
  }, [selectedAsset]);

  const handleComment = (title) => {
    setInput("");
    handleAddComment({ title: title, assetId: selectedAsset._id });
  };
  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleChangeIcon = () => {
    setIsFiles(!isFiles);
  };
  return (
    <div style={{ height: "100%" }} isOpen={show} onHide={handleClose}>
      {selectedAsset && Object.keys(selectedAsset).length > 0 && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "sticky", top: 0 }}>
            <div
              className="tagDetailsTag "
              style={{ height: "50px", margin: 0 }}
            >
              <div
                className="tag-number-detail "
                style={{
                  background: rgbToHex(selectedAsset?.color),
                  borderRadius: "0px 5px 5px 0px",
                  padding: 0,
                  width: "20%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>1</p>
              </div>
              <div className="tag-title-detail">
                <p>{selectedAsset?.title}</p>
                <Back height={24} width={24} onClick={handleClose} />
              </div>
            </div>
            <div style={{ padding: "15px 10px" }} className="tagdetailsmenu">
              <p style={{ marginBottom: "7px" }}>Description</p>
              <img
                src={more}
                style={{ cursor: "pointer", height: "20px" }}
                onClick={() => setShowTagDropdown(!showTagDropdown)}
              />

              <div
                className={`${
                  showTagDropdown
                    ? "tag-details-menu-dropdown"
                    : "tag-details-menu-dropdown-hidden"
                }`}
              >
                <div className="details-menu-item" onClick={handleEditAsset}>
                  <img src={editiconblack} />
                  <div>Edit</div>
                </div>
                <div className="details-menu-item" onClick={handleAssetStatus}>
                  <img src={tickicon} />
                  <div>Complete</div>
                </div>
                <div className="details-menu-item" onClick={handleDeleteAsset}>
                  <img src={trashicon} />
                  <div>Delete</div>
                </div>
                <div
                  className="details-menu-item"
                  onClick={handleNavigateAsset}
                >
                  <img src={commenticon} />
                  <div>Go to the tag</div>
                </div>
              </div>
            </div>
            <p className="tag-description">{selectedAsset.description}</p>
          </div>

          {/* {!isFiles ? (
              <div className="stored-files">
                <p
                  style={{ backgroundColor: "#51BCDA" }}
                  className="Project-status"
                >
                  Stored Files
                </p>

                <AssetImages files={selectedAssetFiles} />
              </div>
            ) : ( */}

          <div>
            <p
              style={{ backgroundColor: "#51BCDA", marginTop: "12px" }}
              className="Project-status"
            >
              Comments
            </p>
            <div
              className="comments"
              style={{
                paddingBottom: "10px",
                maxHeight: "250px",
                overflow: "scroll",
              }}
            >
              {selectedAssetComments.map((comment) => (
                <CommentList comment={comment} />
              ))}
            </div>
            {/* )} */}
          </div>
          <div
            style={{
              position: "sticky",
              bottom: 0,
              backgroundColor: "white",
              padding: ".5rem 0",
              // boxShadow:
              //   "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}
            className="action-comment"
          >
            <Input
              name="description"
              type="textarea"
              ref={commentRef}
              rows="1"
              // wrap="hard"
              // maxlength="100"
              style={{
                borderRadius: 40,
                padding: "0px 10px",
                fontSize: "14px",
                overflowY: "hidden",

                // overflowX: "hidden",
                // minHeight: "30px",
                // maxHeight: "120px",
              }}
              value={input}
              onInput={(e) => handleInput(e)}
              placeholder={" Type your comment here ...."}
            />
            <div
              style={{
                background: "#51BCDA",
                borderRadius: "100%",
                width: "50px",
                height: "40px",
                display: "flex ",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleComment(input)}
            >
              <img src={paperplane} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const AssetImages = ({ files }) => {
  return (
    <div
      style={{
        width: "300px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        margin: "5px 5px 5px 0",
        gap: 5,
        paddingLeft: "10px",
      }}
    >
      {files.map((file, index) => (
        <>
          <img
            style={{ width: "60px", minHeight: "60px", borderRadius: "50%" }}
            key={file._id}
            src={file?.file}
          />
        </>
      ))}
    </div>
  );
};

export default TagDetail;
