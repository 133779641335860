/*eslint-disable*/
import React, { useState, useEffect } from "react";

import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useFlexLayout,
} from "react-table";

import { matchSorter } from "match-sorter";
import Loader from "react-loader";
import { useSticky } from "react-table-sticky";
import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Input,
} from "reactstrap";
import Datetime from "react-datetime";
import moment from "moment";

import arrowright from "assets/img/arrowright.svg";
import leftArrow from "../../assets/img/arrowleft.svg";
import PaginationIcon from "components/Common/Pagination/PaginationIcon";

const Styles = styled.div`
  .table {
    display: flex;
    flex-direction: column;
    text-align:center;
    width:100%;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    .tr {
      background:white,
      width:100%
      align-items:center;
    }
    .th {
      background: #f4f3ef;
      font-weight: 700;
      padding: 0px 0px 0px 12px;
      padding-left: 15px;
      z-index: 0;
      
    }
    .td {
      padding: 20px 0px 20px 10px;
      padding-left: 15px;
      background-color: #fff;
      overflow: hidden;
      align-items:center;
      
      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow-y: scroll;
      .header,
      .footer {
        position: sticky;
      }

      .header {
        top: 0;
        margin-bottom: 0;
        border: 1px solid #d9d9d9;
        padding: 20px 0 10px 0;
        width: auto;
      }
      
      .projects-header {
        top: 0;
        min-width:100% !important;
        margin-bottom: 0;
        border: 1px solid #d9d9d9;
        padding: 20px 0 10px 0;
         width: fit-content;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
        z-index: 0 !important;
        align-items:center;
      }

      [data-sticky-last-left-td] {
      }

      [data-sticky-first-right-td] {
      }
    }
  }
`;

const numberOfRowsOptions = [
  {
    value: 5,
    label: 5 + " rows",
  },
  {
    value: 10,
    label: 10 + " rows",
  },
  {
    value: 25,
    label: 25 + " rows",
  },
  {
    value: 50,
    label: 50 + " rows",
  },
  {
    value: 100,
    label: 100 + " rows",
  },
];
const DefaultColumnFilter = ({ column }) => {
  const { preFilteredRows, setFilter, date } = column;
  const count = preFilteredRows?.length;
  const DATE_FORMAT = "DD/MM/YYYY";
  return (
    <FormGroup>
      {date ? (
        <>
          <Datetime
            placeholder={`Search ${count} records...`}
            dateFormat={DATE_FORMAT}
            timeFormat={false}
            autoComplete="off"
            onChange={(val) => {
              setFilter(val && val != "" && moment(val).format(DATE_FORMAT));
            }}
          />
        </>
      ) : (
        <Input
          placeholder={`Search ${count} records...`}
          type="text"
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        />
      )}
    </FormGroup>
  );
};

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]],
  });
};

fuzzyTextFilterFn.autoRemove = (val) => !val;

let debounceTimer;

const Table = ({
  columns,
  data,
  noPagination,
  pageIndex,
  numberOfRows,
  totalCounts,
  onChangePaginationInfo,
  onCellClick,
  onChangeFilter,
  useDateTime,
  actionAlignLeft,
  loading,
  projects,
  initialState,
  sticky,
}) => {
  const [cellSize, setCellSize] = useState("19%");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const noOfCols = columns.length;
    if (
      noOfCols === 2 ||
      noOfCols === 3 ||
      noOfCols === 4 ||
      noOfCols === 5 ||
      noOfCols === 6 ||
      noOfCols === 7
    ) {
      let dummyCellSize = 100 / columns.length + "%";
      setCellSize(dummyCellSize);
    } else {
      let dummyCellSize = 100 / 7 + "%";
      setCellSize(dummyCellSize);
    }
  }, [cellSize]);

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({ Filter: DefaultColumnFilter }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    state,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      manualPagination: true,
      manualFilters: onChangeFilter ? true : false,
      pageCount: Math.ceil(totalCounts / numberOfRows),
      initialState: initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
    useFlexLayout,
    useSticky,
  );

  useEffect(() => {
    if (onChangeFilter) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        onChangeFilter(state.filters);
      }, 500);
    }
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [state.filters]);

  let pagesOptions = [];

  for (let i = 1; i < pageCount + 1; i++) {
    pagesOptions.push(i);
  }

  //   value: pageIndex,
  //   label: "Page " + pageIndex,
  // };

  const currentRows = {
    value: numberOfRows,
    label: numberOfRows + " rows",
  };

  const onPageNumber = (page) => {
    if (page === 0 || page > pagesOptions.length) {
      return;
    }
    setCurrentPage(page);
    onChangePaginationInfo(page, numberOfRows);

    //onChangePageInfo
  };
  return (
    <Styles>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader
            loaded={!loading}
            lines={13}
            length={20}
            width={5}
            radius={30}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />
        </div>
      )}
      {!loading && (
        <div {...getTableProps()} className="table sticky" style={{}}>
          <div className={`${projects ? "projects-header" : "header"}`}>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render("Header")}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="body">
            {page.map((row, key) => {
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr" key={key}>
                  {row.cells.map((cell) => (
                    <div {...cell.getCellProps()} className="td">
                      {cell.render("Cell")}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/*  Pagination   */}
      {pagesOptions.length > 1 && (
        <div className="Paggination">
          <div className="Paggination-bottom">
            <PaginationIcon
              src={leftArrow}
              handleClick={() => onPageNumber(currentPage - 1)}
              disabled={currentPage == 1 ? true : false}
            />
            <div className="paggination-items-wrapper">
              {pagesOptions.map((page, key) => {
                return (
                  <div
                    key={key}
                    className={
                      currentPage === page
                        ? "Paggination-item-selected"
                        : "Paggination-item"
                    }
                    onClick={() => onPageNumber(page)}
                  >
                    <p
                      className={
                        currentPage === page
                          ? "Paggination-item-text-selected"
                          : "Paggination-item-text"
                      }
                      onClick={() => onPageNumber(page)}
                    >
                      {page}
                    </p>
                  </div>
                );
              })}
            </div>
            <PaginationIcon
              src={arrowright}
              handleClick={() => onPageNumber(currentPage + 1)}
              disabled={currentPage == pagesOptions.length ? true : false}
            />
          </div>
        </div>
      )}
    </Styles>
  );
};
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// Determine whether the sentiment of text is positive
// Use a web service

filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
