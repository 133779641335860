import React, { useRef } from "react";
import { Button } from "reactstrap";
import { ReactComponent as EmptyStorage } from "../../../assets/svg/Emptystorage.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFiles } from "@store/asset/assetActions";
import { useEffect } from "react";
import { ReactComponent as ShareSm } from "../../../assets/svg/ShareSm.svg";
import { getProject } from "@store/project/projectActions";
import { AiFillCloseCircle } from "react-icons/ai";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import ReactReadMoreReadLess from "react-read-more-read-less";

const ProjectDetail = ({ id, handleClose }) => {
  const dispatch = useDispatch();
  const { selectedProject, loading } = useSelector((state) => state.projects);
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());

  // useEffect(() => {
  //   if (id) {
  //     dispatch(getProject(id));
  //   }
  // }, [id]);
  return (
    <div ref={ref} style={{ padding: "0rem 1rem", width: "100%" }}>
      <div className="display-flex border-btm">
        <h6 className="container-heading">PROJECT DETAILS</h6>
        <AiFillCloseCircle
          onClick={() => handleClose()}
          size={18}
          style={{ marginTop: 5, cursor: "pointer" }}
        />
      </div>

      <div className="project-details">
        <div style={{ ...styles.outerDivHorizontal }}>
          <h3 style={{ ...styles.itemFieldText, marginRight: "15px" }}>
            Project Name
          </h3>
          <h4 style={{ ...styles.itemValueText }}>{selectedProject?.title}</h4>
        </div>
        <div style={{ ...styles.outerDivHorizontal }}>
          <h3 style={{ ...styles.itemFieldText, marginRight: "15px" }}>
            Email
          </h3>
          <h4 style={{ ...styles.itemValueText }}>
            {selectedProject?.userId?.email}
          </h4>
        </div>
        <div style={{ ...styles.outerDivVertical }}>
          <h3 style={{ ...styles.itemFieldText, marginBottom: "10px" }}>
            Description
          </h3>
          <div style={{ ...styles.itemValueText, width: "100%" }}>
            <ReactReadMoreReadLess
              charLimit={150}
              readMoreText={"See more"}
              readLessText={"See less"}
              readMoreClassName="read-more-less--more"
              readLessClassName="read-more-less--less"
            >
              {selectedProject?.description}
            </ReactReadMoreReadLess>
          </div>
        </div>
        <div style={{ ...styles.outerDivVertical }}>
          <h3 style={{ ...styles.itemFieldText, marginBottom: "10px" }}>
            Google Embed Link
          </h3>
          <div
            style={{
              padding: "5px",
              display: "flex",

              background: "#F5F5F5",
              borderRadius: "5px",
              minHeight: "20px",
              alignItems: "center",
              width: "100%",
            }}
          >
            {/* {selectedProject.clickEmbedUrl && (
            <div> */}
            <ShareSm style={{ margin: "5px" }} />
            <p
              style={{
                fontWeight: "500",
                fontSize: "13px",
                color: "#727272",
                margin: "0",
              }}
            >
              {selectedProject.clickEmbedUrl}
            </p>
            {/* </div>
          )} */}
          </div>
        </div>
        {/* open marketing link in a new tab */}
        <div style={{ display: "flex", justifyContent: "start" }}>
          <a
            href={selectedProject?.geomausMarketingModel}
            style={
              !selectedProject.geomausMarketingModel
                ? {
                  ...styles.disableMarketingModelBtn,
                  ...styles.marketingModelBtn,
                }
                : styles.marketingModelBtn
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            View marketing model
          </a>
        </div>
      </div>
    </div>
  );
};

const styles = {
  itemValueText: {
    padding: "5px",
    background: "#F5F5F5",
    borderRadius: "5px",
    alignItems: "center",
    fontWeight: "500",
    fontSize: "11px",
    color: "#000000;",
    margin: "0",
  },

  outerDivHorizontal: {
    display: "flex",
    marginBottom: "15px",
  },
  outerDivVertical: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    marginBottom: "15px",
  },
  itemFieldText: {
    fontWeight: 500,
    fontSize: "16px",
    textAlign: "center",
    color: " #000000",
    margin: "0px",
  },
  marketingModelBtn: {
    textDecoration: "none",
    background: "#51BCDA",
    padding: "8px 20px",
    borderRadius: "5px",
    color: "white",
    alignSelf: "center",
  },
  disableMarketingModelBtn: {
    pointerEvents: "none",
    opacity: "0.6",
  },
};
export default ProjectDetail;
