import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "@api/axios";
const fetchNotifications = createAsyncThunk(
  "notifications/fetch",
  async ({ rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/notifications?page=1&limit=5`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const markAllNotifications = createAsyncThunk(
  "notifications/markAll",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/notifications/markAll`, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export { fetchNotifications, markAllNotifications };
