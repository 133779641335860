/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect } from "react";

import { Button } from "reactstrap";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import defaultImage from "assets/img/placeholderImage.png";
import profileImage from "assets/img/profilePlaceholder.png";
import { registerables } from "chart.js";

// used for making the prop types of this component

function ImageUpload ({
  // fileState,
  // setFileState,
  label,
  profilepic,
  imageHeight,
  uploadedImage,
  setValue,
  name,
  error,
  errorMessage,
  register,
  control,
  setUserPhoto,
}) {
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    profilepic ? profileImage : defaultImage,
  );
  useEffect(() => {
    if (uploadedImage) {
      const url = uploadedImage;
      setImagePreviewUrl(url);
    }
  }, [uploadedImage]);

  const fileInput = React.useRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      // setFileState(file);
      if (setValue) {
        setValue(name, reader.result);
      }
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // fileState is the file/image uploaded
    // in this function you can save the image (fileState) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setUserPhoto("https://geomaus.s3.amazonaws.com/1671089712853photo.png")
    fileInput.current.value = null;
    // setFileState(null);
    setImagePreviewUrl(profilepic ? profileImage : defaultImage);
  };
  return (
    <div className="fileinput text-center w-full max-h-[7rem]">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <input
            className=".input-group-lg"
            type="file"
            onChange={handleImageChange}
            ref={fileInput}
            accept="image/png, image/gif, image/jpeg"
            register
          />
        )}
      />
      <div>
        <img
          style={{ maxHeight: imageHeight ? imageHeight : "15rem" }}
          // width="100%"
          // height="100%"
          src={imagePreviewUrl}
          alt="..."
        />
      </div>
      <div>
        {uploadedImage === null ||
          uploadedImage === "" ||
          uploadedImage === undefined ? (
          <Button
            className="btn btn-primary btn-block"
            onClick={() => handleClick()}
          >
            {label}
          </Button>
        ) : (
          <span>
            <Button onClick={() => handleClick()}>Change</Button>
            <Button color="danger" onClick={() => handleRemove()}>
              <i className="fa fa-times" />
              Remove
            </Button>
          </span>
        )}
      </div>
      {error && <div className="has-error-true">{errorMessage}</div>}
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
};

export default ImageUpload;
