import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
  getProject,
  getProjectsByCustomer,
  setSelectedProject,
  removeSelectedProject,
  getProjectFiles,
  uploadProjectFile,
  deleteProjectFile,
  removeProjectsByCustomer,
  assignProject,
  assignProjectByCustomer,
  editProjectFile,
} from "./projectActions";
const initialState = {
  projects: [],
  customers: [],
  customerProjects: [],
  selectedProject: null,
  selectedProjectFiles: [],
  totalProjects: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProjects.pending]: (state) => {
      state.loading = true;
    },
    [fetchProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects = payload.result.docs;
      state.totalProjects = payload.result.totalDocs;
      state.numberOfRows = payload.result.limit;
      state.pageIndex = payload.result.page;
    },
    [fetchProjects.rejected]: (state) => {
      state.loading = false;
    },

    [createProject.pending]: (state) => {
      state.loading = true;
    },
    [createProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const project = payload.result;
      const projects = state.projects;
      state.projects = [project, ...projects];
    },
    [createProject.rejected]: (state) => {
      state.loading = false;
    },
    [updateProject.pending]: (state) => {
      state.loading = true;
    },
    [updateProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const project = payload.result;
      const projects = state.projects;
      const filteredProjects = projects.filter(function (obj) {
        return obj._id !== project._id;
      });
      state.projects = [project, ...filteredProjects];
    },
    [updateProject.rejected]: (state) => {
      state.loading = false;
    },
    [assignProject.pending]: (state) => {
      state.loading = true;
    },
    [assignProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [assignProject.rejected]: (state) => {
      state.loading = false;
    },
    [assignProjectByCustomer.pending]: (state) => {
      state.loading = true;
    },
    [assignProjectByCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [assignProjectByCustomer.rejected]: (state) => {
      state.loading = false;
    },
    [deleteProject.pending]: (state) => {
      state.loading = true;
    },
    [deleteProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const deletedId = payload.result;
      const projects = state.projects;
      const filteredProjects = projects.filter(function (obj) {
        return obj._id !== deletedId;
      });
      state.projects = [...filteredProjects];
    },
    [deleteProject.rejected]: (state) => {
      state.loading = false;
    },
    [getProject.pending]: (state) => {
      state.loading = true;
      state.selectedProject = null;
    },
    [getProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedProject = payload.result;
    },
    [getProject.rejected]: (state) => {
      state.loading = false;
    },
    [getProjectsByCustomer.pending]: (state) => {
      state.loading = true;
    },
    [getProjectsByCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerProjects = payload.result;
    },
    [getProjectsByCustomer.rejected]: (state) => {
      state.loading = false;
    },
    [removeProjectsByCustomer.pending]: (state) => {
      state.loading = true;
    },
    [removeProjectsByCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customerProjects = [];
    },
    [removeProjectsByCustomer.rejected]: (state) => {
      state.loading = false;
    },
    [setSelectedProject.pending]: (state) => {
      state.loading = true;
    },
    [setSelectedProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedProject = payload;
    },
    [setSelectedProject.rejected]: (state) => {
      state.loading = false;
    },
    [removeSelectedProject.pending]: (state) => {
      state.loading = true;
    },
    [removeSelectedProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedProject = false;
    },
    [removeSelectedProject.rejected]: (state) => {
      state.loading = false;
      state.selectedProject = false;
    },
    [getProjectFiles.pending]: (state) => {
      state.loading = true;
    },
    [getProjectFiles.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.selectedProjectFiles = payload.result;
    },
    [getProjectFiles.rejected]: (state) => {
      state.loading = false;
    },
    [uploadProjectFile.pending]: (state) => {
      state.loading = true;
    },
    [uploadProjectFile.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const file = payload.result;
      const files = state.selectedProjectFiles;
      state.selectedProjectFiles = [file, ...files];
    },
    [uploadProjectFile.rejected]: (state) => {
      state.loading = false;
    },
    [editProjectFile.pending]: (state) => {
      state.loading = true;
    },
    [editProjectFile.fulfilled]: (state, { payload }) => {
      const file = payload.result;
      const files = state.selectedProjectFiles;
      const filteredFiles = files.filter(function (obj) {
        return obj._id !== file._id;
      });
      state.selectedProjectFiles = [file, ...filteredFiles];
    },
    [editProjectFile.rejected]: (state) => {
      state.loading = false;
    },
    [deleteProjectFile.pending]: (state) => {
      state.loading = true;
    },
    [deleteProjectFile.fulfilled]: (state, { payload }) => {
      const deletedId = payload.result;
      const files = state.selectedProjectFiles;
      const filteredFiles = files.filter(function (obj) {
        return obj._id !== deletedId;
      });
      state.selectedProjectFiles = [...filteredFiles];
    },
    [deleteProjectFile.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// this is for configureStore
export default projectSlice.reducer;
