import { yupResolver } from "@hookform/resolvers/yup";
import { phoneRegExp } from "@validation/regex";
import * as yup from "yup";

const AddUserSchema = yup.object().shape({
  name: yup.string().required("Name should not be empty"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email cannot be empty"),
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone length should be greater then 10")
    .max(20, "Phone length should be less then 20"),
  password: yup.string().when("isEdit", {
    is: (isEdit) => isEdit == false,
    then: yup
      .string()
      .min(5, "password length should be greater then 5")
      .max(20, "password length should be less then 20")
      .required("Password cannot be empty"),
    otherwise: yup.string(),
  }),
  photo: yup.string(),
  twoFactorAuthentication: yup.boolean(),
  passwordConfirmation: yup.string().when("isEdit", {
    is: (isEdit) => isEdit == false,
    then: yup
      .string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  }),
});

const UserSettingSchema = yup.object().shape({
  name: yup.string().required("Name should not be empty"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email cannot be empty"),
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone length should be greater then 10")
    .max(20, "Phone length should be less then 20"),

  photo: yup.string(),
  twoFactorAuthentication: yup.boolean(),
  password: yup
    .string()
    .optional()

    .max(20, "password length should be less then 20"),
  otherwise: yup.string(),

  passwordConfirmation: yup.string().optional().test("passwords-match", "Passwords must match", function (value = '') {
    return (this.parent.password.length === 0 && value.length === 0) || this.parent.password === value

  }),
})

const AddUserValidation = yupResolver(AddUserSchema);
const UserSettingValidation = yupResolver(UserSettingSchema)

export { AddUserValidation, UserSettingValidation };
