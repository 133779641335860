import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {CardBody, Row, Col, Card, Button} from 'reactstrap'
import ReactTooltip from 'react-tooltip'
import LoadingOverlay from 'react-loading-overlay'
import ReactTable from '../../../components/ReactTable/ReactTable'
import editIcon from '../../../assets/img/editicon.svg'
import deleteIcon from '../../../assets/img/deleteicon.svg'
import {MdManageAccounts, MdAssignmentInd} from 'react-icons/md'
import AddUserModal from './AddUserModal'
import {fetchUsers} from '@store/customer/customerActions'
import {createUser} from '@store/customer/customerActions'
import {getCustomerToken} from '@store/login/loginActions'
import {useHistory} from 'react-router-dom'
import AssignProjectModal from './AssignProjectModal'
import {removeProjectsByCustomer} from '@store/project/projectActions'
import {assignProject} from '@store/project/projectActions'
import {updateUser} from '@store/customer/customerActions'
import DeleteModal from 'components/Common/modal/DeleteModal'
import {deleteUser} from '@store/customer/customerActions'
import CsvExport from 'components/Common/CsvExport'

const Users = () => {
  const [data, setData] = useState([])
  const [dataToExport, setDataToExport] = useState([])

  const history = useHistory()
  const [selectedId, setSelectedId] = useState(null)
  const dispatch = useDispatch()
  const {users, totalUsers, pageIndex, numberOfRows, loading} = useSelector(
    state => state.customers
  )
  const handleAccessAccount = id => {
    dispatch(getCustomerToken({id: id}))
    history.push('/user/projects/' + id)
  }

  useEffect(() => {
    dispatch(fetchUsers({rows: numberOfRows, page: pageIndex}))
  }, [pageIndex, numberOfRows])

  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setSelectedId('')
    setShow(false)
  }

  const [assignShow, setAssignShow] = useState(false)
  const handleAssignShow = () => setAssignShow(true)
  const handleAssignClose = () => {
    setSelectedId(null)
    setAssignShow(false)
  }
  const [deleteShow, setDeleteShow] = useState(false)
  const handleDeleteShow = () => setDeleteShow(true)
  const handleDeleteClose = () => {
    setSelectedId(null)
    setDeleteShow(false)
  }

  const handleCreateUser = data => {
    data.role = ['USER']
    delete data.passwordConfirmation
    delete data.isEdit
    data.accessType = data.accessType.value

    if (selectedId) {
      dispatch(updateUser({id: selectedId._id, body: data}))
    } else {
      dispatch(createUser(data))
    }
    handleClose()
  }
  const handleEditUser = id => {
    setSelectedId(id)
    handleShow()
  }
  const handleDeleteClicked = id => {
    setSelectedId(id)
    handleDeleteShow()
  }
  const handleAssignClicked = id => {
    setSelectedId(id)
    handleAssignShow()
    // dispatch(createUser(data));
  }
  const handleAssignProject = data => {
    handleAssignClose()
    const body = data.projects.map(project => {
      return project.value
    })
    dispatch(assignProject({id: selectedId, body: body}))
  }
  const handleDeleteUser = () => {
    handleDeleteClose()
    dispatch(deleteUser(selectedId))
  }
  const onChangePaginationInfo = (page, rows) => {
    const rowsToUpdate = rows === null ? numberOfRows : rows
    const pageToUpdate = page === null ? pageIndex : page
    dispatch(fetchUsers({rows, page}))

    //  dispatch(updatePaginationInfo(pageToUpdate, rowsToUpdate));
  }
  // update the data array
  useEffect(() => {
    let tempData = []
    let dataToExportTemp = []
    if (users?.length > 0) {
      tempData = users.map((user, index) => {
        const item = {
          id: user?.id,
          name: user?.name,
          srNumber: index + 1,
          email: user?.email,
          phone: user?.phone,
          address: user?.address,
          accessType: user?.accessType,
          body: (
            <div className="overflow-auto" style={{maxHeight: '3rem'}}>
              <span
                className="h-25 overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: user?.body,
                }}
              ></span>
            </div>
          ),

          actions: (
            <div
              className="actions-left"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
                marginRight: '14px',
              }}
            >
              <ReactTooltip id="customTooltip" getContent={dataTip => dataTip} />
              <div
                data-for="customTooltip"
                data-tip="Access Account"
                onClick={() => handleAccessAccount(user._id)}
              >
                <MdAssignmentInd className="fa-2x" color="orange" style={{cursor: 'pointer'}} />
              </div>

              <div
                data-for="customTooltip"
                data-tip="Assign Project"
                onClick={() => handleAssignClicked(user._id)}
                // onClick={() => deleteCommunications(communication.id)}
              >
                <MdManageAccounts className="fa-2x" color="#3B8EA8" style={{cursor: 'pointer'}} />
              </div>
              <div data-for="customTooltip" data-tip="Edit" onClick={() => handleEditUser(user)}>
                <img src={editIcon} style={{cursor: 'pointer'}} />
              </div>

              <div
                data-for="customTooltip"
                data-tip="Delete"
                onClick={() => handleDeleteClicked(user._id)}
              >
                <img src={deleteIcon} style={{cursor: 'pointer'}} />
              </div>
            </div>
          ),
        }
        return item
      })
      dataToExportTemp = users.map(user => {
        const item = {
          name: user?.name,
          email: user?.email,
          phone: user?.phone,
          address: user?.address,
          accessType: user?.accessType,
        }
        return item
      })
    }

    setData(tempData)
    setDataToExport(dataToExportTemp)
  }, [users])

  return (
    <div className="content">
      <Row>
        <Col md="12">
          {/* <Card>
            <CardBody style={{ minHeight: "500px" }}> */}
          <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
            <Button variant="primary" onClick={handleShow}>
              Add People
            </Button>
            <CsvExport data={dataToExport} fileName="People Export" />
          </div>
          <ReactTable
            data={data}
            numberOfRows={numberOfRows}
            pageIndex={pageIndex}
            totalCounts={totalUsers}
            onChangePaginationInfo={onChangePaginationInfo}
            loading={loading}
            onChangeFilter={filters => {}}
            columns={[
              {
                Header: 'Sr No.',
                accessor: 'srNumber',
                width: 50,
              },
              {
                Header: 'Name',
                accessor: 'name',
              },
              {
                Header: 'Email',
                accessor: 'email',
              },
              {
                Header: 'Phone',
                accessor: 'phone',
              },
              {
                Header: 'Address',
                accessor: 'address',
              },
              {
                Header: 'Access Type',
                accessor: 'accessType',
              },
              {
                Header: 'Actions',
                accessor: 'actions',
                sortable: false,
                filterable: false,
              },
            ]}
            className="-striped -highlight primary-pagination"
          />
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>
      <AddUserModal
        show={show}
        handleClose={handleClose}
        handleCreateItem={handleCreateUser}
        id={selectedId}
      ></AddUserModal>
      <AssignProjectModal
        show={assignShow}
        handleClose={handleAssignClose}
        handleAssignProject={handleAssignProject}
        id={selectedId}
        type={'USER'}
      />
      <DeleteModal
        show={deleteShow}
        handleClose={handleDeleteClose}
        handleAction={handleDeleteUser}
      />
    </div>
  )
}
export default Users
