import React, {useRef, useState} from 'react'
import {Form, InputGroup, InputGroupAddon, InputGroupText, Input, Button} from 'reactstrap'

import {MatterPortTag} from './MatterPortTag'
import {ReactComponent as EmptyStorage} from '../../../assets/svg/Emptystorage.svg'
import {AiFillCloseCircle} from 'react-icons/ai'
import {useOnClickOutside} from '@hooks/useOnClickOutside'
import {Can} from '@permissions/Can'

const TagLists = ({
  show,
  id,
  handleClose,
  handleAssetClicked,
  handleAddTag,
  assets,
  selectedAsset,
  handleAddComment,
  handleDeleteAsset,
  handleEditAsset,
  handleAssetStatus,
  handleNavigateAsset,
  handleFileUploadAsset,
}) => {
  const [tagsSearch, SetTagsSearch] = useState('')
  const ref = useRef()
  const [showTagDropdown, setShowTagDropdown] = useState(false)
  const data = assets.filter(list => {
    if (tagsSearch === '') {
      //if query is empty
      return list
    } else if (list.title.toLowerCase().includes(tagsSearch.toLowerCase())) {
      //returns filtered array
      return list
    }
  })
  const handlelistClose = () => {
    setShowTagDropdown(false)
  }

  useOnClickOutside(ref, () => handleClose())
  return (
    <div className="tagListComponent" isOpen={show} onHide={handleClose}>
      {assets.length > 0 ? (
        <>
          <div className="display-flex" style={{marginTop: '5%'}}>
            <h3
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '10px',
              }}
            >
              Tags List ({assets.length})
            </h3>
            <AiFillCloseCircle onClick={() => handleClose()} size={18} style={{marginTop: -10}} />
          </div>

          <Form className="search-tags">
            <InputGroup className="no-border">
              <Input
                defaultValue=""
                placeholder="Search..."
                type="text"
                onChange={e => {
                  SetTagsSearch(e.target.value)
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <TagListComponent
            assets={data}
            handleAssetClicked={handleAssetClicked}
            handleClose={handleClose}
            id={id}
            handleAddTag={handleAddTag}
            selectedAsset={selectedAsset}
            show={show}
            handleAddComment={handleAddComment}
            handleDeleteAsset={handleDeleteAsset}
            handleAssetStatus={handleAssetStatus}
            handleEditAsset={handleEditAsset}
            handleNavigateAsset={handleNavigateAsset}
            handleFileUploadAsset={handleFileUploadAsset}
            showTagDropdown={showTagDropdown}
            setShowTagDropdown={setShowTagDropdown}
            handlelistClose={handlelistClose}
          />
        </>
      ) : (
        <div className="empty-tag-list">
          <TagListEmpty />
          <Can I="create" a="Asset">
            <Button className="btn-round" color="info" outline type="button" onClick={handleAddTag}>
              Add Tag
            </Button>
          </Can>
        </div>
      )}
    </div>
  )
}

const TagListComponent = ({
  assets,
  handleAssetClicked,
  show,
  selectedAsset,
  handleClose,
  handleAddComment,
  handleDeleteAsset,
  handleEditAsset,
  handleAssetStatus,
  handleNavigateAsset,
  handleFileUploadAsset,
  showTagDropdown,
  setShowTagDropdown,
  handlelistClose,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        width: '100%',
      }}
    >
      {assets &&
        assets.map((asset, index) => (
          <MatterPortTag
            key={asset._id}
            _id={asset._id}
            number={index + 1}
            color={asset.color}
            title={asset.title}
            status={asset.status}
            onClick={() => handleAssetClicked(asset)}
            selectedAsset={selectedAsset}
            handleAddComment={() => handleAddComment(asset)}
            handleDeleteAsset={() => handleDeleteAsset(asset)}
            handleAssetStatus={() => handleAssetStatus(asset)}
            handleEditAsset={() => handleEditAsset(asset)}
            handleNavigateAsset={() => handleNavigateAsset(asset)}
            handleFileUploadAsset={() => handleFileUploadAsset(asset)}
            showTagDropdown={showTagDropdown}
            setShowTagDropdown={setShowTagDropdown}
            handlelistClose={handlelistClose}
          />
        ))}
    </div>
  )
}
const TagListEmpty = ({}) => {
  return (
    <div>
      <EmptyStorage />
    </div>
  )
}

export default TagLists
