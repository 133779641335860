import React, { useEffect, useRef, useState } from "react";
import {
  completeAsset,
  createAsset,
  createAssetComment,
  deleteAsset,
  fetchAssets,
  updateAsset,
  uploadFile,
} from "@store/asset/assetActions";
import { getProject, uploadProjectFile } from "@store/project/projectActions";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as AddTag } from "../../../assets/svg/AddTag.svg";
import AddTagModal from "./AddTagModal";
import AssetStorage from "./AssetStorage";
import { ReactComponent as AssetTagging } from "../../../assets/svg/AssetTaging.svg";
import { ReactComponent as AttachIconWhite } from "../../../assets/img/attachiconwhite.svg";
import { Can } from "@permissions/Can";
import { ReactComponent as Chat } from "../../../assets/svg/chat.svg";
import DeleteModal from "components/Common/modal/DeleteModal";
import ListItem from "./ListItem";
import LoadingOverlay from "react-loading-overlay";
import ProjectDetail from "./ProjectDetail";
import { ReactComponent as ProjectDetails } from "../../../assets/svg/ProjectDetails.svg";
import ProjectShare from "./ProjectShare";
import ProjectStorage from "./ProjectStorage";
import { ReactComponent as Share } from "../../../assets/svg/Share.svg";
import { ReactComponent as Storage } from "../../../assets/svg/Storage.svg";
import SuccessModal from "components/Common/modal/SuccessModal";
import TagDetail from "./TagDetail";
import { ReactComponent as TagList } from "../../../assets/svg/TagList.svg";
import TagLists from "./TagLists";
import TagModal from "./TagModal";
import UploadModal from "components/Common/modal/UploadModal";
import { removeSelectedProject } from "@store/project/projectActions";
import { setSelectedProject } from "@store/project/projectActions";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

const MatterportEditor = () => {
  const sdkKey = process.env.REACT_APP_MATTERPORT_SDK;
  const [mpSdk, setMpSdk] = useState({});
  const [isConfigured, setIsConfigured] = useState(false);
  const [addingTag, setAddingTag] = useState(false);
  const [movingTag, setMovingTag] = useState(false);
  const [tag, setTag] = useState(null);
  const iframe_ref = useRef(null);
  const container_ref = useRef(null);
  const location = useLocation();
  const subscription = useRef(null);
  const isFirefox = false;
  const [isAssetActionOpen, setIsAssetActionOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [assetListShow, setAssetListShow] = useState(false);
  const [assetDetailShow, setAssetDetailShow] = useState(false);
  const [assetFilesShow, setAssetFilesShow] = useState(false);
  const [assetStatusShow, setAssetStatusShow] = useState(false);
  const [assetStatusRemoveShow, setAssetStatusRemoveShow] = useState(false);

  const [tagModalShow, SetTagModalShow] = useState(false);
  const [fileUploadShow, setFileUploadShow] = useState(false);
  const [assetFileUploadShow, setAssetFileUploadShow] = useState(false);
  const [assetFileState, setAssetFileState] = React.useState(null);
  const [isAssetFiles, setIsAssetFiles] = useState(false);

  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("");
  const [tagPos, setTagPos] = useState({});
  const { id, tagId } = useParams();
  const [projectId, setProjectId] = useState(id);

  const [selectedId, setSelectedId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isAssetFile, setIsAssetFile] = useState(false);
  const [matterPortAssetsState, setMatterPortAssetsState] = useState([]);

  const tagItem = useRef(null);
  const tagPosition = useRef({});
  const tagDataItem = useRef({});
  const matterPortAssets = useRef([]);
  const selectedProjectApiCalled = useRef(false);

  const [itemSelected, setItemSelected] = useState("");

  const dispatch = useDispatch();
  const { assets, totalAssets, pageIndex, numberOfRows, loading } = useSelector(
    (state) => state.assets,
  );
  const { selectedProject } = useSelector((state) => state.projects);

  // check if there is id of asset in params then directly go to the tag
  useEffect(() => {}, []);
  useEffect(() => {
    if (projectId && projectId != undefined) {
      dispatch(fetchAssets({ id: projectId }));
      dispatch(getProject(projectId));
    }
  }, [projectId]);

  useEffect(() => {}, [tagPos]);
  useEffect(() => {
    if (selectedProject && selectedProject._id === projectId) {
      if (selectedProjectApiCalled.current === false) {
        selectedProjectApiCalled.current = true;
        iframe_ref.current.setAttribute(
          "src",
          "https://my.matterport.com/show/?m=" +
            selectedProject.matterPortUrl +
            "&hhl=0&play=1&tiles=1&hl=0",
        );
        iframe_ref.current.addEventListener("load", () =>
          showcaseLoader(iframe_ref),
        );
      }
    }
  }, [selectedProject]);

  useEffect(() => {
    if (mpSdk && Object.keys(mpSdk).length > 0) {
      mpSdk.on(mpSdk.Mattertag.Event.CLICK, function (tagSid) {
        var asset = matterPortAssets.current.find(
          (asset) => asset.sid === tagSid,
        );
        setSelectedAsset(asset);
        handleTagModalShow();
      });
      setTimeout(() => {
        if (tagId) {
          handleAssetListShow();
        }
      }, 3000);
    }
  }, [mpSdk]);
  useEffect(() => {
    var tags = assets.map((asset) => ({
      ...asset,
      tagSid: asset.tagId,
      label: asset.title,
      description: asset.description,
      anchorPosition: asset?.assetLocation?.newPos
        ? asset.assetLocation.newPos
        : { x: 0, y: 0, z: 3 },
      stemVector: asset?.assetLocation?.stemVector
        ? asset.assetLocation.stemVector
        : { x: 0, y: 0, z: 0 },
      color: asset.color,
    }));
    if (mpSdk && Object.keys(mpSdk).length > 0) {
      mpSdk.Mattertag.getData()
        .then((oldTags) => {
          let oldTagSids = oldTags.map((oldTag) => oldTag.sid);
          return mpSdk.Mattertag.remove(oldTagSids);
        })
        .then(() => {
          return mpSdk.Mattertag.add(tags);
        })
        .then((newSids) => {
          tags.forEach((tag, i) => {
            mpSdk.Mattertag.preventAction(newSids[i], {
              opening: true,
            });
            tag.sid = newSids[i];
            tag.index = i;
          });
          matterPortAssets.current = tags;
          setMatterPortAssetsState(tags);
          return tags;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [assets, mpSdk]);

  useEffect(() => {
    if (mpSdk && Object.keys(mpSdk).length > 0) {
      subscription.current = mpSdk.Pointer.intersection.subscribe(
        (intersectionData) => {
          if (tag && !movingTag) {
            if (
              intersectionData.object === "intersectedobject.model" ||
              intersectionData.object === "intersectedobject.sweep"
            ) {
              updateTagPos(intersectionData.position, intersectionData.normal);
            }
          }
        },
      );
    }
    return () => {
      if (tag) {
        window.subscription = subscription;

        subscription?.current?.cancel();
      }
    };
  }, [tag]);
  const handleClose = () => {
    setSelectedId("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handleDeleteShow = () => setDeleteShow(true);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleStatusShow = () => setAssetStatusShow(true);
  const handleStatusClose = () => setAssetStatusShow(false);
  // when tag status is already completed call this state
  const handleStatusRemoveShow = () => setAssetStatusRemoveShow(true);
  const handleStatusRemoveClose = () => setAssetStatusRemoveShow(false);
  const handleAddClicked = () => {
    handleAssetDetailClose();
    setIsEdit(false);
    handleShow();
  };

  const handleTagModalShow = () => {
    SetTagModalShow(true);
  };
  const handleTagModalClose = () => {
    SetTagModalShow(false);
  };

  const handleDeleteClicked = (selectedAsset) => {
    setSelectedAsset(selectedAsset);
    handleDeleteShow();
  };
  const handleEditClicked = (selectedAsset) => {
    setSelectedAsset(selectedAsset);
    setIsEdit(true);
    handleShow();
  };
  const handleStatusClicked = (selectedAsset) => {
    setSelectedAsset(selectedAsset);
    if (selectedAsset.status === "Completed") {
      handleStatusRemoveShow();
    } else {
      handleStatusShow();
    }
  };

  const handleAssetListClose = () => {
    setAssetListShow(false);
  };
  const handleAssetListShow = () => setAssetListShow(true);
  const handleAssetDetailClose = () => {
    setAssetDetailShow(false);
  };
  const handleAssetDetailShow = () => setAssetDetailShow(true);

  const handleAssetFilesClose = () => {
    setAssetFilesShow(false);
  };

  const handleAssetAction = () => {
    setIsAssetActionOpen(!isAssetActionOpen);
  };
  const handleFileUploadShow = () => setFileUploadShow(true);
  const handleFileUploadCancel = () => setFileUploadShow(false);

  const handleAssetFileUploadShow = () => {
    setAssetFileUploadShow(true);
  };
  const handleAssetFileUploadCancel = () => {
    setAssetFileUploadShow(false);
  };
  const handleAssetClicked = (asset) => {
    setSelectedAsset(asset);
    handleAssetDetailShow();
  };
  const handleFileClicked = (file) => {
    // console.log("here....");
  };

  const handleFileUpload = (name, file, extension) => {
    handleFileUploadCancel();

    const form_data = { file };
    // form_data.append('file', file)
    dispatch(
      uploadProjectFile({ id: projectId, name, file: form_data, extension }),
    );
  };
  const handleAssetFileUpload = (name, file, extension) => {
    handleAssetFileUploadCancel();
    const form_data = { file };
    // form_data.append('file', file)
    dispatch(
      uploadFile({ id: selectedAsset._id, name, file: form_data, extension }),
    );
  };

  const handleAddComment = (data) => {
    dispatch(createAssetComment(data));
  };

  const handleAssetStatusCompleted = () => {
    handleStatusClose();
    dispatch(
      completeAsset({ id: selectedAsset._id, body: { status: "Completed" } }),
    );
  };
  const handleAssetStatusInCompleted = () => {
    handleStatusRemoveClose();
    dispatch(
      completeAsset({ id: selectedAsset._id, body: { status: "InCompleted" } }),
    );
  };

  const handleDeleteAsset = () => {
    handleDeleteClose();
    handleAssetDetailClose();
    mpSdk.Mattertag.remove(selectedAsset.tagId)
      .then((value) => {})
      .catch((e) => {
        console.log(e);
      });

    dispatch(deleteAsset(selectedAsset._id));
  };
  const handleNavigateAsset = (selectedAsset) => {
    mpSdk.Mattertag.navigateToTag(
      selectedAsset.sid,
      mpSdk.Mattertag.Transition.FLY,
    );

    handleClose();
    // mpSdk.Mattertag.navigateToTag(
    //   selectedAsset.sid,
    //   mpSdk.Mattertag.Transition.FLY,
    // );

    // handleClose();
    // mpSdk.Mattertag.navigateToTag(
    //   selectedAsset.sid,
    //   mpSdk.Mattertag.Transition.FLY,
    // );

    // handleClose();
  };

  function createTagInDb(tag, pos) {
    tagItem.current = null;
    tagPosition.current = {};

    tagDataItem.current.tagId = tag;
    tagDataItem.current.assetLocation = pos;
    // var form_data = new FormData()
    // for (var key in tagDataItem.current) {
    // if (key === 'assetLocation' || key ==ws.com/1665038339989= 'color' || key === 'users') {
    // form_data.append(key, JSON.stringify(tagDataItem.current[key]))
    // } else {
    // form_data.append(key, tagDataItem.current[key])
    // }
    // }
    //  form_data.append('projectId', projectId)
    const form_data = { projectId, ...tagDataItem.current };
    dispatch(createAsset(form_data));
  }

  function showcaseLoader(iframe) {
    try {
      window.MP_SDK.connect(iframe.current, sdkKey, "3.10")
        .then(loadedShowcaseHandler)
        .catch(console.error);
    } catch (e) {
      console.error(e);
    }
  }
  function placeTag() {
    if (tagItem.current && tagItem.current.length > 0) {
      createTagInDb(tagItem.current, tagPosition.current);
      setTag(null);
      setMovingTag(false);
    }
  }
  useEffect(() => {
    handleAssetDetailClose();
    handleAssetFilesClose();
  }, [itemSelected]);

  useEffect(() => {
    const eventListener = window.addEventListener("blur", function () {
      if (document.activeElement === iframe_ref.current) {
        placeTag();
        setTimeout(function () {
          window.focus();
        }, 0);
      }
    });
    return () => window.removeEventListener("blur", eventListener);
  }, []);
  function updateTagPos(newPos, newNorm = undefined, scale = undefined) {
    if (!newPos) return;
    if (!scale) scale = 0.33;
    if (!newNorm) newNorm = { x: 0, y: 1, z: 0 };
    const stemVector = {
      x: scale * newNorm.x,
      y: scale * newNorm.y,
      z: scale * newNorm.z,
    };

    setTagPos({ stemVector, newPos });
    tagPosition.current = { stemVector, newPos };

    mpSdk.Mattertag.editPosition(tag, {
      anchorPosition: newPos,
      stemVector: stemVector,
    }).catch((e) => {
      console.error(e);
      setTag(null);
      setMovingTag(false);
    });
  }
  function updateTag(matTagId, label, description, color) {
    if (!label && !description) return;

    const props = new Object();
    label ? (props["label"] = label) : {};
    description ? (props["description"] = description) : {};
    color ? (props["color"] = color) : {};

    mpSdk.Mattertag.editBillboard(matTagId, props).catch((e) => {
      console.error(e);
    });
  }

  function changeInfo(ele, tagId) {
    if (ele.tagName === "TH") {
      return;
    }
    const desc = ele.innerText;
    const change = document.createElement("input");
    change.id = tagId;
    change.value = desc;
    ele.replaceWith(change);
    change.focus();
    change.addEventListener("blur", (e) => {
      clickAway(change, tagId);
    });
    change.addEventListener("keydown", (e) => {
      if (e.key == "Enter") {
        change.blur();
      }
    });
  }

  function clickAway(ele, tagId) {
    let desc = ele.value;
    const change = document.createElement("td");
    change.id = tagId;
    change.innerText = ele.value;
    ele.replaceWith(change);
    change.removeEventListener("blur", clickAway);
    const lbl = tagId === "label" ? desc : null;
    desc = tagId === "description" ? desc : null;
    updateTag(change.parentElement.id, (label = lbl), (description = desc));
    change.addEventListener("click", () => {
      changeInfo(change, tagId);
    });
  }
  function loadedShowcaseHandler(mpSdk) {
    setMpSdk(mpSdk);
    // ??
    // setIsConfigured(true);
  }

  const handleCreateTag = (data) => {
    if (isEdit) {
      handleClose();
      setIsEdit(false);
      setSelectedAsset((prevAsset) => ({ ...prevAsset, ...data }));
      //var form_data = new FormData()
      // for (var key in data) {
      // if (key === 'color' || key === 'users') {
      //form_data.append(key, JSON.stringify(data[key]))

      // } else {
      // form_data.append(key, data[key])
      // const form_data = {...data}
      // }
      // }
      const form_data = { ...data };
      dispatch(updateAsset({ id: selectedAsset._id, body: form_data }));
    } else {
      tagDataItem.current = data;

      if (!addingTag && !tag) {
        setAddingTag(true);
        mpSdk.Mattertag.add([
          {
            label: data.title,
            description: data.description,
            anchorPosition: { x: 0, y: 0, z: 3 },
            stemVector: { x: 0, y: 0, z: 0 },
            color: data.color,
          },
        ])
          .then((sid) => {
            setTag(sid[0]);
            tagItem.current = sid[0];
            return mpSdk.Mattertag.getData();
          })
          .then((collection) => {
            const t_sid = collection.find((elem) => elem.sid === tag);
            //add sid to collection or open layout
            // addTagListeners(row);
            setAddingTag(false);
          })
          .then(() => {
            // if (isFirefox) overlayDetect();
          })
          .catch((e) => {
            console.error(e);
            setAddingTag(false);
          });
      }

      handleClose();
    }
  };

  function addTagListeners(block) {
    if (!block || block.children[0].tagName == "TH") {
      return;
    }
    // Label
    block.children[0].addEventListener("click", () => {
      changeInfo(block.children[0], (tagId = "label"));
    });

    // Description
    block.children[1].addEventListener("click", () => {
      changeInfo(block.children[1], (tagId = "description"));
    });

    // arrow icon
    block.children[3].addEventListener("click", () => {
      mpSdk.Mattertag.navigateToTag(
        block.id,
        mpSdk.Mattertag.Transition.FADEOUT,
      ).catch((e) => {
        console.error(e);
      });
      mpSdk.Mattertag.navigateToTag(
        block.id,
        mpSdk.Mattertag.Transition.FADEOUT,
      ).catch((e) => {
        console.error(e);
      });
      mpSdk.Mattertag.navigateToTag(
        block.id,
        mpSdk.Mattertag.Transition.FADEOUT,
      ).catch((e) => {
        console.error(e);
      });
    });

    // delete icon
    block.children[4].addEventListener("click", () => {
      block.parentNode.removeChild(block);
      mpSdk.Mattertag.remove(block.id).catch((e) => {});
    });
  }

  //render components on click
  const renderSwitch = (param) => {
    switch (param) {
      case "list-tag":
        return (
          <TagLists
            handleClose={handleAssetListClose}
            assets={matterPortAssetsState}
            id={selectedId}
            handleAssetClicked={handleAssetClicked}
            handleAddTag={handleAddClicked}
            selectedAsset={selectedAsset}
            show={assetDetailShow}
            handleAddComment={handleAddComment}
            handleDeleteAsset={handleDeleteClicked}
            handleAssetStatus={handleStatusClicked}
            handleEditAsset={handleEditClicked}
            handleNavigateAsset={handleNavigateAsset}
            handleFileUploadAsset={handleAssetFileUploadShow}
          />
        );
      case "project-storage":
        return (
          <ProjectStorage
            handleClose={handleAssetListClose}
            id={projectId}
            handleFileClicked={handleFileClicked}
            handleFileUpload={handleFileUploadShow}
          />
        );

      case "project-share":
        return (
          <ProjectShare handleClose={handleAssetListClose} id={projectId} />
        );
      case "project-detail":
        return (
          <ProjectDetail handleClose={handleAssetListClose} id={projectId} />
        );

      default:
        return (
          <TagLists
            handleClose={handleAssetListClose}
            assets={matterPortAssets.current}
            id={selectedId}
            handleAssetClicked={handleAssetClicked}
            handleAddTag={handleAddClicked}
            selectedAsset={selectedAsset}
            show={assetDetailShow}
            handleAddComment={handleAddComment}
            handleDeleteAsset={handleDeleteClicked}
            handleAssetStatus={handleStatusClicked}
            handleEditAsset={handleEditClicked}
            handleNavigateAsset={handleNavigateAsset}
            handleFileUploadAsset={handleAssetFileUploadShow}
          />
        );
    }
  };

  return (
    <>
      <div className="">
        <div className="showcase_container">
          <div style={styles.container}>
            <iframe
              style={{ width: "calc(100% - 84px)", height: "87.2vh" }}
              ref={iframe_ref}
              className="showcase-iframe"
              frameBorder="0"
              allow="autoplay"
              allowFullScreen
              id="iframe"
            />
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: "0",
                height: "87.3vh",
              }}
            >
              {assetListShow && (
                <div className="action_container">
                  {renderSwitch(itemSelected)}
                  {assetDetailShow && (
                    <>
                      {isAssetFiles ? (
                        <div
                          className="tag-detail"
                          style={{
                            minWidth: "200px",
                            height: "87.3vh",
                            overflow: "scroll",
                          }}
                        >
                          <AssetStorage
                            handleClose={handleAssetDetailClose}
                            id={selectedAsset}
                            handleFileClicked={handleFileClicked}
                            handleFileUpload={handleAssetFileUploadShow}
                          />
                        </div>
                      ) : (
                        <div
                          className="tag-detail"
                          style={{
                            minWidth: "200px",
                            height: "87.3vh",
                            overflow: "scroll",
                          }}
                        >
                          <TagDetail
                            show={assetDetailShow}
                            handleClose={handleAssetDetailClose}
                            selectedAsset={selectedAsset}
                            handleAddComment={handleAddComment}
                            handleDeleteAsset={handleDeleteClicked}
                            handleAssetStatus={handleStatusClicked}
                            handleEditAsset={handleEditClicked}
                            handleNavigateAsset={handleNavigateAsset}
                            handleFileUploadAsset={handleAssetFileUploadShow}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              <div style={styles.iconContainer}>
                <Chat width="50%" style={{ cursor: "pointer" }} />
                <div>
                  <AssetTagging
                    width="100%"
                    onClick={handleAssetAction}
                    style={{ cursor: "pointer" }}
                  />
                  {isAssetActionOpen && (
                    <div className="assetActions">
                      <Can I="create" a="Asset">
                        <AddTag
                          width="50%"
                          className="innerAssetComponent"
                          onClick={handleAddClicked}
                          style={{ cursor: "pointer" }}
                        />
                      </Can>
                      <span style={{ marginTop: "20px" }}></span>
                      <ListItem
                        icon={<TagList width="50%" />}
                        itemSelected={itemSelected === "list-tag"}
                        onClick={() => {
                          setItemSelected("list-tag");
                          setIsAssetFiles(false);
                          handleAssetListShow();
                        }}
                      />
                    </div>
                  )}
                </div>
                <ListItem
                  icon={<Storage width="50%" />}
                  itemSelected={itemSelected === "project-storage"}
                  onClick={() => {
                    setItemSelected("project-storage");
                    handleAssetListShow();
                  }}
                />
                <ListItem
                  icon={<Share width="50%" />}
                  itemSelected={itemSelected === "project-share"}
                  onClick={() => {
                    setItemSelected("project-share");
                    handleAssetListShow();
                  }}
                />
                <ListItem
                  icon={<ProjectDetails width="50%" />}
                  itemSelected={itemSelected === "project-detail"}
                  onClick={() => {
                    setItemSelected("project-detail");
                    handleAssetListShow();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTagModal
        show={show}
        handleClose={handleClose}
        id={isEdit ? selectedAsset : selectedId}
        isEdit={isEdit}
        handleCreateItem={handleCreateTag}
      />
      <DeleteModal
        show={deleteShow}
        handleClose={handleDeleteClose}
        handleAction={handleDeleteAsset}
      />
      <DeleteModal
        show={assetStatusRemoveShow}
        title="Do you want to mark this as InComplete?"
        buttonText="Yes"
        handleClose={handleStatusRemoveClose}
        handleAction={handleAssetStatusInCompleted}
      />
      <SuccessModal
        show={assetStatusShow}
        handleClose={handleStatusClose}
        handleAction={handleAssetStatusCompleted}
      />
      <TagModal
        show={tagModalShow}
        handleClose={handleTagModalClose}
        handleFileClicked={handleFileClicked}
        handleFileUpload={handleAssetFileUploadShow}
        handleComment={handleAddComment}
        selectedAsset={selectedAsset}
        handleEditAsset={handleEditClicked}

        // handleAction={handleAssetStatusCompleted}
      />
      <UploadModal
        show={fileUploadShow}
        handleClose={handleFileUploadCancel}
        handleAction={handleFileUpload}
      />
      <UploadModal
        show={assetFileUploadShow}
        handleClose={handleAssetFileUploadCancel}
        handleAction={handleAssetFileUpload}
      />
    </>
  );
};
const styles = {
  container: { display: "flex", flexDirection: "row" },
  iconContainer: {
    paddingTop: 30,
    paddingBottom: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#000",
    width: "84px",
  },
};

export default MatterportEditor;
