import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react';

function useBroadcast (name) {
  const channel = new BroadcastChannel(name);
  const [channelData, setChannelData] = useState(null);
  channel.onmessage = (event) => {
    setChannelData(event.data);
  };

  const closeChannle = () => {
    channel.close();
  }

  return { channelData, closeChannle };
}

// useBroadcast.propTypes = PropTypes.string.isRequired;

export default useBroadcast


