import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCustomers,
  fetchUsers,
  createCustomer,
  deleteCustomer,
  updateCustomer,
  createUser,
  updateUser,
  deleteUser,
  getUsersByProject
} from "./customerActions";

const initialState = {
  customers: [],
  users: [],
  totalUsers: 0,
  totalCustomer: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
  usersByProject:[]
};

export const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCustomers.pending]: (state) => {
      state.loading = true;
    },
    [fetchCustomers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customers = payload.result.docs;
      state.totalCustomer = payload.result.totalDocs;
      state.numberOfRows = payload.result.limit;
      state.pageIndex = payload.result.page;
    },
    [fetchCustomers.rejected]: (state) => {
      state.loading = false;
    },
    // fetch users by project id
    [getUsersByProject.pending]: (state) => {
      state.loading = true;
    },
    [getUsersByProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.usersByProject = payload.result;
    },
    [getUsersByProject.rejected]: (state) => {
      state.loading = false;
    },
    [fetchUsers.pending]: (state) => {
      state.loading = true;
    },
    [fetchUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.users = payload.result.docs;
      state.totalUsers = payload.result.totalDocs;
      state.numberOfRows = payload.result.limit;
      state.pageIndex = payload.result.page;
    },
    [fetchUsers.rejected]: (state) => {
      state.loading = false;
    },
    [createCustomer.pending]: (state) => {
      state.loading = true;
    },
    [createCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const customer = payload.message.user;
      const customers = state.customers;
      state.customers = [customer, ...customers];
    },
    [createCustomer.rejected]: (state) => {
      state.loading = false;
    },
    [updateCustomer.pending]: (state) => {
      state.loading = true;
    },
    [updateCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const customer = payload.result;
      const customers = state.customers;
      const filteredCustomers = customers.filter(function (obj) {
        return obj._id !== customer._id;
      });
      state.customers = [customer, ...filteredCustomers];
    },
    [updateCustomer.rejected]: (state) => {
      state.loading = false;
    },

    [deleteCustomer.pending]: (state) => {
      state.loading = true;
    },
    [deleteCustomer.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const deletedId = payload.result;
      const customers = state.customers;
      const filteredCustomers = customers.filter(function (obj) {
        return obj._id !== deletedId;
      });
      state.customers = [...filteredCustomers];
    },
    [deleteCustomer.rejected]: (state) => {
      state.loading = false;
    },
    [createUser.pending]: (state) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const user = payload.message.user;
      const users = state.users;
      state.users = [user, ...users];
    },
    [createUser.rejected]: (state) => {
      state.loading = false;
    },
    [updateUser.pending]: (state) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const user = payload.result;
      const users = state.users;
      const filteredUsers = users.filter(function (obj) {
        return obj._id !== user._id;
      });
      state.users = [user, ...filteredUsers];
    },
    [updateUser.rejected]: (state) => {
      state.loading = false;
    },
    [deleteUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const deletedId = payload.result;
      const users = state.users;
      const filteredUsers = users.filter(function (obj) {
        return obj._id !== deletedId;
      });
      state.users = [...filteredUsers];
    },
    [deleteUser.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// this is for configureStore
export default customerSlice.reducer;
