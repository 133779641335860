import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Row, Col, Card, Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import ReactTable from "../../../components/ReactTable/ReactTable";
import ReactTableSearch from "components/ReactTable/ReactTableSearch";
import editIcon from "../../../assets/img/editicon.svg";
import deleteIcon from "../../../assets/img/deleteicon.svg";
import { MdManageAccounts, MdAssignmentInd } from "react-icons/md";
import AddCustomerModal from "./AddCustomerModal";
import {
  fetchCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
} from "@store/customer/customerActions";
import DeleteModal from "components/Common/modal/DeleteModal";
import { ToastContainer } from "react-toastify";
import { getCustomerToken } from "@store/login/loginActions";
import ReactReadMoreReadLess from "react-read-more-read-less";
import {
  assignProject,
  assignProjectByCustomer,
  fetchProjects,
} from "../../../@store/project/projectActions";
import { fetchFeatures } from "../../../@store/feature/featureActions";
import AssignProjectModal from "../users/AssignProjectModal";
import CsvExport from "components/Common/CsvExport";

const Customers = () => {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { customers, totalCustomers, pageIndex, numberOfRows, loading } =
    useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(fetchCustomers({ page: pageIndex, rows: numberOfRows, query }));
  }, [pageIndex, numberOfRows, query]);

  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [assignShow, setAssignShow] = useState(false);
  const handleAssignShow = () => setAssignShow(true);
  const handleAssignClose = () => {
    setSelectedId(null);
    setAssignShow(false);
  };

  const handleClose = () => {
    setSelectedId("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handleDeleteClose = () => {
    setDeleteShow(false);
  };
  const handleDeleteShow = () => setDeleteShow(true);
  const handleCreateCustomer = (data) => {
    data.role = ["CUSTOMER"];
    delete data.passwordConfirmation;
    delete data.isEdit;
    if (selectedId) {
      dispatch(updateCustomer({ id: selectedId._id, body: data }));
    } else {
      dispatch(createCustomer(data));
    }
    handleClose();
  };
  const handleAccessAccount = (id) => {
    dispatch(getCustomerToken({ id: id }));

    history.push("/customer/dashboard/" + id);
  };
  const handleAssignClicked = (id) => {
    setSelectedId(id);
    handleAssignShow();
    // dispatch(createUser(data));
  };
  const handleAssignProject = (data) => {
    handleAssignClose();
    const body = data.projects.map((project) => {
      return project.value;
    });
    dispatch(assignProjectByCustomer({ id: selectedId, body: body }));
  };
  const handleDeleteCustomer = () => {
    handleDeleteClose();
    dispatch(deleteCustomer(selectedId));
  };
  const handleEditCustomer = (id) => {
    setSelectedId(id);
    handleShow();
  };
  const handleDeleteClicked = (id) => {
    setSelectedId(id);
    handleDeleteShow();
  };
  const handleSearchClicked = () => {
    dispatch(fetchCustomers({ page: pageIndex, rows: numberOfRows, query }));
  };

  const onChangePaginationInfo = (page, rows) => {
    const rowsToUpdate = rows === null ? numberOfRows : rows;
    const pageToUpdate = page === null ? pageIndex : page;
    dispatch(fetchCustomers({ page, rows, query }));
  };

  // update the data array
  useEffect(() => {
    let tempData = [];
    let dataToExportTemp = [];
    if (customers?.length > 0) {
      tempData = customers.map((customer, index) => {
        const item = {
          id: customer?.id,
          srNumber: index + 1,
          name: customer?.name,
          email: customer?.email,
          phone: customer?.phone,
          address: customer?.address,
          body: (
            <div className="overflow-auto" style={{ maxHeight: "3rem" }}>
              <span
                className="h-25 overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: customer?.body,
                }}
              ></span>
            </div>
          ),
          description: (
            <div>
              <ReactReadMoreReadLess
                charLimit={150}
                readMoreText={"See more"}
                readLessText={"See less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                {customer?.description}
              </ReactReadMoreReadLess>
            </div>
          ),
          address: (
            <div>
              <ReactReadMoreReadLess
                charLimit={150}
                readMoreText={"See more"}
                readLessText={"See less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                {customer?.address}
              </ReactReadMoreReadLess>
            </div>
          ),
          actions: (
            <div
              className="actions-left"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
                marginRight: "14px",
              }}
            >
              <ReactTooltip
                id="customTooltip"
                getContent={(dataTip) => dataTip}
              />

              <div
                data-for="customTooltip"
                data-tip="Access Account"
                onClick={() => handleAccessAccount(customer._id)}
              >
                <MdAssignmentInd
                  className="fa-2x"
                  color="orange"
                  style={{ cursor: "pointer" }}
                />
              </div>

              <div
                data-for="customTooltip"
                data-tip="Assign Project"
                onClick={() => handleAssignClicked(customer._id)}

                // onClick={() => deleteCommunications(communication.id)}
              >
                <MdManageAccounts
                  className="fa-2x"
                  color="#3B8EA8"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                data-for="customTooltip"
                data-tip="Edit"
                onClick={() => handleEditCustomer(customer)}
              >
                <img src={editIcon} style={{ cursor: "pointer" }} />
              </div>

              <div
                data-for="customTooltip"
                data-tip="Delete"
                onClick={() => handleDeleteClicked(customer._id)}
              >
                <img src={deleteIcon} style={{ cursor: "pointer" }} />
              </div>
            </div>
          ),
        };

        return item;
      });
      dataToExportTemp = customers.map((customer) => {
        const item = {
          name: customer?.name,
          email: customer?.email,
          phone: customer?.phone,
          address: customer?.address,
        };
        // Compile a csv row
        // dataToExportTemp.push(obj);
        return item;
      });
    }
    setData(tempData);
    setDataToExport(dataToExportTemp);
  }, [customers]);

  return (
    <div className="content">
      <div className="TableTop">
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Button variant="primary" onClick={handleShow}>
              Add Customer
            </Button>
            {<CsvExport data={dataToExport} fileName="Customers Export" />}
          </div>
          <AddCustomerModal
            show={show}
            handleClose={handleClose}
            handleCreateItem={handleCreateCustomer}
            id={selectedId}
          ></AddCustomerModal>
        </div>
        <div>
          <ReactTableSearch
            numberOfRows={numberOfRows}
            onChange={setQuery}
            onSearchClicked={handleSearchClicked}
            onChangePaginationInfo={onChangePaginationInfo}
          />
        </div>
      </div>
      <Row>
        <Col md="12">
          <ReactTable
            data={data}
            numberOfRows={numberOfRows}
            pageIndex={pageIndex}
            totalCounts={totalCustomers}
            onChangePaginationInfo={onChangePaginationInfo}
            loading={loading}
            onChangeFilter={(filters) => {}}
            columns={[
              {
                Header: "Sr No.",
                accessor: "srNumber",
                width: 50,
              },
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Email",
                accessor: "email",
              },
              {
                Header: "Phone",
                accessor: "phone",
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Address",
                accessor: "address",
              },
              {
                Header: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    Actions
                  </div>
                ),
                accessor: "actions",
                sortable: false,
                filterable: false,
                minWidth: 100,
                sticky: "right",
              },
            ]}
            className="-striped -highlight primary-pagination"
          />
          {/* </CardBody>
          </Card> */}
        </Col>
      </Row>

      <DeleteModal
        show={deleteShow}
        handleClose={handleDeleteClose}
        handleAction={handleDeleteCustomer}
      />
      <AssignProjectModal
        show={assignShow}
        handleClose={handleAssignClose}
        handleAssignProject={handleAssignProject}
        id={selectedId}
        type={"CUSTOMER"}
      />
      <ToastContainer />
    </div>
  );
};
export default Customers;
