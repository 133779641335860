import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm } from "react-hook-form";
import { ReactFormInput as Input } from "../../../../components/Common/Input";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { get_url_extension } from "@utils";
import { get_url_name } from "@utils";

const EditFileNameModal = ({ file, show, handleClose, handleAction }) => {
  const onCancel = (e) => {
    handleClose();
  };
  const fileExtension = file && get_url_extension(file?.file);

  // const fileName = file && get_url_name(file?.file);
  const fileName = file?.name;
  const submitEditModal = (data) => {
    //console.log(data, "data");
    handleAction(data.name);
  };

  return (
    <Modal
      isOpen={show}
      className="modal-lg modal-responsiveness"
      onHide={handleClose}
    >
      <ModalHeader closeButton>{"Rename File"}</ModalHeader>
      <ModalBody>
        <EditFileForm
          fileName={fileName}
          onSubmit={submitEditModal}
          onCancel={onCancel}
        />
      </ModalBody>
    </Modal>
  );
};

const EditFileForm = ({ fileName, onSubmit, onCancel }) => {
  const defaultValues = {
    name: fileName,
  };

  const { control, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    // resolver: AddCustomerValidation,
  });

  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());

  return (
    <div ref={ref} className="space-y-10 w-full">
      {/* <Loader active={isLoading}> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 w-full md:w-96">
          <Input
            reactForm
            label={"File Name"}
            name="name"
            control={control}
            type="text"
            placeholder={"File Name"}
            error={formState?.errors?.name}
            errorMessage={formState?.errors?.name?.message}
          />

          <div className="text-center">
            <Button className="btn-wd" type="submit" color="primary">
              Save Changes
            </Button>
            <Button
              className="btn-wd"
              type="button"
              color="secondary"
              onClick={onCancel}
            >
              cancel
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditFileNameModal;
