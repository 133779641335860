import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardBody, Row, Col, Card, Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import ReactTable from "../../../components/ReactTable/ReactTable";
import ReactTableSearch from "components/ReactTable/ReactTableSearch";
import { fetchFeatures } from "@store/feature/featureActions";
import AddFeatureModal from "./AddFeatureModal";
import { createFeature } from "@store/feature/featureActions";
import { deleteFeature } from "@store/feature/featureActions";
import { dateConversion, dateConversionSmall } from "@utils";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loader";
import DeleteModal from "components/Common/modal/DeleteModal";
import { AiFillDelete } from "react-icons/ai";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { fetchProjects } from "../../../@store/project/projectActions";
import CsvExport from "components/Common/CsvExport";

const Features = () => {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  const [dataToExport, setDataToExport] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [alert, setAlert] = React.useState(null);
  const dispatch = useDispatch();
  const { features, totalFeatures, pageIndex, numberOfRows, loading } =
    useSelector((state) => state.features);

  useEffect(() => {
    dispatch(fetchFeatures({ page: pageIndex, rows: numberOfRows, query }));
  }, [pageIndex, numberOfRows, query]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [deleteShow, setDeleteShow] = useState(false);

  const handleDeleteClose = () => {
    setDeleteShow(false);
  };

  const handleShowMore = (id) => {
    setSelectedId(id);
  };
  const handleDeleteShow = () => {
    setDeleteShow(true);
  };
  const handleCreateFeature = (data) => {
    handleClose();
    dispatch(createFeature(data));
  };
  const handleDeleteFeature = () => {
    handleDeleteClose();
    dispatch(deleteFeature(selectedId));
  };
  const handleDeleteClicked = (id) => {
    setSelectedId(id);
    handleDeleteShow();
  };
  const handleSearchClicked = () => {
    dispatch(fetchFeatures({ rows: numberOfRows, page: pageIndex, query }));
  };

  const onChangePaginationInfo = (page, rows) => {
    const rowsToUpdate = rows === null ? numberOfRows : rows;
    const pageToUpdate = page === null ? pageIndex : page;

    dispatch(fetchFeatures({ page, rows, query }));
  };
  // update the data array
  useEffect(() => {
    let tempData = [];
    let dataToExportTemp = [];
    if (features?.length > 0) {
      tempData = features.map((feature, index) => {
        const item = {
          id: feature?._id,
          srNumber: index + 1,
          name: feature?.userId?.name,
          email: feature?.userId?.email,
          title: feature?.title,
          details: feature?.details,
          date: dateConversion(feature?.createdAt),

          body: (
            <div className="overflow-auto" style={{ maxHeight: "3rem" }}>
              <span
                className="h-25 overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: feature?.body,
                }}
              ></span>
            </div>
          ),

          details: (
            <div>
              <ReactReadMoreReadLess
                charLimit={150}
                readMoreText={"See more"}
                readLessText={"See less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                {feature?.details}
              </ReactReadMoreReadLess>
            </div>
          ),

          actions: (
            <div
              className="actions-left"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactTooltip
                id="customTooltip"
                getContent={(dataTip) => dataTip}
              />
              <Button
                data-for="customTooltip"
                data-tip="Delete"
                className=" btn btn-danger btn-sm"
                style={{ background: "#DC3545" }}
                onClick={() => handleDeleteClicked(feature._id)}
              >
                Delete
              </Button>
              {/* <ReactTooltip
                id="customTooltip"
                getContent={(dataTip) => dataTip}
              />

              <Button
                data-for="customTooltip"
                data-tip="Delete"
                onClick={() => handleDeleteClicked(feature?._id)}
                className="btn btn-danger"
              >
                Delete
              </Button> */}
            </div>
          ),
        };
        return item;
      });
      dataToExportTemp = features.map((feature) => {
        const item = {
          name: feature?.userId?.name,
          email: feature?.userId?.email,

          title: feature?.title,
          details: feature?.details,
          date: dateConversion(feature?.createdAt),
        };
        return item;
      });
    }
    setData(tempData);
    setDataToExport(dataToExportTemp);
  }, [features]);

  return (
    <>
      <div className="content">
        <div className="TableTop">
          <div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
            >
              <Button variant="primary" onClick={handleShow}>
                Add Feature Request
              </Button>
              <CsvExport data={dataToExport} fileName="Features Export" />
            </div>
            <AddFeatureModal
              show={show}
              handleClose={handleClose}
              handleCreateFeature={handleCreateFeature}
              id={selectedId}
            ></AddFeatureModal>
          </div>

          <div>
            <ReactTableSearch
              numberOfRows={numberOfRows}
              onChange={setQuery}
              onSearchClicked={handleSearchClicked}
              onChangePaginationInfo={onChangePaginationInfo}
            />
          </div>
        </div>
        <Row>
          <Col md="12">
            <ReactTable
              data={data}
              numberOfRows={numberOfRows}
              pageIndex={pageIndex}
              totalCounts={totalFeatures}
              onChangePaginationInfo={onChangePaginationInfo}
              loading={loading}
              onChangeFilter={(filters) => {}}
              columns={[
                {
                  Header: "Sr No.",
                  accessor: "srNumber",
                  width: 50,
                },
                {
                  Header: "User Name",
                  accessor: "name",
                  minWidth: 100,
                },
                {
                  Header: "Email",
                  accessor: "email",
                  minWidth: 100,
                },
                {
                  Header: "Title",
                  accessor: "title",
                  minWidth: 100,
                },
                {
                  Header: "Details",
                  accessor: "details",
                  minWidth: 290,
                },
                {
                  Header: "Date and time",
                  accessor: "date",
                  minWidth: 300,
                },
                {
                  Header: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Actions
                    </div>
                  ),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 50,
                  sticky: "right",
                },
              ]}
              className="-striped -highlight primary-pagination"
            />
          </Col>
        </Row>

        <DeleteModal
          show={deleteShow}
          handleClose={handleDeleteClose}
          handleAction={handleDeleteFeature}
        />
      </div>
    </>
  );
};
export default Features;
