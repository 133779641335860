import React, { useState } from "react";
import { useEffect } from "react";
import defaultImage from 'assets/img/profilePlaceholder.png'
import { useSelector } from "react-redux";
const parentImage = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  padding: '10px'
}
const imageWrapper = (url) =>( {
  width: '150px',
  height: '150px',
  borderRadius: '100%',
  overflow: 'hidden',
  background: `url(${url})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});
const ProfileImage = () => {
  const { user } = useSelector((state) => state.login)
  const [userData, setUserData] = useState(user)
  useEffect(() => { setUserData(user) }, [user])
  return (
    <div style={parentImage}>
      <div style={imageWrapper(userData.photo ? userData.photo : defaultImage)}>
      </div>
    </div>
  )
}
export default ProfileImage;