/*!
=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================
* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useOnClickOutside } from '@hooks/useOnClickOutside'

import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from 'reactstrap'
import { logoutUser } from '@store/login/loginActions'
import DeleteModal from 'components/Common/modal/DeleteModal'
import SettingsModal from 'views/customer/users/SettingsModal'
import { removeCustomerToken } from '@store/login/loginActions'
import { updateCustomer } from '@store/customer/customerActions'
import InfiniteScroll from 'react-infinite-scroll-component'
import axios from 'axios'
import moment from 'moment'
import { styles } from 'views/admin/styles'
import NotificationItem from './NotificationItem'
import useSocket from '@hooks/useSocket'
import 'react-toastify/dist/ReactToastify.css'
import { updateLoginUser } from '@store/login/loginActions'
// Variable
const url = process.env.REACT_APP_HOSTURL
function AdminNavbar(props) {
  // State
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [color, setColor] = useState('navbar-transparent')
  const [openNotificationsList, setOpenNotificationsList] = useState(false)
  const [show, setShow] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [user, setUser] = useState({})
  const location = useLocation()
  const [userNotifications, setUserNotifications] = useState([])
  const [notificationCount, setNotificationCount] = useState(0)
  const [notiPagination] = useState({
    page: 1,
    size: 10,
  })
  // Hooks
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef()
  const { isCustomer, loading, user: loggedInUser, role } = useSelector(
    (state) => state.login,
  )
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  //
  const { isConnected, lastNotification } = useSocket()
  // Effects
  //
  useEffect(() => {
    /**
     *
     * @description function that adds color white/transparent to the navbar on resize (this is for the collapse)
     *
     */
    const updateColor = () => {
      if (window.innerWidth < 993 && collapseOpen) {
        setColor('bg-white')
      } else {
        setColor('navbar-transparent')
      }
    }
    //
    window.addEventListener('resize', updateColor)
    //
    return function cleanUp() {
      window.removeEventListener('resize', updateColor)
    }
  }, [])
  useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open')
    }
  }, [location])
  useEffect(() => {
    if (lastNotification && isConnected) {
      const updatedNotifications = [
        ...userNotifications,
        lastNotification,
      ].sort((left, right) => {
        return moment.utc(right.createdAt).diff(moment.utc(left.createdAt))
      })
      setUserNotifications(updatedNotifications)
    }
  }, [lastNotification])
  //
  useEffect(() => {
    fetchNotifications()
  }, [lastNotification])

  useEffect(() => {
    const notiCount = userNotifications.filter(({ read }) => !read).length
    setNotificationCount(notiCount)
  }, [userNotifications])
  // Methods
  const handleClose = () => setShow(false)
  //
  const handleShow = () => setShow(true)
  console.log('isCustomer', isCustomer)

  // setting modal
  const handleSettingsClose = () => setShowSettingsModal(false)
  const handleSettingsShow = () => setShowSettingsModal(true)

  const handleUpdateCustomer = async (data) => {
    console.log('➡️ 🤷‍♂️ file: AdminNavbar.js:141 🤷‍♂️ data', data)
    delete data.passwordConfirmation
    delete data.isEdit
    if (data.password.length === 0) {
      delete data.password
    }
    const res = await dispatch(updateLoginUser({ id: data.id, body: data }))
    handleSettingsClose()
  }

  const logoutAction = async () => {
    handleClose()
    await dispatch(logoutUser({ history }))
    history.push('/Login')
  }
  //
  const returnToAdmin = () => {
    dispatch(removeCustomerToken())
    history.push('/admin/dashboard')
  }
  const returnToDashboard = () => {
    dispatch(removeCustomerToken())
    history.push('/customer/dashboard')
  }
  //
  const fetchNotifications = async () => {
    if (role == 'ADMIN') {
      try {
        const notifications = await axios.get(
          `${url}api/user/adminnotifications/${loggedInUser._id}`,
        )
        setUserNotifications(notifications.data.result.userNotifications)
      } catch (error) {
        console.log(error.message)
      }
    }
    if (role == 'CUSTOMER') {
      try {
        const notifications = await axios.get(
          `${url}api/user/notifications/${loggedInUser._id}`,
        )
        setUserNotifications(notifications.data.result.userNotifications)
      } catch (error) {
        console.log(error.message)
      }
    }
    if (role == 'USER') {
      try {
        const notifications = await axios.get(
          `${url}api/user/peoplenotification/${loggedInUser._id}`,
        )
        setUserNotifications(notifications.data.result.userNotifications)
      } catch (error) {
        console.log(error.message)
      }
    }
  }

  /**
   * @description this function opens and closes the sidebar on small devices
   */
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open')
    setSidebarOpen(!sidebarOpen)
  }
  /**
   * @description this function opens and closes the collapse on small devices
   * it also adds navbar-transparent class to the navbar when closed
   * and bg-white when opened
   *
   */
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor('bg-white')
    } else {
      setColor('navbar-transparent')
    }
    setCollapseOpen(!collapseOpen)
  }
  useOnClickOutside(ref, () => setOpenNotificationsList(false))
  return (
    <>
      <Navbar
        className={classnames('navbar-absolute fixed-top', color)}
        style={{ position: 'unset' }}
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-minimize">
              <Button
                className="btn-icon btn-round"
                color="default"
                id="minimizeSidebar"
                onClick={props.handleMiniClick}
              >
                <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
              </Button>
            </div>
            <div
              className={classnames('navbar-toggle', {
                toggled: sidebarOpen,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand></NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse
            className="justify-content-end"
            navbar
            isOpen={collapseOpen}
          >
            {/* <Form>
              <InputGroup className="no-border">
                <Input defaultValue="" placeholder="Search..." type="text" />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="nc-icon nc-zoom-split" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Form>
            <Nav navbar>
              <NavItem>
                <NavLink
                  className="btn-magnify"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="nc-icon nc-layout-11" />
                  <p>
                    <span className="d-lg-none d-md-block">Stats</span>
                  </p>
                </NavLink>
              </NavItem> */}
            <Nav navbar>
              {isCustomer && (
                <div>
                  {loggedInUser && loggedInUser?.role[0] === 'ADMIN' && (
                    <Button
                      type="button"
                      color="primary"
                      onClick={returnToAdmin}
                      className="nav-return-btn"
                    >
                      Return to Admin Account
                    </Button>
                  )}

                  {loggedInUser && loggedInUser?.role[0] === 'CUSTOMER' && (
                    <Button
                      type="button"
                      color="primary"
                      onClick={returnToDashboard}
                      className="nav-return-btn"
                    >
                      Return to Dashboard
                    </Button>
                  )}
                </div>
              )}
              {/* <UncontrolledDropdown
                className="btn-rotate"
                nav
                style={{ position: "relative" }}
              >
                <DropdownToggle
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                >
                  <i className="nc-icon nc-bell-55" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu
                  persist
                  aria-labelledby="navbarDropdownMenuLink"
                  right
                >
                  {userNotifications.map((noti, _) => (
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p>{truncate(noti.title)}</p>
                        <span>{moment(noti.createdAt).fromNow()}</span>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <div
                ref={ref}
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={() => setOpenNotificationsList(!openNotificationsList)}
              >
                {notificationCount != 0 ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: '7px',
                      background: 'orange',
                      borderRadius: '100px',
                      right: '8px',
                      padding: '0.1rem',
                      zIndex: '99',
                      height: '1rem',
                      width: '1rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {notificationCount && notificationCount != 0 ? (
                      <p
                        style={{
                          fontSize: '0.5rem',
                          color: 'white',
                          fontWeight: '800',
                        }}
                      >
                        {notificationCount ? notificationCount : ''}
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
                <NavItem style={styles.notiIcon}>
                  <div className="mainNotiParent">
                    <i
                      className="nc-icon nc-bell-55"
                      style={{ marginTop: '3px' }}
                    />
                    {openNotificationsList && (
                      <div
                        className="notificationContainer"
                        style={styles.notiCont}
                      >
                        <div style={styles.notificationHeading}>
                          {' '}
                          Notifications
                        </div>
                        <NotificationItem
                          notifications={userNotifications}
                          setNotifications={setUserNotifications}
                          fetchNotifications={fetchNotifications}
                        />
                      </div>
                    )}
                  </div>
                </NavItem>
              </div>

              <NavItem>
                <div>
                  <UncontrolledDropdown className="btn-rotate" nav>
                    <DropdownToggle
                      aria-haspopup={true}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      id="navbarDropdownMenuLink"
                      nav
                    >
                      <i className="nc-icon nc-settings-gear-65" />
                      <p>
                        <span className="d-lg-none d-md-block">Account</span>
                      </p>
                    </DropdownToggle>
                    <DropdownMenu
                      persist
                      aria-labelledby="navbarDropdownMenuLink"
                      right
                    >
                      <DropdownItem onClick={handleShow}>Log Out</DropdownItem>
                      <DropdownItem onClick={handleSettingsShow}>
                        Settings
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <DeleteModal
                    title="Are you sure you want to log out?"
                    handleAction={logoutAction}
                    show={show}
                    handleClose={handleClose}
                    buttonText="Log Out"
                  />
                  <SettingsModal
                    title="Settings Modal"
                    handleClose={handleSettingsClose}
                    show={showSettingsModal}
                    handleCreateItem={handleUpdateCustomer}
                  />
                </div>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default AdminNavbar
