export const CommentsIcons = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 39 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.2278 3.16213H28.0094C27.9056 1.79919 26.7642 0.721771 25.375 0.721771H2.64316C1.1857 0.721771 0 1.90756 0 3.36493V15.0942C0 16.5516 1.1857 17.7374 2.64316 17.7374H4.63932V22.3934C4.63932 22.7153 4.82707 23.0076 5.11976 23.1414C5.22922 23.1915 5.34603 23.216 5.46183 23.216C5.65575 23.216 5.8474 23.1476 5.99991 23.0157L9.53825 19.9584C9.41701 19.5831 9.35077 19.1835 9.35077 18.7685V7.03928C9.35068 4.90149 11.0899 3.16213 13.2278 3.16213Z"
      fill={props.fill}
    />
    <path
      d="M36.1792 4.50586H13.4474C11.99 4.50586 10.8042 5.69156 10.8042 7.14902V18.8783C10.8042 20.3358 11.99 21.5215 13.4474 21.5215H26.7138L32.8225 26.7998C32.975 26.9315 33.1667 27 33.3606 27C33.4762 27 33.5929 26.9754 33.7025 26.9254C33.9952 26.7915 34.183 26.4994 34.183 26.1774V21.5216H36.1791C37.6365 21.5216 38.8222 20.3359 38.8222 18.8784V7.14911C38.8223 5.69165 37.6366 4.50586 36.1792 4.50586ZM18.2322 14.9582C17.0737 14.9582 16.1345 14.019 16.1345 12.8605C16.1345 11.702 17.0737 10.7628 18.2322 10.7628C19.3907 10.7628 20.3299 11.7021 20.3299 12.8605C20.3299 14.0191 19.3907 14.9582 18.2322 14.9582ZM24.8133 14.9582C23.6548 14.9582 22.7157 14.019 22.7157 12.8605C22.7157 11.702 23.6549 10.7628 24.8133 10.7628C25.9718 10.7628 26.9109 11.7021 26.9109 12.8605C26.9111 14.0191 25.9718 14.9582 24.8133 14.9582ZM31.3944 14.9582C30.2359 14.9582 29.2967 14.019 29.2967 12.8605C29.2967 11.702 30.2359 10.7628 31.3944 10.7628C32.5529 10.7628 33.4919 11.7021 33.4919 12.8605C33.492 14.0191 32.5528 14.9582 31.3944 14.9582Z"
      fill={props.fill}
    />
  </svg>
);
