import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


const AddTutorialSchema = yup.object().shape({
  title: yup.string().required("Title should not be empty"),
  video: yup.mixed().required("Logo Photo is required"),
  thumbnail: yup.mixed()
  // logoPhoto: yup.mixed().required("Logo Photo is required"),
});


const AddTutorialValidation = yupResolver(AddTutorialSchema)

export { AddTutorialValidation };
