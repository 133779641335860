const getBackgroundColor = (status) => {
  switch (status) {
    case "Completed":
      return "#00C851";
    case "In-Progress":
      return "#FFBB33";
    case "Failed":
      return "#DC3545";
    case "undefined":
      return "darkgrey";
  }
};
const ProjectStatus = ({ status }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: '10px', color: 'white' }}>
      <p
        style={{ backgroundColor: getBackgroundColor(status), padding: '4px 7px' }}
        className="Project-status"
      >
        {status}
      </p>
    </div >
  );
};
export { ProjectStatus };
