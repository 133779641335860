import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddUpdateSchema = yup.object().shape({
  title: yup.string().required("Title should not be empty"),
});

const AddUpdateValidation = yupResolver(AddUpdateSchema);

export { AddUpdateValidation };
