import axios from "axios";

const REACT_APP_API_BASE_URL = process.env.REACT_APP_HOSTURL + "api/";

export const axiosInstance = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
});

const ResponseInterceptor = (response) => {
  return response;
};

const RequestInterceptor = (config) => {
  config.headers.common["Accept-Language"] = "en";
  let access_token = localStorage.getItem("customer_access_token")
    ? localStorage.getItem("customer_access_token")
    : localStorage.getItem("access_token");

  config.headers.Authorization = "Bearer " + access_token;
  return config;
};

axiosInstance.interceptors.request.use(RequestInterceptor);

axiosInstance.interceptors.response.use(ResponseInterceptor, (error) => {
  const expectedErrors =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;
  if (!expectedErrors) {
    return;
  } else {
    if (error.response.status === 401) {
      if (localStorage.getItem("access_token")) {
        store.dispatch(logoutUser());
        //     await dispatch(logoutUser({ history }));
        // history.push("/Login");
        return;
      }
    }
    if (error.response.data.errors) {
      const errors = error?.response?.data?.errors;
      const fields = Object.keys(errors);
      const firstField = fields[0];
      const fieldMessage = errors[firstField][0];
      return Promise.reject(fieldMessage);
    }
    if (error.response.data.message) {
      return Promise.reject(error.response.data.message);
    }
    if (error.response.data.error) {
      return Promise.reject(error.response.data.error);
    }
    return Promise.reject("Something Went Wrong");
  }
});

export default axiosInstance;
