
import React, { useEffect, useState } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment/moment";
import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
} from "@store/project/projectActions";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  dateConversion,
  dateConversionSmall,
  onlyDateConversion,
} from "../@utils";
import {
  fetchAllAssets,
  fetchCustomerOverduesAssets,
} from "@store/asset/assetActions";
import { fetchOverDueAssets } from "@store/asset/assetActions";
import { fetchFeatures } from "@store/feature/featureActions";
import { fetchUpdates } from "@store/update/updateActions";
import { fetchCustomers } from "@store/customer/customerActions";
import { fetchUsers } from "@store/customer/customerActions";
import { removeSelectedProject } from "@store/project/projectActions";
import viewIcon from "../assets/img/viewicon.svg";

function Dashboard () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const { role } = useSelector((state) => state.login);
  const { projects, totalProjects, pageIndex, numberOfRows, loading } =
    useSelector((state) => state.projects);
  const { features, totalFeatures } = useSelector((state) => state.features);
  const { updates, totalUpdates } = useSelector((state) => state.updates);
  const { customers, totalCustomers } = useSelector((state) => state.customers);
  const { assets, totalAssets, overDueAssets, totalOverDueAssets } =
    useSelector((state) => state.assets);
  const { totalCustomerOverdueTags } = useSelector((state) => state.assets);
  console.log('➡️ 🤷‍♂️ file: CustomerDashBoard.js:74 🤷‍♂️ totalCustomerOverdueTags', totalCustomerOverdueTags)
  const { users, totalUsers } = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(
      fetchProjects({ page: pageIndex, rows: numberOfRows, searchText: query }),
    );
    dispatch(fetchFeatures({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchUpdates({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchCustomers({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchCustomerOverduesAssets());
  }, [role]);

  const [overDueCount, setOverDueCount] = useState([])


  const overDueTags = () => {
    const count = []
    assets.map(asset => {
      const overdue = moment(asset.maintenanceDate).isBefore(new Date())
      count.push(overdue)
    })
    setOverDueCount(count)
  }

  const viewMatterPortHandler = (asset) => {
    if (role === "ADMIN") {
      history.push(
        "/admin/MatterportEditor/" + asset.projectId + "/" + asset.tagId,
      );
    } else if (role === "CUSTOMER") {
      history.push(
        "/customer/MatterportEditor/" + asset.projectId + "/" + asset.tagId,
      );
    } else if (role === "USER") {
      history.push(
        "/user/MatterportEditor/" + asset.projectId + "/" + asset.tagId,
      );
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-map text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Buildings</p>
                      <CardTitle tag="p">{totalProjects}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/customer/projects")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add A New Building
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-exclamation-triangle text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        <div style={{ fontSize: "13px" }}>Feature Request</div>
                        <div style={{ fontSize: "12px" }}>Reported issues</div>
                      </p>
                      <CardTitle tag="p" style={{ fontSize: "15px" }}>
                        {totalFeatures}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/customer/features")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Request Feature/Report and Issue
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-refresh text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Notifications</p>
                      <CardTitle tag="p">{totalUpdates}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/customer/notifications")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  New Notification
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-users text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">People</p>
                      <CardTitle tag="p">{users.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/customer/users")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add New People
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Row>
          {overDueCount == 0 ? (
            <h3>No Over Due Tag Found</h3>
          ) : (
            <>
              <Col lg="12" md="6" sm="6">
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">
                      Over Due Tags{" "}
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                        style={{ color: "orange", paddingLeft: "2px" }}
                      />
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="table-full-width table-responsive"
                      style={tableStyle.container}
                    >
                      <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                        className="table-overdue"
                      >
                        <tr style={{ borderBottom: "1px solid grey" }}>
                          <th style={tableStyle.head}>Sr No.</th>
                          <th style={tableStyle.head}>Tag Name</th>
                          <th style={tableStyle.head}>Building Name</th>
                          <th style={tableStyle.head}>Maintenance Date</th>
                          <th style={tableStyle.head}>Users</th>
                          <th style={tableStyle.head}>Tags</th>
                        </tr>
                        {totalCustomerOverdueTags &&
                          totalCustomerOverdueTags
                            .filter((asset) =>
                              moment(asset.maintenanceDate).isBefore(
                                new Date(),
                              ),
                            )
                            .map((asset, index) => (
                              <tr style={tableStyle.row}>
                                <td>{index + 1}</td>
                                <td>
                                  <div>{asset?.assetName}</div>
                                </td>

                                <td>
                                  <div data-for="customTooltip" data-tip="View">
                                    {
                                      projects?.filter(
                                        (project) =>
                                          project?._id === asset?.projectId,
                                      )[0]?.title
                                    }
                                  </div>
                                </td>
                                <td>{asset?.maintenanceDate}</td>
                                <td>
                                  <div style={{ display: "flex", gap: "1rem" }}>
                                    {asset &&
                                      asset?.users.map((user) => (
                                        <div style={tableStyle.users}>
                                          {user?.label &&
                                            user?.label.charAt(0).toUpperCase()}
                                        </div>
                                      ))}
                                  </div>
                                </td>
                                <td>
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={viewIcon}
                                    onClick={() => {
                                      viewMatterPortHandler(asset);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="12" md="6" sm="6">
                <Card className="card-tasks">
                  <CardHeader>
                    <CardTitle tag="h4">
                      Tags need attention{" "}
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                        style={{ color: "orange", paddingLeft: "2px" }}
                      />
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div
                      className="table-full-width table-responsive"
                      style={tableStyle.container}
                    >
                      <table style={tableStyle.table} className="table-overdue">
                        <tr style={{ borderBottom: "1px solid " }}>
                          <th style={tableStyle.head}>Sr No.</th>
                          <th style={tableStyle.head}>Tag Name</th>
                          <th style={tableStyle.head}>Building Name</th>
                          <th style={tableStyle.head}>
                            Warranty Expiration Date
                          </th>
                          <th style={tableStyle.head}>Warranty Provider</th>
                          <th style={tableStyle.head}>Users</th>
                          <th style={tableStyle.head}>Tags</th>
                        </tr>
                        {totalCustomerOverdueTags &&
                          totalCustomerOverdueTags
                            .filter(
                              (asset) =>
                                moment(
                                  new Date(asset.warrantyExpirationDate),
                                ).diff(new Date(), "months", true) <= 2,
                            )
                            .map((asset, index) => (
                              <tr style={tableStyle.row}>
                                <td>{index + 1}</td>
                                <td style={{ padding: "15px 0px 15px 0px" }}>
                                  <div>{asset?.assetName}</div>
                                </td>

                                <td>
                                  <div data-for="customTooltip" data-tip="View">
                                    {
                                      projects?.filter(
                                        (project) =>
                                          project?._id === asset?.projectId,
                                      )[0]?.title
                                    }
                                  </div>
                                </td>
                                <td>{asset?.warrantyExpirationDate}</td>
                                <td>{asset?.warrantyProvider}</td>
                                {/* users */}
                                <td>
                                  <div style={{ display: "flex", gap: "1rem" }}>
                                    {asset &&
                                      asset?.users.map((user) => (
                                        <div style={tableStyle.users}>
                                          {user?.label &&
                                            user?.label.charAt(0).toUpperCase()}
                                        </div>
                                      ))}
                                  </div>
                                </td>
                                {/* tags */}
                                <td>
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src={viewIcon}
                                    onClick={() => {
                                      viewMatterPortHandler(asset);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
}

export default Dashboard;

const tableStyle = {
  container: {
    overflow: "auto",
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    ".td": {
      background: "red",
    },
  },

  users: {
    width: "34px",
    height: "34px",
    display: "flex",
    borderRadius: "17px",
    background: "#51bcda",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
    color: "white",
    padding: 0,
  },
  row: {
    borderBottom: "1px solid #e6e6e6",
    alignContent: "center",
    justifyContent: "center",
    paddingLeft: "1rem",
  },
  head: {
    paddingBottom: "1rem",
    paddingLeft: "1rem",
  },
};
