import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import { ReactFormInput as Input } from "../../../components/Common/Input";
import { AddUserValidation } from "./AddUserValidation";
import Select from "react-select";
import { useOnClickOutside } from "../../../@hooks/useOnClickOutside";
import { AddUserPasswordField } from "./AddUserPasswordField";
import ImageUpload from "components/CustomUpload/ImageUpload";

const AddUserModal = ({ id, show, handleClose, handleCreateItem }) => {
  const onCancel = (e) => {
    handleClose();
  };

  useEffect(() => {
    if (id) {
      // call the api and set all values to the form in update
    }
  }, [id]);




  return (
    <Modal
      isOpen={show}
      className="modal-lg modal-responsiveness"
      onHide={handleClose}
    >
      <ModalHeader closeButton>{id ? "Edit People" : "Add People"}</ModalHeader>
      <ModalBody>
        <AddUserForm
          onSubmit={handleCreateItem}
          onCancel={onCancel}
          user={id}
        />
      </ModalBody>
    </Modal>
  );
};

const AddUserForm = ({ onSubmit, onCancel, user }) => {
  const options = [
    { value: "View", label: "View" },
    { value: "Edit", label: "Edit" },
    {
      value: "Admin",
      label: "Admin",
    },
  ];
  let selectedStatusObj = {};
  let selectedStatus = [];
  if (user && user.accessType) {
    selectedStatus = options.filter(function (option) {
      return option.value === user.accessType;
    });
    if (selectedStatus.length > 0) {
      selectedStatusObj = {
        label: selectedStatus[0].label,
        value: selectedStatus[0].value,
      };
    }
  }

  const getLabel = () => {
    if (user?.twoFactorAuthentication) {
      return "Enable";
    } else if (user?.twoFactorAuthentication === false || null) {
      return "Disable";
    }
    return "Enable";
  };

  const defaultValues = {
    isEdit: user ? true : false,
    name: user ? user.name : "",
    email: user ? user.email : "",
    accessType: selectedStatusObj,
    password: "",
    phone: user ? user.phone : "",
    address: user ? user.address : "",
    description: user ? user.description : "",
    photo: user ? user.photo : "",
    twoFactorAuthentication: user?.twoFactorAuthentication
      ? user?.twoFactorAuthentication
      : true,
  };

  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: AddUserValidation,
  });
  const ref = useRef();

  useOnClickOutside(ref, () => onCancel());
  return (
    <div className="space-y-10 w-full" ref={ref}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 w-full md:w-96"
        autocomplete="off"
      >
        <Row>
          <Col>
            <div className="space-y-4">
              <Input
                reactForm
                label={"Name *"}
                name="name"
                control={control}
                type="text"
                placeholder={"Name"}
                error={formState?.errors?.name}
                errorMessage={formState?.errors?.name?.message}
              />
            </div>
            <div className="space-y-4">
              <Input
                reactForm
                autoComplete
                id={"email"}
                label={"Email *"}
                name="email"
                control={control}
                type="email"
                placeholder={""}
                error={formState?.errors?.email}
                errorMessage={formState?.errors?.email?.message}
              />

            </div>

            {!user && (
              <div className="space-y-4">
                <AddUserPasswordField
                  reactForm
                  label="Password *"
                  name="password"
                  control={control}
                  type="password"
                  placeholder={"Password"}
                  error={formState?.errors?.password}
                  errorMessage={formState?.errors?.password?.message}
                />
                <AddUserPasswordField
                  reactForm
                  label="Confirm Password *"
                  name="passwordConfirmation"
                  control={control}
                  type="password"
                  placeholder={"Confirm Password"}
                  error={formState?.errors?.passwordConfirmation}
                  errorMessage={
                    formState?.errors?.passwordConfirmation?.message
                  }
                />
              </div>
            )}
            <div className="space-y-4">
              <Label>Two factor authentication</Label>
              <Select
                name="twoFactorAuthentication"
                className="react-select"
                placeholder="Single Select"
                control={control}
                options={[
                  { label: "Enable", value: true },
                  { label: "Disable", value: false },
                ]}
                defaultValue={{
                  label: getLabel(),
                  value: user?.twoFactorAuthentication
                    ? user?.twoFactorAuthentication
                    : true,
                }}
                onChange={(field) =>
                  setValue("twoFactorAuthentication", field.value)
                }
              />
            </div>

            <div className="space-y-4" style={{ marginTop: "10px" }}>
              <Input
                reactForm
                label={"Address"}
                name="address"
                control={control}
                type="textarea"
                placeholder={""}
              />
            </div>
            <div className="space-y-4">
              <FormGroup>
                <Label>Access Type</Label>
                <Controller
                  name={"accessType"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        options={options}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>
          </Col>
          <Col>
            <ImageUpload
              // className="w-10"
              label={"Upload Photo"}
              imageHeight={"7rem"}
              name="photo"
              setValue={setValue}
              control={control}
              profilepic={true}
              uploadedImage={user ? user?.photo : ""}
              error={formState?.errors?.photo}
              errorMessage={formState?.errors?.photo?.message}
            />

            <div className="space-y-4">
              <Input
                reactForm
                label={"Phone *"}
                name="phone"
                control={control}
                type="phone"
                placeholder={""}
                error={formState?.errors?.phone}
                errorMessage={formState?.errors?.phone?.message}
              />
            </div>

            <div className="space-y-4">
              <Input
                reactForm
                label={"Description"}
                name="description"
                control={control}
                type="textarea"
                placeholder={""}
              />
            </div>
            <div>
              <Button type="submit" color="primary">
                Save
              </Button>
              <Button type="button" color="secondary" onClick={onCancel}>
                cancel
              </Button>
            </div>
          </Col>
        </Row>
      </form>
      {/* </Loader> */}
    </div>
  );
};

export default AddUserModal;
