import React, { useEffect, useState } from "react";
import useSocket from "@hooks/useSocket";
import moment from "moment/moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { dateConversion } from "@utils";
import Loader from "react-loader";

const Notifications = () => {
  const [userNotifications, setUserNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const {
    isCustomer,
    loading,
    user: loggedInUser,
    role,
  } = useSelector((state) => state.login);
  const [isLoading, setIsLoading] = useState(false);
  const url = process.env.REACT_APP_HOSTURL;

  const [notiPagination] = useState({
    page: 1,
    size: 10,
  });

  const { isConnected, lastNotification } = useSocket();
  useEffect(() => {
    if (lastNotification && isConnected) {
      const updatedNotifications = [
        ...userNotifications,
        lastNotification,
      ].sort((left, right) => {
        return moment.utc(right.createdAt).diff(moment.utc(left.createdAt));
      });
      setUserNotifications(updatedNotifications);
    }
  }, [lastNotification]);
  //
  useEffect(() => {
    fetchNotifications();
  }, [lastNotification]);

  useEffect(() => {
    const notiCount = userNotifications.filter(({ read }) => !read).length;
    setNotificationCount(notiCount);
  }, [userNotifications]);
  const fetchNotifications = async () => {
    if (role === "ADMIN") {
      try {
        setIsLoading(true);
        const notifications = await axios.get(
          `${url}api/user/adminnotifications/${loggedInUser._id}`,
        );
        setUserNotifications(notifications.data.result.userNotifications);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (role === "CUSTOMER") {
      try {
        setIsLoading(true);
        const notifications = await axios.get(
          `${url}api/user/notifications/${loggedInUser._id}`,
        );
        setUserNotifications(notifications.data.result.userNotifications);
        setIsLoading(false);
      } catch (error) {
        console.log(error.message);
      }
    }
    if (role === "USER") {
      try {
        const notifications = await axios.get(
          `${url}api/user/peoplenotification/${loggedInUser._id}`,
        );
        setUserNotifications(notifications.data.result.userNotifications);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "3rem",
            marginBottom: "3rem",
          }}
        >
          <Loader />
        </div>
      ) : (
        <Row>
          {userNotifications === 0 ? (
            <Row>
              <div>No Notification Found</div>
            </Row>
          ) : (
            <Col lg="12" md="12" sm="12">
              <Card className="card-tasks">
                <CardHeader>
                  <CardTitle tag="h4">
                    Notifications
                    <i
                      class="fa fa-bell"
                      aria-hidden="true"
                      style={{ color: "orange", paddingLeft: "2px" }}
                    />
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-full-width table-responsive">
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <tr style={{ borderBottom: "1px solid grey" }}>
                        <th style={{ paddingBottom: "1rem" }}>Sr No.</th>

                        <th style={{ paddingBottom: "1rem" }}>Title</th>
                        <th style={{ paddingBottom: "1rem" }}>Type</th>
                        <th style={{ paddingBottom: "1rem" }}>Date</th>
                      </tr>
                      {userNotifications &&
                        userNotifications.map((asset, index) => (
                          <tr
                            style={{
                              borderBottom: "1px solid #e6e6e6",
                              alignContent: "center",
                              justifyContent: "center",
                              padding: "15px 0px 15px 0px",
                            }}
                          >
                            <td>{index + 1}</td>
                            <td style={{ padding: "15px 0px 15px 0px" }}>
                              <div>{asset?.title}</div>
                            </td>

                            <td>
                              <div data-for="customTooltip" data-tip="View">
                                {asset?.type}
                              </div>
                            </td>

                            <td>
                              <div style={{ display: "flex", gap: "1rem" }}>
                                {dateConversion(asset?.createdAt)}
                              </div>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      )}
    </div>
  );
};
export default Notifications;
