import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import React, { useEffect, useRef, useState } from "react";

import { AddCustomerValidation } from "./AddCustomerValidation";
import { AddUserPasswordField } from "../users/AddUserPasswordField";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { ReactFormInput as Input } from "../../../components/Common/Input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

const AddCustomerModal = ({ id, show, handleClose, handleCreateItem }) => {
  const onCancel = (e) => {
    handleClose();
  };

  useEffect(() => {
    if (id) {
      // call the api and set all values to the form in update
    }
  }, [id]);

  return (
    <Modal
      isOpen={show}
      className="modal-lg modal-responsiveness"
      onHide={handleClose}
      centered={true}
    >
      <ModalHeader closeButton>
        {id ? "Edit Customer" : "Add Customer"}
      </ModalHeader>
      <ModalBody>
        <AddCustomerForm
          customer={id}
          onSubmit={handleCreateItem}
          onCancel={onCancel}
        />
      </ModalBody>
    </Modal>
  );
};

const AddCustomerForm = ({ customer, onSubmit, onCancel }) => {
  const [twoFactor, setTwoFactor] = useState(true);

  const defaultValues = {
    isEdit: customer ? true : false,
    name: customer ? customer.name : "",
    email: customer ? customer.email : "",
    password: customer ? customer.password : "",
    phone: customer ? customer.phone : "",
    address: customer ? customer.address : "",
    description: customer ? customer.description : "",
    photo: customer ? customer.photo : "",
    twoFactorAuthentication: customer?.twoFactorAuthentication
      ? customer?.twoFactorAuthentication
      : true,
  };
  const { control, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    resolver: AddCustomerValidation,
  });

  const getLabel = () => {
    if (customer?.twoFactorAuthentication) {
      return "Enable";
    } else if (customer?.twoFactorAuthentication === false || null) {
      return "Disable";
    }
    return "Enable";
  };
  const ref = useRef();
  useOnClickOutside(ref, () => onCancel());

  const style = {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "20px",
  };
  return (
    <div ref={ref} className="space-y-10 w-full">
      {/* <Loader active={isLoading}> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 w-full md:w-96"
        autoComplete="off"
      >
        <input
          type="email"
          name="email"
          style={{ width: 0, height: 0, opacity: 0 }}
        ></input>
        <input
          type="password"
          style={{ width: 0, height: 0, opacity: 0 }}
        ></input>
        <div style={style}>
          <div>
            <div>
              <Input
                reactForm
                label={"Name *"}
                name="name"
                control={control}
                type="text"
                placeholder={"Name"}
                error={formState?.errors?.name}
                errorMessage={formState?.errors?.name?.message}
              />
            </div>
            <div className="space-y-4">
              <Input
                reactForm
                id="efvfhfhefhfbhbfhbfhfbhrbfhbf"
                label={"Email"}
                name="email"
                control={control}
                type="text"
                placeholder={"Email"}
                autoComplete="off"
                error={formState?.errors?.email}
                errorMessage={formState?.errors?.email?.message}
              />
            </div>
          </div>
          <div className="w-full">
            <ImageUpload
              label={"Upload Photo"}
              imageHeight={"6rem"}
              name="photo"
              setValue={setValue}
              control={control}
              profilepic={true}
              uploadedImage={customer ? customer?.photo : ""}
              error={formState?.errors?.photo}
              errorMessage={formState?.errors?.photo?.message}
            />
          </div>
        </div>

        {!customer && (
          <div style={style}>
            <div className="space-y-4">
              <AddUserPasswordField
                reactForm
                label="Password *"
                name="password"
                control={control}
                type="password"
                autoComplete="off"
                placeholder={"Password"}
                error={formState?.errors?.password}
                errorMessage={formState?.errors?.password?.message}
              />
            </div>
            <div>
              <AddUserPasswordField
                reactForm
                label="Confirm Password *"
                name="passwordConfirmation"
                control={control}
                type="password"
                placeholder={"Confirm Password"}
                error={formState?.errors?.passwordConfirmation}
                errorMessage={formState?.errors?.passwordConfirmation?.message}
              />
            </div>
          </div>
        )}

        <div style={style}>
          <div className="space-y-4">
            <Input
              reactForm
              label={"Phone *"}
              name="phone"
              control={control}
              type="phone"
              placeholder={"Phone"}
              error={formState?.errors?.phone}
              errorMessage={formState?.errors?.phone?.message}
            />
          </div>
          <div className="space-y-4">
            <Label>Two factor authentication</Label>
            <Select
              name="twoFactorAuthentication"
              className="react-select"
              placeholder="Single Select"
              control={control}
              options={[
                { label: "Enable", value: true },
                { label: "Disable", value: false },
              ]}
              defaultValue={{
                label: getLabel(),
                value: customer?.twoFactorAuthentication
                  ? customer?.twoFactorAuthentication
                  : true,
              }}
              onChange={(field) =>
                setValue("twoFactorAuthentication", field.value)
              }
            />
          </div>
          <div>
            <div className="space-y-4">
              <Input
                reactForm
                label={"Address"}
                name="address"
                control={control}
                type="textarea"
                placeholder={"Address"}
                height="140px"
              />
            </div>
          </div>
          <div className="space-y-4">
            <Input
              reactForm
              label={"Description"}
              name="description"
              control={control}
              type="textarea"
              placeholder={"Description"}
              height="140px"
            />
          </div>
        </div>
        <div className="text-center">
          <Button className="btn-wd" type="submit" color="primary">
            Save Changes
          </Button>
          <Button
            className="btn-wd"
            type="button"
            color="secondary"
            onClick={onCancel}
          >
            cancel
          </Button>
        </div>
      </form>
      {/* </Loader> */}
    </div>
  );
};

export default AddCustomerModal;
