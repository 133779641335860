import { Button, Input, Modal } from "reactstrap";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

import React, { useRef } from "react";

const UploadModal = ({ show, handleClose, handleAction }) => {
  const [fileState, setFileState] = React.useState(null);
  const [fileName, setFileName] = React.useState("");
  const [extension, setExtension] = React.useState(null);

  const onCancel = (e) => {
    handleClose();
    setFileName("");
  };
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    const ext = file.name.split(".").pop();
    reader.onloadend = () => {
      setFileState(reader.result);
      setExtension(ext);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const onSubmit = () => {
    if (fileName) {
      handleAction(fileName, fileState, extension);
      setFileName("");
    }
    if (fileName === null || (fileName === "" && fileState)) {
      alert("Please add file name");
    }
  };
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());
  return (
    <Modal
      className="modal-dialog modal-md "
      isOpen={show}
      onHide={handleClose}
    >
      <div style={styles.modalContent} ref={ref}>
        <strong className="h2" style={styles.headingText}>
          UPLOAD AN ATTACHMENT
        </strong>
        <span style={{ color: "grey" }}>(Max file size: 20mb)</span>

        <input
          className="Upload_input"
          // style={{ backgroundColor: "#66615B" }}
          type="file"
          onChange={(e) => handleImageChange(e)}
        />

        <Input
          name="name"
          type="text"
          style={{
            marginTop: 10,
            padding: "10px 10px",
            fontSize: "14px",
            width: "320px",
            height: "50px",
          }}
          value={fileName}
          onInput={(e) => setFileName(e.target.value)}
          placeholder={"Type file name here ...."}
        />

        <div style={styles.btnContainer}>
          <Button
            disabled={!fileState}
            onClick={onSubmit}
            color="primary"
            style={styles.btn}
          >
            Submit
          </Button>

          <Button
            style={{ ...styles.btn, backgroundColor: "#66615B" }}
            type="button"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "25px 40px",
  },
  btn: {
    width: "150px",
    backgroundColor: "#43BF57",
  },
  headingText: {
    marginTop: 30,
    marginBottom: 30,
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#000000",
  },
  btnContainer: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "30px",
  },
};

export default UploadModal;
