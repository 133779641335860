import { getProject } from "@store/project/projectActions";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ProjectMarketingModel = () => {
  const { selectedProject } = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [projectId, setProjectId] = useState(id);
  const iframe_ref = useRef(null);

  useEffect(() => {
    if (projectId && projectId != undefined) {
      dispatch(getProject(projectId));
    }
  }, [projectId]);
  useEffect(() => {
    if (selectedProject && selectedProject._id === projectId) {
      iframe_ref.current.setAttribute(
        "src",
        selectedProject.geomausMarketingModel
      );
    }
  }, [selectedProject]);
  return (
    <iframe
      style={{ width: "100vw", height: "100vh" }}
      ref={iframe_ref}
      className="showcase-iframe"
      frameBorder="0"
      allow="autoplay"
      allowFullScreen
    />
  );
};

export default ProjectMarketingModel;
