import { createSlice } from "@reduxjs/toolkit";
import { fetchUpdates, createUpdate, deleteUpdate } from "./updateActions";
const initialState = {
  updates: [],
  totalUpdates: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
};

export const UpdateSlice = createSlice({
  name: "updates",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUpdates.pending]: (state) => {
      state.loading = true;
    },
    [fetchUpdates.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.updates = payload.result.docs;
      state.totalUpdates = payload.result.totalDocs;
      state.numberOfRows = payload.result.limit;
      state.pageIndex = payload.result.page;
    },
    [fetchUpdates.rejected]: (state) => {
      state.loading = false;
    },
    [createUpdate.pending]: (state) => {
      state.loading = true;
    },
    [createUpdate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const update = payload.result;
      const updates = state.updates;
      state.updates = [update, ...updates];
    },
    [createUpdate.rejected]: (state) => {
      state.loading = false;
    },
    [deleteUpdate.pending]: (state) => {
      state.loading = true;
    },
    [deleteUpdate.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const deletedId = payload.result;
      const updates = state.updates;
      const filteredUpdates = updates.filter(function (obj) {
        return obj._id !== deletedId;
      });

      state.updates = [...filteredUpdates];
    },
    [deleteUpdate.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// this is for configureStore
export default UpdateSlice.reducer;
