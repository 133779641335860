import React from "react";
import AddTutorialModal from "views/admin/projects/AddTutorialVideoModal";
import { Can } from "@permissions/Can";
import { Row, Button } from "reactstrap";
import {
  createTutorial,
  fetchTutorials,
  updateTutorial,
} from "@store/tutorials/tutorialsActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import PlayerModal from "./PlayerModal";
import VideoCard from "./VideoCard";

export default function Tutorials() {
  const dispatch = useDispatch();
  const [currentSrc, setCurrentSrc] = useState("");
  const { tutorials, loading } = useSelector((state) => state.tutorial);
  const [show, setShow] = useState(false);
  const [playerModal, setPlayerModal] = useState(false);
  const handleShow = () => {
    setShow(true);
    setDatatoEdit("");
  };
  const handleClose = () => setShow(false);

  const [selected, setSelected] = useState(null);
  const [dataToEdit, setDatatoEdit] = useState("");

  const handleCreateTutorial = async (data) => {
    if (selected === null) {
      dispatch(createTutorial(data));
    }
    if (selected) {
      dispatch(updateTutorial({ id: selected, body: data }));
      setSelected(null);
    }
  };

  useEffect(() => {
    dispatch(fetchTutorials());
  }, [tutorials.length]);
  const handleClick = () => {
    setPlayerModal(true);
  };
  const handlePlayerClose = () => {
    setPlayerModal(false);
  };

  const handleEditClick = (title, thumbnail, src, id) => {
    setShow(true);
    setDatatoEdit({ title, thumbnail, src, id });
  };

  return (
    <>
      <div className="content">
        <div className="TableTop">
          <Can I="create" a="Tutorial">
            <Button variant="primary" onClick={handleShow}>
              Add Tutorial
            </Button>
          </Can>
        </div>
        <AddTutorialModal
          show={show}
          handleClose={handleClose}
          handleCreateItem={handleCreateTutorial}
          current={dataToEdit}
        />
        <Row style={{ gap: "40px", display: "flex" }}>
          {tutorials?.map((item) => {
            return (
              <VideoCard
                title={item.title}
                src={item.video}
                thumbnail={item.thumbnail}
                handleClick={handleClick}
                setCurrentSrc={setCurrentSrc}
                handleEditClick={handleEditClick}
                id={item._id}
                setSelected={setSelected}
              />
            );
          })}
        </Row>
      </div>
      <PlayerModal
        show={playerModal}
        handleClose={handlePlayerClose}
        src={currentSrc}
      />
    </>
  );
}
