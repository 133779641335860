import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "@api/axios";
const fetchAssets = createAsyncThunk(
  "assets/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const { id } = data;

      const res = await axiosInstance.get(`/asset/` + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const fetchAllAssets = createAsyncThunk(
  "assets/fetch/all",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/assets/`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const fetchOverDueAssets = createAsyncThunk(
  "assets/fetch/overdue",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/overDueAssets/`);
      return res.data;
    } catch (err) {
      console.log("fetch overdue  assets", err);

      return rejectWithValue(err);
    }
  },
);

const fetchCustomerOverduesAssets = createAsyncThunk(
  "assets/customerOverdue",

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/getcustomerstag`);
      return res.data;
    } catch (err) {
      console.log("fetch overdue  assets", err);

      return rejectWithValue(err);
    }
  },
);

const fetchUserOverduesAssets = createAsyncThunk(
  "assets/userOverdue",

  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/getuserstag`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const getAsset = createAsyncThunk(
  "assets/getAsset",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/getAsset/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const createAsset = createAsyncThunk(
  "assets/add",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/asset`, body);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const updateAsset = createAsyncThunk(
  "assets/update",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/asset/` + id, body);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const completeAsset = createAsyncThunk(
  "assets/complete",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/asset/complete/` + id, body);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const deleteAsset = createAsyncThunk(
  "assets/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/asset/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);

const createAssetComment = createAsyncThunk(
  "assetComment/add",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/assetComment`, body);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const uploadFile = createAsyncThunk(
  "asset/upload",
  async ({ id, name, file, extension }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/asset/Upload/` + id, {
        name,
        file,
        extension,
      });
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const getFiles = createAsyncThunk(
  "asset/files",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/asset/files/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const deleteFile = createAsyncThunk(
  "assets/filesDelete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/asset/file/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const editFile = createAsyncThunk(
  "assets/filesEdit",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/asset/file/` + id, body);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);

export {
  fetchAssets,
  fetchAllAssets,
  fetchOverDueAssets,
  getAsset,
  deleteAsset,
  createAsset,
  updateAsset,
  completeAsset,
  createAssetComment,
  uploadFile,
  editFile,
  deleteFile,
  getFiles,
  fetchCustomerOverduesAssets,
  fetchUserOverduesAssets,
};
