/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link, useHistory } from "react-router-dom";
import moment from "moment/moment";
import axios from 'axios';

import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
} from "@store/project/projectActions";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  dateConversion,
  dateConversionSmall,
  onlyDateConversion,
} from "../@utils";
import {
  fetchAllAssets,
  fetchCustomerOverduesAssets,
} from "@store/asset/assetActions";
import { fetchOverDueAssets } from "@store/asset/assetActions";
import { fetchFeatures } from "@store/feature/featureActions";
import { fetchUpdates } from "@store/update/updateActions";
import { fetchCustomers } from "@store/customer/customerActions";

function Dashboard () {
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.login);

  const history = useHistory();
  const [query, setQuery] = useState("");
  const { projects, totalProjects, pageIndex, numberOfRows, loading } =
    useSelector((state) => state.projects);
  const { features, totalFeatures } = useSelector((state) => state.features);
  const { updates, totalUpdates } = useSelector((state) => state.updates);
  const { customers, totalCustomers } = useSelector((state) => state.customers);
  const { assets, totalAssets, overDueAssets, totalOverDueAssets } =
    useSelector((state) => state.assets);
  const { totalCustomerOverdueTags } = useSelector((state) => state.assets);

  useEffect(() => {
    dispatch(
      fetchProjects({ page: pageIndex, rows: numberOfRows, searchText: query }),
    );
    dispatch(fetchFeatures({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchUpdates({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchCustomers({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchCustomerOverduesAssets());
  }, [role]);

  const [overDueCount, setOverDueCount] = useState([]);
  const overDueTags = () => {
    const count = [];
    if (totalCustomerOverdueTags && Array.isArray(totalCustomerOverdueTags)) {
      totalCustomerOverdueTags.map((asset) => {
        const overdue = moment(asset.maintenanceDate).isBefore(new Date());
        count.push(overdue);
      });
    }
    setOverDueCount(count);
  };

  useEffect(() => {
    overDueTags();
  }, [role]);






  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-map text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Projects</p>
                      <CardTitle tag="p">{totalProjects}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/admin/projects")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add A New Project
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-exclamation-triangle text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        <div style={{ fontSize: "13px" }}>Feature Request</div>
                        <div style={{ fontSize: "12px" }}>Reported issues</div>
                      </p>
                      <CardTitle tag="p" style={{ fontSize: "15px" }}>
                        {totalFeatures}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/admin/features")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add A New Feature
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-refresh text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Updates</p>
                      <CardTitle tag="p">{totalUpdates}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/admin/updates")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add A New Update
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-users text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Customers</p>
                      <CardTitle tag="p">{customers.length}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/admin/customers")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add A New Customer
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-map text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Tags</p>
                      <CardTitle tag="p">{totalAssets}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-refresh" />
                  All Tags
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-exclamation-triangle text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Overdue Tags</p>
                      <CardTitle tag="p">
                        {overDueCount.filter(Boolean).length}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-refresh" />
                  All Overdue projectsTags
                </div>
              </CardFooter>
            </Card>
          </Col>
          {/* <Col md="3">
            <Card>
              <CardHeader>
                <CardTitle style={{ fontWeight: "bold" }}>
                  Recent Project
                </CardTitle>
                <p className="card-category">
                  {projects.length == 0
                    ? "No Projects Added"
                    : projects[0]?.title}
                </p>
              </CardHeader>
              <CardBody>
                {projects.length == 0
                  ? "No Projects Added"
                  : projects[0]?.description.substring(0, 70) + " ..."}
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" />
                  created at :
                  {projects.length == 0
                    ? "No Projects Added"
                    : onlyDateConversion(projects[0]?.createdAt)}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <CardHeader>
                <CardTitle style={{ fontWeight: "bold" }}>
                  Recent Feature Requests
                </CardTitle>
                <p className="card-category">
                  {features.length == 0
                    ? "No Feature is Requested"
                    : features[0]?.title}
                </p>
              </CardHeader>
              <CardBody>
                {features.length == 0
                  ? "No Feature is Requested"
                  : features[0]?.details.substring(0, 70) + " ..."}
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" />
                  created at :
                  {features.length == 0
                    ? "No Feature is Requested"
                    : onlyDateConversion(features[0]?.createdAt)}
                </div>
              </CardFooter>
            </Card>
          </Col> */}
          <Col md="3">
            <Card>
              <CardHeader>
                <CardTitle style={{ fontWeight: "bold" }}>
                  Recent Update
                </CardTitle>
                <p className="card-category">
                  {updates.length == 0
                    ? "Not Found Any Update"
                    : updates[0]?.title}
                </p>
              </CardHeader>
              <CardBody>
                {updates.length == 0
                  ? "Not Found Any Update"
                  : updates[0]?.details.substring(0, 70) + " ..."}
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" />
                  created at :
                  {updates.length == 0
                    ? "Not Found Any Update"
                    : onlyDateConversion(updates[0]?.createdAt)}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="3">
            <Card>
              <CardHeader>
                <CardTitle style={{ fontWeight: "bold" }}>
                  Recent Customer
                </CardTitle>
                <p className="card-category">
                  Name :
                  {customers.length == 0
                    ? "Not Found Any Customer"
                    : customers[0]?.name}
                </p>
              </CardHeader>
              <CardBody>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    <span style={{ fontWeight: "bold" }}>Email :</span>{" "}
                    {customers.length == 0
                      ? "Not Found Any Customer"
                      : customers[0]?.email}
                  </span>
                  <span>
                    <span style={{ fontWeight: "bold" }}> Phone :</span>

                    {customers.length == 0
                      ? "Not Found Any Customer"
                      : customers[0]?.phone}
                  </span>
                </div>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" />
                  created at :
                  {customers.length == 0
                    ? "Not Found Any Customer"
                    : onlyDateConversion(customers[0]?.createdAt)}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6" sm="6">
            <Card className="card-tasks">
              <CardHeader>
                <CardTitle tag="h4">Recent Projects</CardTitle>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tr>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Description</th>
                    </tr>
                    {projects?.slice(0, 5).map((project, key) => (
                      <tr key={key}>
                        <td style={{ padding: "8px" }}>
                          <div
                            className="img-row"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="img-wrapper">
                              <img
                                alt="..."
                                className="img-raised"
                                src={project?.logoPhoto}
                                width={30}
                                height={30}
                              />
                            </div>
                          </div>
                        </td>
                        <td>{project.title}</td>
                        <td>{project.description}</td>
                      </tr>
                    ))}
                  </table>
                </div>

                <Button
                  color="primary"
                  onClick={() => history.push("/admin/projects")}
                >
                  Add a New Project
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" md="6" sm="6">
            <Card className="card-tasks">
              <CardHeader>
                <CardTitle tag="h4">
                  Feature Request / Reported issues{" "}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="table-full-width table-responsive">
                  <table>
                    <tr>
                      <th>Title</th>

                      <th>Date Added</th>
                    </tr>
                    {features?.slice(0, 5).map((feature, key) => (
                      <tr key={key}>
                        <td style={{ padding: "8px" }}>{feature.title}</td>
                        <td style={{ padding: "8px" }}>
                          {onlyDateConversion(feature.createdAt)}
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
                <Button
                  color="primary"
                  onClick={() => history.push("/admin/features")}
                >
                  New Feature Requests
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
