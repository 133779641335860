import { createSlice } from "@reduxjs/toolkit";
import { fetchFeatures, createFeature, deleteFeature } from "./featureActions";
const initialState = {
  features: [],
  totalFeatures: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
};

export const featureSlice = createSlice({
  name: "features",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFeatures.pending]: (state) => {
      state.loading = true;
    },
    [fetchFeatures.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.features = payload.result.docs;
      state.totalFeatures = payload.result.totalDocs;
      state.numberOfRows = payload.result.limit;
      state.pageIndex = payload.result.page;
    },
    [fetchFeatures.rejected]: (state) => {
      state.loading = false;
    },
    [createFeature.pending]: (state) => {
      state.loading = true;
    },
    [createFeature.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const feature = payload.result;
      const features = state.features;
      state.features = [feature, ...features];
      state.totalFeatures = state.totalFeatures + 1;
    },
    [createFeature.rejected]: (state) => {
      state.loading = false;
    },
    [deleteFeature.pending]: (state) => {
      state.loading = true;
    },
    [deleteFeature.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const deletedId = payload.result;
      const features = state.features;

      const filteredFeatures = features.filter(function (obj) {
        return obj._id !== deletedId;
      });
      state.features = [...filteredFeatures];
      state.totalFeatures = state.totalFeatures - 1;
    },
    [deleteFeature.rejected]: (state) => {
      state.loading = false;
    },
  },
});

// this is for configureStore
export default featureSlice.reducer;
