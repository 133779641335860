import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import loginSlice from "./login/loginSlice";
import projectSlice from "./project/projectSlice";
import featureSlice from "./feature/featureSlice";
import customerSlice from "./customer/customerSlice";
import updateSlice from "./update/updateSlice";
import notificationSlice from "./notification/notificationSlice";
import tutorialsSclice from "./tutorials/tutorialsSclice";
import assetSlice from "./asset/assetSlice";
import { handleUserRole } from "@permissions/Ability";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { combineReducers } from "redux";
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const appReducer = combineReducers({
  login: loginSlice,
  projects: projectSlice,
  features: featureSlice,
  customers: customerSlice,
  updates: updateSlice,
  notifications: notificationSlice,
  assets: assetSlice,
  tutorial: tutorialsSclice,
});
let rootReducer = (state, action) => {
  if (action.type === "RESET") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
let persistor = persistStore(store);
let currentAuth;
store?.subscribe(() => {
  const prevAuth = currentAuth;
  currentAuth = store.getState().login;

  if (prevAuth !== currentAuth) {
    handleUserRole(currentAuth.role, currentAuth.accessType);
  }
});

export { store, persistor };
