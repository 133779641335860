import React, { useLayoutEffect, useState } from "react";

import { FilesIcon } from "assets/svg/files";
import styled from "styled-components";
import { useSelector } from "react-redux";
import moment from "moment";
const CommentList = ({ comment, tagModal, setSelectedTab }) => {
  const { user } = useSelector((state) => state.login);
  const [showMore, setShowMore] = useState(comment.title.length > 30);
  const [send, setSend] = useState(false);
  const { selectedAssetFiles, loading } = useSelector((state) => state.assets);

  const handleFileView = () => {
    setSelectedTab("files")
  }


  const messageTime = moment(comment.createdAt).format("h:mm A");
  const findLink =
    useLayoutEffect(() => {
      if (user?._id == comment?.userId?._id) {
        setSend(true);
      } else {
        setSend(false);
      }
    }, [comment]);
  return (
    <Row send={send} key={comment._id}>
      <div
        style={{
          marginRight: "0.5rem",
          marginTop: "5px",
        }}
      ></div>

      {comment && comment?.userId?.name && (
        <>
          <UserAlphaStyle send={send}>
            {comment?.userId?.name.charAt(0)}
          </UserAlphaStyle>
          <ChatBubble send={send}>
            <div>
              <UserName>{comment?.userId?.name}</UserName>
              {comment?.messageType == "file" ? (
                <TextStyle>
                  <div onClick={() => handleFileView()} style={{ cursor: "pointer" }}>
                    <span style={{ margin: "0px 10px 10px" }}>
                      <FilesIcon fill="white" />
                    </span>

                    {comment.title}
                  </div>
                </TextStyle>
              ) : (
                <TextStyle>{comment.title}</TextStyle>
              )}
            </div>
            <div>
              {send ? (
                <MessageTimeStyleSend>{messageTime}</MessageTimeStyleSend>
              ) : (
                <MessageTimeStyleReceive>{messageTime}</MessageTimeStyleReceive>
              )}
            </div>
          </ChatBubble>
        </>
      )}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.send ? "row-reverse" : "row")};
  align-items: flex-end;
  padding-bottom: 0.9rem;
  gap: 10px;
`;
const UserName = styled.p`
  color: #fff;
  font-weight: 700;
  padding: 0px;
  margin: 5px;
`;

const TextStyle = styled.p`
  color: #ffff;
  padding: 0px;
  margin: 5px;
`;
const ChatBubble = styled.div`
  background: ${(props) => (props.send ? "grey" : "#23a6cf")};
  width: 250px;
  border-radius: 20px;
  text-align: start;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: ${(props) => (props.send ? "right" : "left")};
  position: relative;
  gap: 20px;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
`;

const MessageTimeStyleReceive = styled.div`
  position: absolute;
  bottom: 10px;
  color: #fff;
  right: 17px;
`;
const MessageTimeStyleSend = styled.div`
  position: absolute;
  bottom: 10px;
  color: #fff;
  left: 17px;
`;
const UserAlphaStyle = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  border-radius: 17px;
  background: ${(props) => (props.send ? "grey" : "#23a6cf")};
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  padding: 0;
  margin-bottom: 8px;
`;

export default CommentList;
