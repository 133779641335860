import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { phoneRegExp } from "@validation/regex";

const AddCustomerSchema = yup.object().shape({
  name: yup.string().required("Name should not be empty"),
  phone: yup
    .string()
    .required()
    .matches(phoneRegExp, "Phone number is not valid")
    .min(10, "Phone length should be greater then 10")
    .max(20, "Phone length should be less then 20"),

  email: yup
    .string()
    .email("Invalid email format")
    .required("Email cannot be empty"),
  password: yup.string().when("isEdit", {
    is: (isEdit) => isEdit == false,
    then: yup
      .string()
      .min(5, "password length should be greater then 5")
      .max(20, "password length should be less then 20")
      .required("Password cannot be empty"),
    otherwise: yup.string(),
  }),
  passwordConfirmation: yup.string().when("isEdit", {
    is: (isEdit) => isEdit == false,
    then: yup
      .string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      }),
  }),
});

const AddCustomerValidation = yupResolver(AddCustomerSchema);

export { AddCustomerValidation };
