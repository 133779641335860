import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
// 
function initSocket () {
  return io(process.env.REACT_APP_HOSTURL, {
    auth: {
      token:
        localStorage.getItem('customer_access_token')
        ||
        localStorage.getItem('access_token')
        || null
    }
  });
}
let socket = null;
// 
export default function useSocket () {
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [lastNotification, setLastNotification] = useState(null);
  const { isCustomer } = useSelector(state => state.login)
  // 
  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        setIsConnected(true);
      });
      socket.on('reconnect', () => {
        setIsConnected(true);
      });
      // 
      socket.on('disconnect', () => {
        setIsConnected(false);
      });
      // 
      socket.on('notification', (data) => {
        setLastNotification(data);
        toast.info(data.title);
      });
      // 
      return () => {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('notificaiton');
      };
    }
  }, [socket]);
  useEffect(() => {
    if (socket) {
      socket.disconnect();
    }
    socket = initSocket();
  }, [isCustomer])
  // 
  return {
    isConnected,
    lastNotification
  }
}
