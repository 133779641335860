import React from "react";
const PaginationIcon = (props) => {
  const { src, disabled, handleClick } = props;
  return (
    <>
      <div className="paggination-icon-wrapper">
        <img
          className={disabled ? "icon-disabled" : "icon"}
          src={src}
          onClick={handleClick}
        />
      </div>
    </>
  );
};
export default PaginationIcon;
