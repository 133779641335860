import React, { useState } from "react";
import fileAttachment from "../../assets/svg/file_attachment.svg";
import styled from "styled-components";
import UploadModal from "components/Common/modal/UploadModal";
import { useDispatch } from "react-redux";
import { uploadFile } from "@store/asset/assetActions";
const FilePickerUpload = ({ assetId, handleComment }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleUploadShow = () => setShow(true);
  const handleUploadClose = () => setShow(false);
  const handleAssetFileUpload = (name, file, extension) => {
    handleUploadClose();
    const form_data = { file };
    dispatch(uploadFile({ id: assetId, name, file: form_data, extension }));
    handleComment({
      title: name + "." + extension,
      assetId: assetId,
      messageType: "file",
    });
  };

  return (
    <>
      <UploadModal
        show={show}
        handleClose={handleUploadClose}
        handleAction={handleAssetFileUpload}
      />
      <div onClick={handleUploadShow}>
        <label style={{ cursor: "pointer" }}>
          <img
            src={fileAttachment}
            style={{ height: "14px", width: "16px", marginTop: "50%" }}
          />
        </label>
      </div>
    </>
  );
};

export default FilePickerUpload;
