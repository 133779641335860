import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { urlRegExp } from "@validation/regex";

const AddProjectSchema = yup.object().shape({
  title: yup.string().required("Title should not be empty"),
  description: yup.string().required("Description should not be empty"),
  matterPortUrl: yup.string().required("Matterport ID should not be empty"),
  // coverPhoto: yup.string().required("Cover Photo is required"),

  // coverPhoto: yup.mixed().test("required", "photo is required", (value) => {
  //   return value && value.length;
  // }),
  // coverPhoto: yup.mixed().required("Logo Photo is required"),
  // logoPhoto: yup.mixed().required("Logo Photo is required"),
});

const AddProjectCustomerSchema = yup.object().shape({
  title: yup.string().required("Title should not be empty"),
  description: yup.string().required("Description should not be empty"),
  coverPhoto: yup.mixed().required("Logo Photo is required"),
})

const AddProjectValidation = yupResolver(AddProjectSchema);
const AddProjectCustomerValidation = yupResolver(AddProjectCustomerSchema)

export { AddProjectValidation, AddProjectCustomerValidation };
