import React, { useRef, useState } from 'react'

import { Can } from '@permissions/Can'
import { Card } from 'reactstrap'
import actions from '../../assets/svg/actions.svg'
import deleteIcon from '../../assets/img/deleteicon.svg'
import editIcon from '../../assets/img/editicon.svg'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import viewIcon from '../../assets/img/viewicon.svg'

export const ActionsModal = ({ handleEditProject, handleDeleteClicked, handleViewProject, project }) => {
  const [popup, setPopup] = useState(false)
  const popOverStyle = {
    display: 'flex',
    padding: '12px',
    flexDirection: 'column',
    borderRadius: '12px',
    gap: '14px',
    width: '100px',
    position: 'absolute',
    zIndex: '1',
    right: '-23px',
    width: '128px',
    top: '-50px',
    padding: '15px 30px 15px 29px'
  }

  const popRowStyle = {
    display: 'flex',
    fontSize: '12px',
    fontSize: '12px',
    alignItems: 'flexStart',
    gap: '10px',
    cursor: 'pointer'

  }
  const ref = useRef();
  useOnClickOutside(ref, () => { setPopup(false) });
  return (
    <>
      <span style={IconStyle} onClick={() => { setPopup(true) }}>
        <img src={actions} alt="map-icon" style={{ marginTop: "10px" }} />
      </span>
      {popup && (
        <div ref={ref} style={{ position: 'absolute' }}>
          <Card style={popOverStyle}>
            <div style={popRowStyle} onClick={() => { handleViewProject(project) }}>
              <img src={viewIcon} style={IconStyle} />
              <span>View</span>
            </div>
            <Can I="edit" a="Project" passThrough>
              {allowed =>
                allowed && (
                  <div style={popRowStyle} onClick={() => {
                    setPopup(false)
                    handleEditProject(project)
                  }} >
                    <img src={editIcon} style={IconStyle} />
                    <span>Edit</span>
                  </div>
                )
              }
            </Can>
            <Can I="delete" a="Project" passThrough>
              {allowed =>
                allowed && (
                  <div style={popRowStyle} onClick={() => handleDeleteClicked(project._id)} >
                    <img src={deleteIcon} style={IconStyle}
                    />
                    <span>Delete</span>
                  </div>
                )
              }
            </Can>
          </Card>
        </div>
      )}
    </>
  )
}


const IconStyle = {
  cursor: 'pointer',
  height: "15px",
  width: "20px",

}
