import moment from "moment";
const dateConversion = (date) => {
  const convertedDate = moment(date).format("MMMM Do YYYY, h:mm:ss a");
  return convertedDate;
};
const dateConversionSmall = (date) => {
  const convertedDate = moment(date).format("MM-DD-YY, h:mm:ss a");
  return convertedDate;
};
const onlyDateConversion = (date) => {
  const convertedDate = moment(date).format("MMMM Do YYYY");
  return convertedDate;
};
const onlyDateConversion2 = (date) => {
  const convertedDate = moment(date).format("MM-Do-YY");
  return convertedDate;
};

const onlyTimeCovertion = (date) => {
  const convertedDate = moment(date).format('LT');
  return convertedDate;
};

function get_url_extension (url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}
function get_url_name (url) {
  return url.substr(0, url.lastIndexOf("/"));
}

const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16) / 255,
      g: parseInt(result[2], 16) / 255,
      b: parseInt(result[3], 16) / 255,
    }
    : null;
};
function componentToHex (c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex ({ r, g, b }) {
  return (
    "#" +
    componentToHex(r * 255) +
    componentToHex(g * 255) +
    componentToHex(b * 255)
  );
}

function SaveAndRedirect (userData, history, redirect = true) {
  localStorage.setItem("access_token", userData.accessToken);
  localStorage.setItem("user", JSON.stringify(userData.user));

  if (redirect) {
    if (userData.user.role[0] === "ADMIN") {
      history.push("/admin/dashboard");
    }
    if (userData.user.role[0] === "CUSTOMER") {
      history.push("/customer/dashboard");
    }
    if (userData.user.role[0] === "USER") {
      history.push("/user/dashboard");
    }
  }
}

// get label for the select input  [ OTP ]
function getLabel () {
  if (customer?.twoFactorAuthentication) {
    return "Enable";
  } else if (customer?.twoFactorAuthentication === false || null) {
    return "Enable";
  }
  return "Enable";
}
export {
  dateConversion,
  onlyDateConversion,
  onlyDateConversion2,
  hexToRgb,
  onlyTimeCovertion,
  rgbToHex,
  dateConversionSmall,
  get_url_extension,
  get_url_name,
  getLabel,
  SaveAndRedirect,
};
