import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "@api/axios";
const fetchProjects = createAsyncThunk(
  "projects/fetch",
  async (data, { rejectWithValue }) => {
    try {
      const { rows, page, searchText, type } = data;
      const res = await axiosInstance.get(
        `/projects?page=` +
          page +
          `&limit=` +
          rows +
          `&searchText=` +
          searchText +
          `&type=` +
          type,
      );
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);

const getProject = createAsyncThunk(
  "project/get",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/project/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const getProjectsByCustomer = createAsyncThunk(
  "projectsByCustomer/get",
  async ({ id, type }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(
        `/projectsByCustomer/` + id + "/" + type,
      );
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);

const removeProjectsByCustomer = createAsyncThunk(
  "projectsByCustomer/delete",
  async (id, { rejectWithValue }) => {
    try {
      return "removed";
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const createProject = createAsyncThunk(
  "project/create",
  async (body, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/project`, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const updateProject = createAsyncThunk(
  "project/update",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/project/` + id, body);

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const assignProject = createAsyncThunk(
  "project/assign",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/project/assign/` + id, body);

      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const assignProjectByCustomer = createAsyncThunk(
  "projectsByCustomer/assign",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(
        `/projects/AssignCustomer/` + id,
        body,
      );
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const deleteProject = createAsyncThunk(
  "project/delete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/project/` + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const setSelectedProject = createAsyncThunk(
  "project/selected",
  async (project, { rejectWithValue }) => {
    try {
      return project;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const removeSelectedProject = createAsyncThunk(
  "project/remove",
  async ({ rejectWithValue }) => {
    try {
      return "test";
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const uploadProjectFile = createAsyncThunk(
  "project/upload",
  async ({ id, name, file, extension }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/project/Upload/` + id, {
        name,
        file,
        extension,
      });
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const deleteProjectFile = createAsyncThunk(
  "project/filesDelete",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.delete(`/project/file/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);
const editProjectFile = createAsyncThunk(
  "project/filesEdit",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/project/file/` + id, body);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const getProjectFiles = createAsyncThunk(
  "project/files",
  async (id, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/project/files/` + id);
      return res.data;
    } catch (err) {
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue(err);
    }
  },
);

export {
  fetchProjects,
  createProject,
  updateProject,
  assignProject,
  assignProjectByCustomer,
  deleteProject,
  getProject,
  getProjectsByCustomer,
  removeProjectsByCustomer,
  setSelectedProject,
  removeSelectedProject,
  uploadProjectFile,
  editProjectFile,
  deleteProjectFile,
  getProjectFiles,
};
