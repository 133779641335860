import React from 'react'
import {styles} from 'views/admin/styles'
import moment from 'moment'
import axios from 'axios'
import {useSelector} from 'react-redux'
function truncate(input) {
  if (input.length > 40) {
    return input.substring(0, 40) + '...'
  }
  return input
}
function NotificationItem({notifications, setNotifications, fetchNotifications}) {
  const {isCustomer, loading, user: loggedInUser, role} = useSelector(state => state.login)

  async function markToRead(logId, userId) {
    // const notification = notifications.map((n) => {
    //   if (n._id === notiId) {
    //     n.read = true;
    //     return n;
    //   }
    //   return n;
    // });

    const updateNoti = await axios.put(
      `${process.env.REACT_APP_HOSTURL}api/user/notifications/markread`,
      {logId, userId}
    )
    fetchNotifications()
  }
  return (
    <ul style={{margin: '0', padding: '0'}}>
      {notifications.map((noti, _) => (
        <li
          key={_}
          style={!noti.read ? {background: '', ...styles.notiComm} : styles.notiComm}
          onClick={() => markToRead(noti._id, noti.userId)}
        >
          <p>{truncate(noti.title)}</p>
          <div style={styles.clockIcon}>
            <div>
              <i className="fa fa-clock-o" aria-hidden="true"></i>
            </div>
            <div> {moment(noti.createdAt).fromNow()}</div>
            <div style={{marginLeft: '5px'}}>
              {!noti.read ? (
                <i className="fa fa-envelope" style={{color: 'orange'}} aria-hidden="true"></i>
              ) : (
                <i
                  className="fa fa-envelope-open-o"
                  style={{color: 'green'}}
                  aria-hidden="true"
                ></i>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
}

export default NotificationItem
