import React from "react";
import { Controller } from "react-hook-form";
import { Input, Label, FormGroup, FormFeedback, FormText } from "reactstrap";
import { CSSModule } from "reactstrap";

const ReactFormInput = ({
  name,
  control,
  label,
  type,
  placeholder,
  disabled,
  error,
  errorMessage,
  readOnly,
  rows,
  height,
  id,
  autoComplete,
}) => {
  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "off";
    }
  };
  return (
    <FormGroup>
      {label && <Label style={{ textTransform: "uppercase" }}>{label}</Label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Input
            autoComplete
            readOnly={readOnly}
            {...field}
            id={id}
            placeholder={placeholder}
            type={type}
            rows={rows}
            disabled={disabled}
            // onFocus={(e) => onFocus(e)}
            style={{
              height: type === "textarea" ? height : type === "color" ? 50 : "",
              width: type === "color" ? 50 : "",
              WebkitAppearance: type === "color" ? "none" : "",
              border: type === "color" ? "none" : "",
              outline: type === "color" ? "none" : "",
              padding: type === "color" ? 0 : "",
              borderRadius: type === "color" ? "50%" : "",
              overflow: type === "color" ? "hidden" : "",
              marginTop: type === "color" ? "15px" : "",
              marginLeft: type === "color" ? "10px" : "",
            }}
          />
        )}
      />
      {true && <div className="has-error-true">{errorMessage}</div>}
    </FormGroup>
  );
};

export { ReactFormInput };
