import {
  onlyTimeCovertion,
  onlyDateConversion
} from "@utils";
import moreHorizontal from "../../../../assets/svg/moreHorizontal.svg";
import deleteicon from "../../../../assets/img/deleteicon.svg";
import editiconblack from "../../../../assets/img/editicon.svg";
import { useState, useRef, useEffect } from "react";
import DeleteModal from "components/Common/modal/DeleteModal";
import downloadfileicon from "../../../../assets/img/downloadfileicon.svg";
import ViewFiles from "../../../../assets/svg/ViewFiles.svg";

import { useOnClickOutside } from "../../../../@hooks/useOnClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { deleteAsset } from "../../../../@store/asset/assetActions";
import { BsFileEarmarkFont } from "react-icons/bs";
import { Can } from "@permissions/Can";
import EditFileNameModal from "./EditFileNameModal";
import { get_url_extension } from "@utils";
import { get_url_name } from "@utils";

const ImageList = ({ files, handleDeleteImage, handleEditImage, type }) => {
  const dispatch = useDispatch();
  const [deleteShow, setDeleteShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [filesData, setFilesData] = useState(selectedFile);
  var validImageTypes = ["gif", "jpeg", "png", "jpg", "svg"];

  const handleDeleteShow = (file) => {
    setSelectedFile(file);
    setDeleteShow(true);
  };
  const handleEditShow = (file) => {
    setSelectedFile(file);
    setEditShow(true);
  };
  const handleDeleteHide = () => {
    setDeleteShow(false);
  };
  const handleDownloadImage = (file) => {
    saveAs(file?.file, file?.file.replace(/\.[^/.]+$/, ""));
  };

  const handleDeleteAssets = () => {
    handleDeleteHide();
    handleDeleteImage(selectedFile);
  };
  const handleEditFile = (fileText) => {
    setEditShow(false);
    handleEditImage({ id: selectedFile._id, name: fileText });
  };

  const ref = useRef();
  useOnClickOutside(ref, () => setFilesData(null));

  useEffect(() => {
    setFilesData(selectedFile);
  }, [selectedFile]);

  return (
    <div ref={ref}>
      {files.map((file, index) => {
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              gap: "10px",
              marginBottom: "15px",
              position: "relative",
              borderBottom: "1px solid #D9D9D9",
              paddingBottom: "1.4rem",
            }}
            key={file._id}
          >
            <div
              style={{
                background: "#90C7FB",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            >
              {validImageTypes.includes(get_url_extension(file?.file)) ? (
                <img
                  key={file._id}
                  src={file?.file}
                  style={{
                    width: "55px",
                    height: "55px",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <BsFileEarmarkFont
                  style={{
                    width: "55px",
                    height: "55px",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontSize: "14px" }}>{file?.name}</div>
                <div style={{ fontSize: "12px", display: 'flex', gap: '4px' }}>
                  <p>   {onlyDateConversion(file?.createdAt)}  - </p>
                  <p>   {onlyTimeCovertion(file?.createdAt)}</p>

                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                gap: "20px",
                minWidth: "100px",
                paddingRight: "10px",
              }}
            >
              <div
                className="details-menu-item"
                onClick={() => handleDownloadImage(file)}
                style={{ marginBottom: "0px" }}
              >
                <img src={downloadfileicon} style={{ height: "20px" }} />
              </div>
              <Can I="edit" a="AssetFile">
                <div
                  className="details-menu-item"
                  onClick={() => handleEditShow(file)}
                  style={{ marginBottom: "0px" }}
                >
                  <img src={editiconblack} style={{ height: "22px" }} />
                </div>
              </Can>
              <Can I="delete" a="AssetFile">
                <div
                  className="details-menu-item"
                  onClick={() => handleDeleteShow(file)}
                  style={{ color: "#DC3545", marginBottom: "0px" }}
                >
                  <img src={deleteicon} style={{ height: "22px" }} />
                </div>
              </Can>
            </div>
          </div>
        );
      })}
      <DeleteModal
        show={deleteShow}
        handleClose={handleDeleteHide}
        handleAction={handleDeleteAssets}
      />
      <EditFileNameModal
        show={editShow}
        handleClose={() => setEditShow(false)}
        handleAction={handleEditFile}
        file={selectedFile}
      />
    </div>
  );
};

export default ImageList;
