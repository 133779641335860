import Dashboard from "views/Dashboard.js";
import CustomerDashBoard from "views/CustomerDashBoard";
import UserDashBoard from "views/UserDashBoard";
import Projects from "views/admin/projects/Projects";
import Features from "views/admin/features/Features";
import Updates from "views/admin/updates/Updates";
import Customers from "views/customer/customers/Customers";
import Notifications from "views/customer/notifications/Notifications";
import Users from "views/customer/users/Users";
import MatterportEditor from "views/admin/matterport/MatterPortEditor";
import ProjectMarketingModel from "./views/admin/projects/ProjectMarketingModel";
import Tutorials from "components/Common/tutorials";
const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "nc-icon nc-single-copy-04",
    component: Projects,
    layout: "/admin",
    routeProtected: true,
    showInSideBar: true,
  },
  // {
  //   path: '/MatterportEditor/:id',
  //   component: MatterportEditor,
  //   layout: '/admin',
  //   showInSideBar: false,
  // },
  // MarketingPage port
  {
    path: "/MarketingPage/:id",
    component: ProjectMarketingModel,
    layout: "/admin",
    showInSideBar: false,
  },
  {
    path: "/MarketingPage/:id",
    component: ProjectMarketingModel,
    layout: "/user",
    showInSideBar: false,
  },
  {
    path: "/MarketingPage/:id",
    component: ProjectMarketingModel,
    layout: "/customer",
    showInSideBar: false,
  },

  {
    path: "/features",
    name: "Feature Requests",
    icon: "nc-icon nc-send",
    component: Features,
    layout: "/admin",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/updates",
    name: "Updates",
    icon: "nc-icon nc-ruler-pencil",
    component: Updates,
    layout: "/admin",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "nc-icon nc-single-02",
    component: Customers,
    layout: "/admin",
    routeProtected: true,
    showInSideBar: true,
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: CustomerDashBoard,
    layout: "/customer",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: UserDashBoard,
    layout: "/user",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/projects",
    name: "Buildings",
    icon: "nc-icon nc-single-copy-04",
    component: Projects,
    layout: "/customer",
    routeProtected: true,
    showInSideBar: true,
  },

  {
    path: "/features",
    name: "Feature Requests",
    icon: "nc-icon nc-send",
    component: Features,
    layout: "/customer",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-ruler-pencil",
    component: Notifications,
    layout: "/customer",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/users",
    name: "People",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: "/customer",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/users",
    name: "People",
    icon: "nc-icon nc-single-02",
    component: Users,
    layout: "/user",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/projects",
    name: "Buildings",
    icon: "nc-icon nc-single-copy-04",
    component: Projects,
    layout: "/user",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/features",
    name: "Feature Requests",
    icon: "nc-icon nc-send",
    component: Features,
    layout: "/user",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-ruler-pencil",
    component: Notifications,
    layout: "/user",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/MatterportEditor/:id/:tagId?",
    component: MatterportEditor,
    layout: "/customer",
  },
  {
    path: "/MatterportEditor/:id/:tagId?",
    component: MatterportEditor,
    layout: "/admin",
  },
  {
    path: "/MatterportEditor/:id/:tagId?",
    component: MatterportEditor,
    layout: "/user",
  },

  {
    path: "/tutorials",
    name: "Tutorials",
    icon: "nc-icon nc-ruler-pencil",
    component: Tutorials,
    layout: "/admin",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    icon: "nc-icon nc-ruler-pencil",
    component: Tutorials,
    layout: "/customer",
    routeProtected: true,
    showInSideBar: true,
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    icon: "nc-icon nc-ruler-pencil",
    component: Tutorials,
    layout: "/user",
    routeProtected: true,
    showInSideBar: true,
  },
];

export default routes;
