import { useOnClickOutside } from "@hooks/useOnClickOutside";
import React, { useRef } from "react";
import { Button, Modal } from "reactstrap";
import { ReactComponent as Done } from "../../../assets/svg/Done.svg";
const SuccessModal = ({ show, handleClose, title, handleAction }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => handleClose());
  const onCancel = (e) => {
    handleClose();
  };
  const onSubmit = () => {
    handleAction();
  };
  return (
    <Modal
      className="modal-dialog modal-sm"
      style={{
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "10%",
        left: "0",
        right: "0",
      }}
      isOpen={show}
      onHide={handleClose}
    >
      <div style={styles.modalContent} ref={ref}>
        <Done />
        <strong className="h2" style={styles.headingText}>
          Are You Sure?
        </strong>
        <p className="text-center">
          {title ? title : "Do you want to mark as completed"}
        </p>
        <div style={styles.btnContainer}>
          <Button onClick={onSubmit} color="primary" style={styles.btn}>
            Yes
          </Button>

          <Button
            style={{ ...styles.btn, backgroundColor: "#66615B" }}
            type="button"
            color="secondary"
            onClick={onCancel}
          >
            cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "25px 40px",
  },
  btn: {
    width: "100px",
    backgroundColor: "#43BF57",
  },
  headingText: {
    fontWeight: "bold",
    marginTop: 30,

    textAlign: "center",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default SuccessModal;
