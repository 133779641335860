import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddFeatureSchema = yup.object().shape({
  title: yup.string().required("Title should not be empty"),
});

const AddFeatureValidation = yupResolver(AddFeatureSchema);

export { AddFeatureValidation };
