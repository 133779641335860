import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "@api/axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const loginUser = createAsyncThunk(
  "user/login",
  async ({ form, history }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(`/users/login`, form);
      return res.data;
    } catch (err) {
      if (err !== "User Not found.") {
        toast.warn("Password is Not Valid");
      } else {
        toast.warn(err);
      }

      return rejectWithValue(err);
    }
  },
);
const sendOtp = createAsyncThunk(
  "user/sendOtp",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post("/sendotp", data);
      return res.data;
    } catch (err) {
      toast.warn(err);

      return rejectWithValue(err);
    }
  },
);
const loginUserInSystem = createAsyncThunk(
  "user/loginUserInSystem",
  async ({ userData, history, redirect = true }, { rejectWithValue }) => {
    try {
      console.log("userDatauserData", userData);
      localStorage.setItem("access_token", userData.accessToken);
      localStorage.setItem("user", JSON.stringify(userData.user));

      if (redirect) {
        if (userData.user.role[0] === "ADMIN") {
          history.push("/admin/dashboard");
        }
        if (userData.user.role[0] === "CUSTOMER") {
          history.push("/customer/dashboard");
        }
        if (userData.user.role[0] === "USER") {
          history.push("/user/dashboard");
        }
      }

      return "";
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const logoutUser = createAsyncThunk("user/logout", async (_, thunkAPI) => {
  localStorage.clear();
  const resetPresiste = () => { };
  thunkAPI.dispatch({
    type: "RESET",
  });
  return "";
});
const getCustomerToken = createAsyncThunk(
  "customers/token",
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/customerAccessToken/` + id);
      localStorage.setItem("customer_access_token", res.data.result.token);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const removeCustomerToken = createAsyncThunk(
  "customers/remove",
  async (_, { rejectWithValue }) => {
    try {
      localStorage.removeItem("customer_access_token");
      return "removed";
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
const setSendOtpStatus = createAsyncThunk(
  "login/sendStatus",
  async (_, { rejectWithValue }) => {
    return "done";
  },
);

const updateLoginUser = createAsyncThunk(
  "currentUser/update",
  async ({ body, id }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.put(`/user/` + id, body);
      return res.data;
      console.log('➡️ 🤷‍♂️ file: loginActions.js:106 🤷‍♂️ res', res)
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);
export {
  loginUser,
  logoutUser,
  loginUserInSystem,
  sendOtp,
  setSendOtpStatus,
  getCustomerToken,
  removeCustomerToken,
  updateLoginUser,
};
