import { useState } from 'react';
import Select from 'react-select'
import { Row, Col, Form, Input } from 'reactstrap'
// 
import searchicon from 'assets/img/searchicon.svg'
import useDebounce from '@hooks/useDebounce';
import { useEffect } from 'react';
// 
const ReactTableSearch = ({ onChangePaginationInfo, numberOfRows, onChange, onSearchClicked }) => {
  // 
  const [value, setValue] = useState('');
  // 
  const [searchValue, oldValue] = useDebounce(value, 500, true);
  // 
  const currentRows = {
    value: numberOfRows,
    label: numberOfRows,
  }
  const numberOfRowsOptions = [
    {
      value: 5,
      label: 5,
    },
    {
      value: 10,
      label: 10,
    },
    {
      value: 25,
      label: 25,
    },
    {
      value: 50,
      label: 50,
    },
    {
      value: 100,
      label: 100,
    },
  ];
  //
  useEffect(() => {
    if (searchValue !== '' || (searchValue === '' && oldValue !== '')) {
      onChange(searchValue)
    }
  }, [searchValue, oldValue]);
  // 
  return (
    <div>
      <div style={{ height: '39px' }}>
        <div className="table-search-container">
          <div style={{ height: '50px' }} className="table-search-wrapper">
            <Form>
              <div className="table-search">
                <img src={searchicon} onClick={onSearchClicked} className="search-icon" />
                <Input
                  defaultValue=""
                  placeholder="Search Record ..."
                  type="text"
                  value={value}
                  onChange={e => {
                    setValue(e.target.value)
                  }}
                  className="table-search-input"
                />
              </div>
            </Form>
          </div>
          <Col>
            <Row style={{ height: '50px' }} className="table-search-number">
              <p style={{ marginTop: '14px', marginRight: '5px' }}>Rows</p>
              <div>
                <Select
                  className="table-select"
                  classNamePrefix="react-select"
                  name="numberOfRows"
                  value={currentRows}
                  onChange={value => {
                    onChangePaginationInfo(1, value.value)
                  }}
                  options={numberOfRowsOptions}
                />
              </div>
            </Row>
          </Col>
        </div>
      </div>
    </div>
  )
}
export default ReactTableSearch
