import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "reactstrap";
import DeleteModal from "components/Common/modal/DeleteModal";
import Download from "../../../assets/svg/Download.svg";
import ImageEmpty from "./components/ImageEmpty";
import ImageList from "./components/ImageList";
import { ReactComponent as UploadImage } from "../../../assets/svg/UploadImage.svg";
import { deleteFile } from "@store/asset/assetActions";
import { editFile } from "@store/asset/assetActions";
import { getFiles } from "@store/asset/assetActions";
import { getProjectFiles } from "@store/project/projectActions";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

const AssetStorage = ({ id, style, handleFileUpload }) => {
  const dispatch = useDispatch();
  const { selectedAssetFiles, loading } = useSelector((state) => state.assets);

  useEffect(() => {
    if (id) {
      dispatch(getFiles(id));
    }
  }, [id]);
  const handleFileDeleted = (file) => {
    dispatch(deleteFile(file?._id));
  };
  const handleFileEdited = ({ name, id }) => {
    dispatch(editFile({ id, body: { name } }));
  };

  return (
    <>
      <div
        className="display-flex"
        style={{
          borderBottom: "1px solid #D9D9D9",
          paddingBottom: "5px",
        }}
      >
        <div className="" style={{ fontSize: "1rem" }}>
          Files
        </div>
        <div style={{ fontSize: "1rem" }}>Actions</div>
      </div>

      <div>
        {selectedAssetFiles.length > 0 ? (
          <div
            style={{
              overflow: "auto",
              minHeight: "160px",
              maxHeight: "160px",
            }}
          >
            <ImageList
              files={selectedAssetFiles}
              handleDeleteImage={handleFileDeleted}
              handleEditImage={handleFileEdited}
              type="asset"
            />

            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "space-between",
              }}
            >
              <div></div>
            </div>
          </div>
        ) : (
          <div style={{ maxHeight: "160px", minHeight: "160px" }}>
            <ImageEmpty />
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              background: "#51BCDA",
              padding: "8px 20px",
              borderRadius: "5px",
              maxWidth: "130px",
              color: "white",
              alignSelf: "center",
              cursor: "pointer",
              position: "absolute",
              bottom: "30px",
            }}
            onClick={handleFileUpload}
          >
            Upload File
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetStorage;
