/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment/moment";
import {
  fetchProjects,
  createProject,
  updateProject,
  deleteProject,
} from "@store/project/projectActions";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { Can } from "@permissions/Can";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllAssets,
  fetchUserOverduesAssets,
} from "@store/asset/assetActions";
import { fetchOverDueAssets } from "@store/asset/assetActions";
import { fetchFeatures } from "@store/feature/featureActions";
import { fetchUpdates } from "@store/update/updateActions";
import { fetchCustomers } from "@store/customer/customerActions";

function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [query, setQuery] = useState("");
  const { role, user } = useSelector((state) => state.login);

  const { projects, totalProjects, pageIndex, numberOfRows, loading } =
    useSelector((state) => state.projects);
  const { features, totalFeatures } = useSelector((state) => state.features);
  const { updates, totalUpdates } = useSelector((state) => state.updates);
  const { assets, totalAssets, overDueAssets, totalOverDueAssets } =
    useSelector((state) => state.assets);

  const { totalUserOverdueTags } = useSelector((state) => state.assets);
  const { users, totalUsers } = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(
      fetchProjects({ page: pageIndex, rows: numberOfRows, searchText: query }),
    );
    dispatch(fetchFeatures({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchUpdates({ page: pageIndex, rows: numberOfRows, query }));
    dispatch(fetchUserOverduesAssets());
  }, [role]);

  // const [overDueCount, setOverDueCount] = useState([])

  // const overDueTags = () => {
  //   const count = []
  //   assets.map(asset => {
  //     const overdue = moment(asset.maintenanceDate).isBefore(new Date())
  //     count.push(overdue)
  //   })
  //   setOverDueCount(count)
  // }

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-map text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Buildings</p>
                      <CardTitle tag="p">{totalProjects}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/user/projects")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Add A New Building
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Can I="create" a="User">
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="fa fa-users text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">People</p>
                        <CardTitle tag="p">{users.length}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div
                    className="stats"
                    onClick={() => history.push("/user/users")}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="fa fa-plus" />
                    Add New People
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Can>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-exclamation-triangle text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">
                        <div style={{ fontSize: "13px" }}>Feature Request</div>
                        <div style={{ fontSize: "12px" }}>Reported issues</div>
                      </p>
                      <CardTitle tag="p" style={{ fontSize: "15px" }}>
                        {totalFeatures}
                      </CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/user/features")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  Request Feature/Report and Issue
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-refresh text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Notifications</p>
                      <CardTitle tag="p">{totalUpdates}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div
                  className="stats"
                  onClick={() => history.push("/user/notifications")}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-plus" />
                  New Notification
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Row>
          {totalUserOverdueTags == 0 ? (
            <h3>No Over Due Tag Found</h3>
          ) : (
            <Col lg="12" md="6" sm="6">
              <Card className="card-tasks">
                <CardHeader>
                  <CardTitle tag="h4">
                    Over Due Tags{" "}
                    <i
                      class="fa fa-exclamation-triangle"
                      aria-hidden="true"
                      style={{ color: "orange", paddingLeft: "2px" }}
                    />
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-full-width table-responsive">
                    <table
                      style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                      <tr style={{ borderBottom: "1px solid grey" }}>
                        <th style={{ paddingBottom: "1rem" }}>Sr No.</th>
                        <th style={{ paddingBottom: "1rem" }}>Tag Name</th>
                        <th style={{ paddingBottom: "1rem" }}>Building Name</th>
                        <th style={{ paddingBottom: "1rem" }}>
                          Maintenance Date
                        </th>
                        <th style={{ paddingBottom: "1rem" }}>Users</th>
                      </tr>
                      {totalUserOverdueTags &&
                        totalUserOverdueTags
                          .filter((asset) =>
                            moment(asset.maintenanceDate).isBefore(new Date()),
                          )
                          .map((asset, index) => (
                            <tr
                              style={{
                                borderBottom: "1px solid #e6e6e6",
                                alignContent: "center",
                                justifyContent: "center",
                                padding: "15px 0px 15px 0px",
                              }}
                            >
                              <td>{index + 1}</td>
                              <td style={{ padding: "15px 0px 15px 0px" }}>
                                <div>{asset?.assetName}</div>
                              </td>

                              <td>
                                <div data-for="customTooltip" data-tip="View">
                                  {
                                    projects?.filter(
                                      (project) =>
                                        project?._id === asset?.projectId,
                                    )[0]?.title
                                  }
                                </div>
                              </td>
                              <td>{asset?.maintenanceDate}</td>
                              <td>
                                <div style={{ display: "flex", gap: "1rem" }}>
                                  {asset &&
                                    asset?.users.map((user) => (
                                      <div
                                        style={{
                                          width: "34px",
                                          height: "34px",
                                          display: "flex",
                                          borderRadius: "17px",
                                          background: "#51bcda",
                                          alignItems: "center",
                                          justifyContent: "center",

                                          fontSize: "20px",
                                          color: "white",
                                          padding: 0,
                                        }}
                                      >
                                        {user?.label &&
                                          user?.label.charAt(0).toUpperCase()}
                                      </div>
                                    ))}
                                </div>
                              </td>
                            </tr>
                          ))}
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
