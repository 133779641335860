import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNotifications,
  markAllNotifications,
} from "./notificationActions";
const initialState = {
  notifications: [],
  notificationsUnRead: 0,
  totalNotifications: 0,
  pageIndex: 1,
  numberOfRows: 5,
  loading: false,
};

export const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchNotifications.pending]: (state) => {
      state.loading = true;
    },
    [fetchNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.notifications = payload.result.docs;
      state.totalNotifications = payload.result.totalDocs;
      state.numberOfRows = payload.result.limit;
      state.pageIndex = payload.result.page;
    },
    [fetchNotifications.rejected]: (state) => {
      state.loading = false;
    },
    [markAllNotifications.pending]: (state) => {
      state.loading = true;
    },
    [markAllNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [markAllNotifications.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default NotificationSlice.reducer;
