export const styles = {
  leftContainer: {
    width: "500px",
    padding: 80,
    height: "100vh",
    background:
      "linear-gradient(182.67deg, rgba(70, 225, 216, 0.12) 2.22%, rgba(69, 231, 255, 0.12) 93.84%)",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100vh",
    background:
      "linear-gradient(183.4deg, rgba(169, 248, 244, 0.12) 2.8%, rgba(255, 255, 255, 0) 167.47%)",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  inputField: {
    backgroundColor: "#fff",
    borderRadius: 27,
    border: "1px solid #D0DCD7",
    padding: "5px 10px",
    width: 300,
    marginBottom: "10px",
  },
  passconfirm: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #D0DCD7",
    padding: "5px 10px",
    marginBottom: "10px",
  },
  heading: {
    fontFamily: "Quicksand",
    fontWeight: "bold",
    fontSize: 40,
  },
  link: {
    color: "#031FDC",
    textDecoration: "none",
  },
  btn: {
    backgroundColor: "#031FDC",
    marginTop: "50px",
    width: 300,
  },
  otpContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "30px",
    paddingBottom: "10px",
  },
  otpInput: {
    width: "3rem",
    height: "3rem",
    borderRadius: 5,
    border: "1px solid #D0DCD7",
    marginRight: 10,
    color: "#000",
    fontSize: "1rem",
  },
  otpInputError: {
    border: "1px solid red",
  },
  inputText: {
    position: "relative",
    marginTop: "20px",
    width: "100%",
  },
  notiCont: {
    position: "absolute",
    right: "0",
    background: "white",
    zIndex: "999",
    borderRadius: "8px",
    boxShadow: "0 6px 10px -4px rgba(0, 0, 0, 0.15)",
    border: "0.1rem solid #EDEADE",
    width: "320px",
    height: "300px",
    padding: "10px",
    overflow: "scroll",
  },
  notiComm: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "2px",
    padding: "8px",
    cursor: "pointer",
    borderBottom: "0.1rem solid #F5F5DC",
  },
  clockIcon: {
    display: "flex",
    gap: "5px",
  },
  notiIcon: {
    height: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "15px",
    position: "relative",
    marginTop: "3px",
  },
  notificationHeading: {
    marginBottom: "5px",
    fontWeight: "900",
  },
  // input[type="text"]{
  //     height:45px;
  //     width:100%;
  //     border:none;
  //     border-radius:7px;
  //     background-color:#f5f5f5;
  //     outline:0;
  //     padding:0 10px;
  //     font-size:13px;
  //     padding-left:30px;
  // }
  // input[type="password"]{
  //     height:45px;
  //     width:100%;
  //     border:none;
  //     border-radius:7px;
  //     background-color:#f5f5f5;
  //     outline:0;
  //     padding:0 10px;
  //     font-size:13px;
  //     padding-left:30px;
  //     padding-right:30px;
  // }
};
